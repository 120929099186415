import React, { useState, useEffect } from "react";
import { auth, pickupItem, redeemMembership } from "../../firebase";
import qrCode from "./mobileQR.png";
import moment from "moment";
import {
  PickupModal,
  Tabs,
  Button,
  Card,
  ShopsList,
  ShopConfirmation,
  getAmountSaved,
  SubChangeModal,
  SubRenewModal,
  priceToCredit,
  priceToDiscount,
  Banner,
} from "../../Components";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "./UserProfile.css";
import { v2shops, shopsData } from "../../Data";
import { faStore, faSearch, faCog } from "@fortawesome/free-solid-svg-icons";
import CurrencyInput from "react-currency-input-field";
import SelectSearch, { fuzzySearch } from "react-select-search";
import ReactSwipeButton from "react-swipe-button";
import AnimateHeight from "react-animate-height";
import Loader from "react-loader-spinner";
import ReactTooltip from "react-tooltip";

let pickupInfo = null;

const UserProfile = ({ user, refreshUserInfo }) => {
  const [displayName, setDisplayName] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [shopscribeMembership, setShopscribeMembership] = useState();
  const [isPickupOpen, setPickupOpen] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);
  const [shopConfirmationShown, setShopConfirmationShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [openSubChange, setOpenSubChange] = useState(false);
  const [openSubRenew, setOpenSubRenew] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (
      urlParams.has("shopId") &&
      shopsData.find((shop) => shop.id === urlParams.get("shopId"))
    ) {
      setSelectedShop(urlParams.get("shopId"));
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (user.subBoxUser && !user.subBoxPaid) {
        history.push(`/checkout/25?type=box`);
      }
      setDisplayName(user.displayName);
      setSubscriptions(
        user.subscriptions && user.subscriptions.filter((s) => s.shopId)
      );
      setShopscribeMembership(
        user.subscriptions &&
          user.subscriptions.find((s) => s.uniqueId === user.membershipId)
      );
      if (user.subBoxUser) setSelectedTab(2);
    }
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    window.Webflow.destroy();
    window.Webflow.ready();
    window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
    window.scroll(0, 0);
  }, [selectedTab]);

  const confirmPickup = async () => {
    let result = await pickupItem(user, pickupInfo.userSubscription.uniqueId);
    if (result) {
      let newSubs = subscriptions;
      newSubs[pickupInfo.index] = result;
      setSubscriptions(newSubs);
    }
    refreshUserInfo();
  };

  const confirmShopscribePickup = async () => {
    let result = null;
    setLoading(true);
    do {
      result = await redeemMembership(
        user,
        parseFloat(amount).toFixed(2),
        selectedShop
      );
    } while (!result);
    setShopConfirmationShown(true);
    refreshUserInfo();
  };

  const getSubscriptionInfo = (subs) => {
    let shopId = subs.shopId;
    let subscriptionId = subs.subscriptionId;
    let shopInfo = shopsData.find((x) => x.id === shopId);
    return shopInfo.subscriptions.find((x) => x.id === subscriptionId);
  };

  const getShopInfo = (subs) => {
    let shopId = subs.shopId;
    return shopsData.find((x) => x.id === shopId);
  };

  const showSwipe = () => {
    return (
      selectedShop &&
      parseFloat(amount) > 0 &&
      parseFloat(amount) <= parseFloat(shopscribeMembership.remainingCredit)
    );
  };

  const isMultiSubUser = () => {
    return (
      (user.membershipId &&
        user.subscriptions &&
        user.subscriptions.length > 1) ||
      !user.subBoxUser
    );
  };

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 0,
          padding: isMobile ? "20px 30px" : "25px 30px",
          zIndex: "99999",
          width: "100vw",
          background: "rgba(253, 248, 244, 1)",
        }}
      >
        <div style={{ width: "100%", maxWidth: "1200px", margin: "auto" }}>
          <a href="/">
            <img
              src="/images/logo-orange.svg"
              loading="lazy"
              sizes="100vw"
              alt=""
              className="logo"
            />
          </a>
        </div>
      </div>
      {isMultiSubUser() && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            padding: isMobile ? "0" : "10px 0",
            zIndex: "99999",
            width: "100vw",
            background: "rgba(253, 248, 244, 1)",
          }}
        >
          <Tabs
            tabs={[
              {
                text: "Redeem",
                default: user.subBoxUser ? false : true,
                icon: faStore,
              },
              {
                text: "Discover",
                icon: faSearch,
              },
              {
                text: "Account",
                default: user.subBoxUser ? true : false,
                icon: faCog,
              },
            ]}
            setCurrentTab={(tab) => setSelectedTab(tab)}
            style={{ width: isMobile ? "100%" : "50%", margin: "auto" }}
            defaultTab={user.subBoxUser ? 2 : 0}
          />
        </div>
      )}
      {/*******************************************************
       * TAB 0 (Membership)
       *********************************************************/}
      {selectedTab === 0 && (
        <div className="row">
          <div
            className="column"
            style={{
              position: "relative",
              flex: "80%",
              maxHeight: isMobile ? "auto" : "100vh",
              overflow: isMobile ? "visible" : "scroll",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                top: isMobile ? "0px" : "100px",
                textAlign: "center",
              }}
            >
              {subscriptions && !shopscribeMembership && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Banner>
                    Use your subscription <b>across shops</b>! Check out
                    Shopscribe 2.0
                  </Banner>
                </div>
              )}
            </div>
            <div
              className="section"
              style={{
                marginTop: "100px",
                alignItems: "center",
                textAlign: "center",
                padding: isMobile ? "10vh 30px" : "125px 30px 75px",
              }}
            >
              <div>
                {isMobile ? (
                  <h3
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    Hey there 👋
                  </h3>
                ) : (
                  <h1
                    style={{
                      marginBottom: "50px",
                      marginTop: "-50px",
                    }}
                  >
                    Hey there 👋
                  </h1>
                )}
                {!subscriptions && !shopscribeMembership && (
                  <div style={{ width: "100%" }}>
                    <h5>
                      You're not a member yet!
                      <br />
                      Not much to see around here...
                    </h5>
                    <img
                      src="https://media.giphy.com/media/cJMlR1SsCSkUjVY3iK/giphy.gif"
                      alt="gif"
                      style={{ height: "30vh", margin: "25px auto" }}
                    />
                    {isMobile || <br />}
                    <Button
                      onClick={() => history.push("/jump-on-board")}
                      width={isMobile ? "100%" : "300px"}
                    >
                      Let's start
                    </Button>
                  </div>
                )}
              </div>
              {shopscribeMembership && (
                <div
                  style={{ width: isMobile ? "100%" : "50%", paddingTop: 0 }}
                >
                  <h5>
                    You have £
                    {parseFloat(shopscribeMembership.remainingCredit).toFixed(
                      2
                    )}{" "}
                    remaining
                  </h5>
                  <div>
                    {shopscribeMembership.remainingCredit >= 0.01 && (
                      <AnimateHeight
                        duration={600}
                        height={showSwipe() ? 320 : 135}
                        easing="cubic-bezier(0.85, 0, 0.15, 1)"
                        style={{
                          boxShadow: "0 25px 50px -10px rgba(100, 44, 0, 0.07)",
                          borderRadius: "25px",
                          backgroundColor: "white",
                        }}
                      >
                        <Card
                          style={{
                            padding: "30px 15px",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          }}
                        >
                          <div style={{ lineHeight: "200%" }}>
                            I am at{" "}
                            <SelectSearch
                              options={v2shops
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((shop) => {
                                  return { ...shop, value: shop.id };
                                })}
                              search={true}
                              placeholder="Type shop name"
                              value={selectedShop}
                              filterOptions={fuzzySearch}
                              onChange={(value) => setSelectedShop(value)}
                            />
                            <br />
                            spending{" "}
                            <span>
                              <CurrencyInput
                                id="custom-price"
                                name="custom-price"
                                defaultValue={""}
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                placeholder="£"
                                prefix="£"
                                value={amount}
                                maxLength={6}
                                onValueChange={(value, name) => {
                                  setAmount(value ?? "");
                                }}
                                autoComplete="off"
                                style={{
                                  maxWidth: "80%",
                                  width: "100px",
                                  border: "none",
                                  borderBottom: "2px solid",
                                  font: "bold 18px DM Sans",
                                  backgroundColor: "transparent",
                                  outline: "none",
                                }}
                              />
                            </span>
                          </div>
                          <div
                            style={{
                              width: "90%",
                              opacity: showSwipe() ? 1 : 0,
                              transform: showSwipe()
                                ? "none"
                                : "translateY(-230px)",
                              transition:
                                "opacity .6s cubic-bezier(0.85, 0, 0.15, 1)",
                            }}
                          >
                            <p
                              style={{
                                lineHeight: "140%",
                                fontSize: "15px",
                                margin: "20px 0 10px",
                              }}
                            >
                              <span
                                style={{
                                  opacity: 0.5,
                                }}
                              >
                                Yay!
                                {moment().isBefore(
                                  shopscribeMembership.freeTrialEnd
                                )
                                  ? " All"
                                  : " £" +
                                    getAmountSaved(
                                      parseFloat(amount),
                                      priceToDiscount(
                                        shopscribeMembership.price
                                      )
                                    )}{" "}
                                of your order is free
                              </span>{" "}
                              🎉
                            </p>
                            <p
                              style={{
                                fontSize: "14px",
                                lineHeight: "130%",
                                margin: "40px 0 20px",
                                color: "#ff7e67",
                              }}
                            >
                              Swipe and show the order to the staff.
                              <br />
                              <b>Swipe only when ready to collect!</b>
                            </p>
                            {showSwipe() && (
                              <div
                                style={{
                                  width: isMobile ? "100%" : "70%",
                                  margin: "0 auto",
                                }}
                              >
                                {loading ? (
                                  <div>
                                    <Loader
                                      type="TailSpin"
                                      color="#ff7e67"
                                      height={50}
                                      width={50}
                                    />
                                  </div>
                                ) : (
                                  <ReactSwipeButton
                                    text="Swipe to enjoy"
                                    color="#ff7e67"
                                    onSuccess={() => confirmShopscribePickup()}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </Card>
                      </AnimateHeight>
                    )}
                  </div>
                  {shopscribeMembership.remainingCredit <= 10 ||
                  amount > shopscribeMembership.remainingCredit ? (
                    <div
                      style={{
                        margin: isMobile ? "25px 0" : "25px 100px",
                      }}
                    >
                      <p style={{ color: "#ff7e67" }}>
                        <b>You're running low on credits!</b> Get your next
                        month's credits now, by clicking renew early.
                      </p>
                      <Button
                        small
                        secondary
                        onClick={() => setOpenSubRenew(true)}
                      >
                        Renew early
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{
                        margin: isMobile ? "25px 0" : "25px 100px",
                      }}
                    >
                      <Button
                        small
                        secondary
                        onClick={() =>
                          window.open(
                            "https://02s49etp2yl.typeform.com/to/swt8GGeT",
                            "_blank"
                          )
                        }
                      >
                        Refer a friend and get £7
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="section" style={{ padding: "0 30px 150px" }}>
              <div className="content">
                {subscriptions && subscriptions.length > 0 && (
                  <h5>Your direct subscriptions:</h5>
                )}
                {subscriptions &&
                  subscriptions.map(
                    (subscription, i) =>
                      getSubscriptionInfo(subscription) && (
                        <div style={{ display: "contents" }} key={"sub-" + i}>
                          <div
                            className="app-screen---hero-1"
                            onClick={() => {
                              if (subscription.remainingItems) {
                                pickupInfo = {
                                  shop: getShopInfo(subscription),
                                  subscription:
                                    getSubscriptionInfo(subscription),
                                  userSubscription: subscription,
                                  userName: displayName,
                                  index: i,
                                };
                                setPickupOpen(true);
                              }
                            }}
                          >
                            <img
                              src={getSubscriptionInfo(subscription).image}
                              loading="lazy"
                              alt=""
                              className="shop-profile-landing"
                            />
                            <div className="shop-text-landing">
                              <h4>{getSubscriptionInfo(subscription).title}</h4>
                              <p>
                                From{" "}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontColor: "#0b1b32",
                                  }}
                                >
                                  {getShopInfo(subscription).name}
                                </span>
                                {" · " +
                                  `Renews on ${moment(
                                    subscription.renewalDate
                                  ).format("Do MMMM YYYY")}`}
                              </p>
                            </div>
                            <div className="subs-price">
                              <h5>{`${subscription.remainingItems}/${subscription.totalItems} remaining`}</h5>
                              <p>Tap to Collect</p>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                <PickupModal
                  isOpen={isPickupOpen}
                  setIsOpen={setPickupOpen}
                  pickupInfo={pickupInfo}
                  setPickupConfirmed={confirmPickup}
                  isDemoAccount={user.demo}
                />
                <ShopConfirmation
                  isOpen={shopConfirmationShown}
                  setClosed={() => {
                    setShopConfirmationShown(false);
                    setLoading(false);
                    setSelectedShop(null);
                    setAmount(0);
                  }}
                  shopName={
                    selectedShop &&
                    shopsData.find(
                      (shop) => shop.id === (" " + selectedShop).slice(1)
                    ).name
                  }
                  amount={(" " + amount).slice(1)}
                  isDemoAccount={user.demo}
                />
              </div>
            </div>
          </div>
          {isMobile || (
            <div
              className="column"
              style={{
                flex: "20%",
                overflow: isMobile ? "unset" : "scroll",
                maxHeight: isMobile ? "auto" : "100vh",
                backgroundColor: isMobile ? "inherit" : "rgb(247, 240, 232)",
                boxShadow: isMobile
                  ? "none"
                  : "inset 0px 15px 25px rgba(0,0,0,0.05)",
              }}
            >
              <div
                className="section"
                style={{
                  padding: "25vh 40px",
                }}
              >
                <div
                  className="content"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <h4 style={{ marginBottom: "10px" }}>See on mobile</h4>
                  <p
                    className="shop-description"
                    style={{ marginBottom: "25px" }}
                  >
                    Scan this or go to <b>shopscribe.com/profile</b>
                  </p>
                  <img
                    src={qrCode}
                    alt="QR-code"
                    style={{ width: "100%", maxWidth: "275px" }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {/*******************************************************
       * TAB 1 (Discover shops)
       *********************************************************/}
      {selectedTab === 1 && (
        <div style={{ paddingTop: "75px" }}>
          <ShopsList />
        </div>
      )}
      {/*******************************************************
       * TAB 2 (Settings)
       *********************************************************/}
      {selectedTab === 2 && (
        <div style={{ padding: isMobile ? "0 30px" : 0 }}>
          {shopscribeMembership && user.membershipId && (
            <div
              className="content plan"
              style={{
                width: isMobile ? "100%" : "80%",
                margin: isMobile ? "100px auto 0" : "150px auto 30px",
                padding: "42px",
              }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={
                    isMobile
                      ? { display: "block" }
                      : { float: "left", width: "45%" }
                  }
                >
                  <h4 style={{ marginBottom: "0px" }}>Your membership</h4>
                  {shopscribeMembership.freeTrialEnd &&
                  moment().isBefore(shopscribeMembership.freeTrialEnd) ? (
                    <div>
                      <p style={{ marginBottom: 0 }}>
                        <br />
                        You're on a free trial, enjoy! 🤑
                        <br />
                        Your trial ends on{" "}
                        <b>
                          {moment(shopscribeMembership.freeTrialEnd).format(
                            "Do MMMM YYYY"
                          )}
                        </b>
                        .
                      </p>
                      <p
                        className="shop-description"
                        style={{
                          margin: "0 auto",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Then for £{shopscribeMembership.price}/month you get £
                        {priceToCredit(shopscribeMembership.price)} in credit.
                        Change your plan at any time.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p
                        className="shop-description"
                        style={{ width: "100%", margin: "10px auto 0" }}
                      >
                        What you pay:{" "}
                        <b>{`£${shopscribeMembership.price}/month`}</b>
                        <br />
                        What you get:{" "}
                        <b>{`£${shopscribeMembership.totalCredit}/month`}</b>
                        <br />
                        <br />
                        {`Your next cycle begins on ${moment(
                          shopscribeMembership.renewalDate
                        ).format(
                          "Do MMMM YYYY"
                        )} or whenever you decide to renew early`}
                        <span
                          data-tip={`A maximum of £${shopscribeMembership.totalCredit} in credit will roll over to next month, if unused`}
                          data-for="path"
                          style={{
                            display: "inline-block",
                            opacity: 0.33,
                            width: "17px",
                            paddingBottom: "3px",
                            marginLeft: "3px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            alt=""
                            src="/images/info.svg"
                            style={{ margin: "0 0 3px 3px" }}
                          />
                        </span>
                      </p>
                      <ReactTooltip effect="solid" id="path" />
                    </div>
                  )}
                </div>
                <div
                  style={
                    isMobile
                      ? { display: "block", marginTop: "25px" }
                      : { float: "right" }
                  }
                >
                  <div style={{ margin: "0 auto 15px" }}>
                    <Button
                      small
                      secondary
                      onClick={() => setOpenSubRenew(true)}
                    >
                      Renew early
                    </Button>
                  </div>
                  <div
                    target="_blank"
                    rel="noreferrer"
                    className="text-link"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      history.push(
                        `/jump-on-board?amount=${shopscribeMembership.price}&update-sub=true`
                      )
                    }
                  >
                    Change plan {">"}
                  </div>
                  <div
                    target="_blank"
                    rel="noreferrer"
                    className="text-link"
                    style={{ cursor: "pointer", marginBottom: 0 }}
                    onClick={() =>
                      window.alert(
                        "We're working on this! Please email info@shopscribe.com to pause or cancel. \n\nWe know this is not ideal, but we're a small team working hard to get this to you asap. Thanks for being a Shopscriber 🧡"
                      )
                    }
                  >
                    Cancel/Pause {">"}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="row"
            style={{
              width: isMobile ? "100%" : "80%",
              maxWidth: "1200px",
              marginBottom: "100px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop:
                shopscribeMembership && user.membershipId
                  ? "25px"
                  : isMobile
                  ? "100px"
                  : "175px",
            }}
          >
            <div
              className="column"
              style={user.subBoxUser ? { flex: "100%" } : { flex: "50%" }}
            >
              <div
                className="section"
                style={{
                  width: "100%",
                  padding: 0,
                }}
              >
                <div
                  className="content plan"
                  style={{ marginBottom: "50px", padding: "42px" }}
                >
                  <h4 style={{ marginBottom: "0px" }}>
                    {user.subBoxUser ? `Your subscription box` : `Your account`}
                  </h4>
                  <p className="shop-description">{user.email}</p>
                  {user.subBoxUser && (
                    <p style={{ textAlign: "start", margin: "30px 0 50px" }}>
                      <b>Name: </b>
                      {user.displayName}
                      <br />
                      <b>Address: </b>
                      {user.address +
                        ", " +
                        user.aptOrSuite +
                        ", " +
                        user.town +
                        ", " +
                        user.postCode}
                      <br />
                      <b>Box Delivery Date: </b>
                      {user.nextDeliveryDate
                        ? new Date(user.nextDeliveryDate).toDateString() +
                          ` (then every 1st ${moment(
                            new Date(user.nextDeliveryDate)
                          ).format("ddd")} of the month)`
                        : ""}
                      <br />
                      {user.deliveryInfo && <b>Delivery Info: </b>}
                      {user.deliveryInfo}
                      <br />
                      {user.dietaryInfo && <b>Dietary Info: </b>}
                      {user.dietaryInfo}
                      <br />
                      {user.referCode && <b>Refer Code: </b>}
                      {user.referCode}
                    </p>
                  )}
                  {!user.subBoxUser && (
                    <a
                      href="https://02s49etp2yl.typeform.com/to/swt8GGeT"
                      target="_blank"
                      rel="noreferrer"
                      className="text-link"
                    >
                      Refer a friend and get £7 {">"}
                    </a>
                  )}
                  <a
                    href="https://forms.gle/Goaii3Uz3nCa2Y696"
                    target="_blank"
                    rel="noreferrer"
                    className="text-link"
                  >
                    Recommend a shop {">"}
                  </a>

                  {user.subBoxUser && (
                    <div
                      onClick={() =>
                        window.alert(
                          "We're working on this! Please email info@shopscribe.com to change your details. \n\nWe know this is not ideal, but we're a small team working hard to get this to you asap. Thanks for being a Shopscriber 🧡"
                        )
                      }
                      className="text-link"
                      style={{ cursor: "pointer" }}
                    >
                      Change your details {">"}
                    </div>
                  )}
                  <div
                    style={{
                      margin: "15px auto 0px",
                      width: isMobile ? "100%" : "auto",
                    }}
                  >
                    {user.subBoxUser && (
                      <Button
                        small
                        secondary
                        onClick={() =>
                          window.alert(
                            "We're working on this! Please email info@shopscribe.com to pause or cancel. \n\nWe know this is not ideal, but we're a small team working hard to get this to you asap. Thanks for being a Shopscriber 🧡"
                          )
                        }
                        style={{ marginBottom: "10px" }}
                      >
                        Pause / Cancel
                      </Button>
                    )}
                    <Button
                      small
                      secondary
                      onClick={() => {
                        auth.signOut();
                      }}
                    >
                      Sign Out
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="column"
              style={{
                paddingLeft: isMobile ? 0 : "30px",
                flex: user.subBoxUser ? "0%" : "50%",
              }}
            >
              {!user.subBoxUser && (
                <div
                  data-w-id="Grid 11"
                  className="w-layout-grid grid-faq"
                  style={{ width: "100%", maxWidth: "100%" }}
                >
                  <div className="accordion-item">
                    <div data-w-id="Div Block 3" className="accordion-header">
                      <h6 data-w-id="Heading 3" className="heading-accordion">
                        How it works
                      </h6>
                      <img
                        src="images/arrow.svg"
                        loading="lazy"
                        alt=""
                        className="icon-arrow"
                      />
                    </div>
                    <div className="accordion-content">
                      <br />
                      <ol style={{ lineHeight: "140%" }}>
                        <li>
                          <h5 style={{ marginBottom: 0 }}>
                            Become a member online
                          </h5>
                          <p style={{ fontSize: "14px" }}>
                            Pick how much credit you'd like monthly and get
                            extra spend each month. Unused credits roll over up
                            to the number of credits in next month's plan.
                          </p>
                        </li>
                        <li>
                          <h5 style={{ marginBottom: 0 }}>
                            Scan QR code in-store
                          </h5>
                          <p style={{ fontSize: "14px" }}>
                            Order as normal. When asked to pay, scan our QR code
                            at the till and type how much credit you'd like to
                            redeem (if no QR code, access the same page in your
                            profile).
                          </p>
                        </li>
                        <li>
                          <h5 style={{ marginBottom: 0 }}>
                            Use your Shopscribe credit
                          </h5>
                          <p style={{ fontSize: "14px" }}>
                            Swipe to confirm redemption, and show the pop-up to
                            the staff as proof.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div data-w-id="Div Block 3" className="accordion-header">
                      <h6 data-w-id="Heading 3" className="heading-accordion">
                        Frequently Asked Questions
                      </h6>
                      <img
                        src="images/arrow.svg"
                        loading="lazy"
                        alt=""
                        className="icon-arrow"
                      />
                    </div>
                    <div className="accordion-content">
                      <p>
                        Please check out our{" "}
                        <a
                          href="https://shopscribe.notion.site/Frequently-Asked-Questions-2779c187321f4729818d36ce2561415c"
                          target="_blank"
                          rel="noreferrer"
                        >
                          FAQs
                        </a>{" "}
                        page
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {shopscribeMembership && (
        <>
          <SubChangeModal
            user={user}
            isOpen={openSubChange}
            setIsOpen={setOpenSubChange}
            refreshSubscription={() => refreshUserInfo()}
            oldMembership={shopscribeMembership}
          />
          <SubRenewModal
            user={user}
            isOpen={openSubRenew}
            setIsOpen={setOpenSubRenew}
            refreshSubscription={() => refreshUserInfo()}
            oldMembership={shopscribeMembership}
          />
        </>
      )}
    </div>
  );
};

export default UserProfile;
