import { useState } from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  NavBar,
  Button,
  ShopsList,
  getDefaultPrice,
  priceToCredit,
  priceToExtraPercent,
} from "../../Components";
import {
  isEmailUnique,
  auth,
  analytics,
  generateUserDocument,
  getFirebaseUserDocument,
} from "../../firebase.js";
import { request } from "../../backend";

const FreeTrial = ({ user }) => {
  const [email, setEmail] = useState("");

  const history = useHistory();
  const defaultPrice = getDefaultPrice();

  const startTrial = async (email) => {
    if (user) {
      const userId = user.id || user.uid;
      const userDocument = await getFirebaseUserDocument(userId);
      if (userDocument && userDocument.uid) {
        history.push(`/checkout/trial`);
      } else {
        const userOnPostgres = await request(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`,
          "GET"
        );
        if (userOnPostgres && userOnPostgres.subId) {
          alert(
            "Sorry! we're phasing out memberships - we don't support memberships for box subscribers."
          );
        } else {
          await anonFreeTrial(email);
        }
      }
    } else {
      await anonFreeTrial(email);
    }
  };

  const anonFreeTrial = async (email) => {
    if (email && (await isEmailUnique(email))) {
      analytics.logEvent("trial_page_email_submitted", {
        value: email,
      });
      await auth.signInAnonymously();
      auth.onAuthStateChanged(async (anonUser) => {
        if (anonUser) {
          let guestUser = {
            uid: anonUser.uid,
            email: email,
            displayName: "Guest",
          };
          let additionalData = {
            guest: true,
          };
          await generateUserDocument(guestUser, additionalData);
          history.push(`/checkout/trial?email=${email}`);
        }
      });
    } else {
      alert("Email already used!");
    }
  };

  return (
    <div>
      <NavBar forShops={false} />
      <div className="row">
        <div
          className="column"
          style={{
            flex: "60%",
            maxHeight: isMobile ? "auto" : "100vh",
            overflow: isMobile ? "visible" : "auto",
          }}
        >
          <div className="section-hero" style={{ height: "100%" }}>
            <div className="block-hero" style={{ width: "100%" }}>
              {user && user.membershipId ? (
                <div className="content">
                  <h3 style={{ color: "#ff7e67 !important" }}>
                    Free trial available
                    <br />
                    only for new users 😔
                  </h3>
                  <br />
                  <Button onClick={() => history.push("/profile")}>
                    Go to your profile
                  </Button>
                </div>
              ) : (
                <div className="content">
                  <h1>Free for 7 days</h1>
                  <div
                    className="w-layout-grid grid-check-plan"
                    style={{
                      width: "fit-content",
                      margin: "auto",
                      gridRowGap: "10px",
                    }}
                  >
                    <div className="check-block-plan">
                      <div className="check-plan">
                        <img
                          src="/images/check-orange.svg"
                          loading="lazy"
                          alt=""
                          className="icon-check"
                        />
                      </div>
                      <div className="text-check-plan">
                        Get <b>£7 in credit</b> to use across our shops{" "}
                        <b>over 7 days</b>
                      </div>
                    </div>
                    <div className="check-block-plan">
                      <div className="check-plan">
                        <img
                          src="/images/check-orange.svg"
                          loading="lazy"
                          alt=""
                          className="icon-check"
                        />
                      </div>
                      <div className="text-check-plan">
                        We'll send you a reminder <b>before your trial ends</b>
                      </div>
                    </div>
                    <div className="check-block-plan">
                      <div className="check-plan">
                        <img
                          src="/images/check-orange.svg"
                          loading="lazy"
                          alt=""
                          className="icon-check"
                        />
                      </div>
                      <div className="text-check-plan">
                        You can <b>cancel anytime</b>
                      </div>
                    </div>
                    <div className="check-block-plan">
                      <div className="check-plan">
                        <img
                          src="/images/check-orange.svg"
                          loading="lazy"
                          alt=""
                          className="icon-check"
                        />
                      </div>
                      <div className="text-check-plan">
                        Unused credit <b>will roll over</b> to your first cycle
                      </div>
                    </div>
                  </div>
                  <div
                    className="button-container"
                    style={{
                      marginTop: "50px",
                      width: "100%",
                      maxWidth: "500px",
                    }}
                  >
                    <input
                      type="email"
                      maxLength="256"
                      placeholder="Your Email"
                      className="text-field w-input required email"
                      style={{ maxWidth: "350px", margin: "auto" }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                    <Button onClick={() => startTrial(email)}>Continue</Button>
                    <p
                      className="shop-description"
                      style={{ maxWidth: "400px", margin: "auto" }}
                    >
                      After the trial, you enroll in our £{defaultPrice}/mo plan
                      and get £{priceToCredit(defaultPrice)}/mo to spend at
                      local shops (an extra{" "}
                      {priceToExtraPercent(defaultPrice).toFixed(0)}
                      %, £
                      {Math.floor(
                        (priceToCredit(defaultPrice) - defaultPrice) * 12
                      )}{" "}
                      free annually).
                      <b> Change or cancel any time.</b>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="column"
          style={{
            flex: "40%",
            overflowY: isMobile ? "unset" : "scroll",
            overflowX: "hidden",
            maxHeight: isMobile ? "auto" : "100vh",
            backgroundColor: isMobile ? "inherit" : "rgb(247, 240, 232)",
            padding: isMobile ? 0 : "25px",
            boxShadow: isMobile
              ? "none"
              : "inset 0px 15px 25px rgba(0,0,0,0.05)",
          }}
        >
          <div className="section-hero" style={{ paddingTop: "75px" }}>
            <ShopsList
              width={isMobile ? window.innerWidth : window.innerWidth * 0.4}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeTrial;
