import { useState } from "react";
import Modal from "react-modal";
import Loader from "react-loader-spinner";
import { sendPasswordRecoveryEmail } from "../../firebase.js";

const PasswordRecoveryModal = ({ isOpen, setIsOpen, email }) => {
  const [emailAddress, setEmailAddress] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [resetResult, setResetResult] = useState(null);

  const closeModal = () => {
    setIsOpen(false);
    setSubmitted(false);
    setResetResult(null);
  };

  const resetPassword = async () => {
    setSubmitted(true);
    const result = await sendPasswordRecoveryEmail(emailAddress ?? email);
    if (result && !result.success) setSubmitted(false);
    setResetResult(result);
  };

  return (
    <div style={{ fontFamily: "DM Sans !important" }}>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        contentLabel="Pickup"
        style={{
          overlay: {
            position: "fixed",
            display: "inline-flex",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: "15px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999999999,
          },
          content: {
            margin: "auto",
            padding: "25px",
            boxShadow: "none",
            outline: "none",
            maxWidth: "100%",
          },
        }}
        className="company-info"
      >
        <div>
          {!submitted && (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <p style={{ marginBottom: 0 }}>Forgot your password?</p>
              <p className="shop-description">
                We'll email you a link to reset it
              </p>
              {resetResult && !resetResult.success && (
                <p className="text-accent">{resetResult.message}</p>
              )}
              <div data-name="Log in" className="form">
                <input
                  type="email"
                  className="text-field w-input"
                  maxLength="256"
                  name="recoveryEmail"
                  placeholder="Email address"
                  id="Email"
                  required=""
                  value={emailAddress ?? email}
                  onChange={(event) =>
                    setEmailAddress(event.currentTarget.value ?? email)
                  }
                  style={{
                    width: "400px",
                    maxWidth: "80%",
                    margin: "20px auto",
                  }}
                />
                <input
                  type="submit"
                  value="Reset password"
                  className="submit-button orange w-button"
                  onClick={() => resetPassword()}
                  style={{ width: "fit-content", margin: "auto" }}
                />
              </div>
            </div>
          )}
          {submitted && (
            <div>
              {resetResult && resetResult.success ? (
                <div>
                  <img
                    src="/images/confirm.svg"
                    loading="lazy"
                    alt=""
                    style={{
                      width: "50px",
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  />
                  <p style={{ maxWidth: "70%" }}>{resetResult.message}</p>
                </div>
              ) : (
                <Loader
                  type="TailSpin"
                  color="#ff7e67"
                  height={50}
                  width={50}
                />
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default PasswordRecoveryModal;
