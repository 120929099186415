import { NavBar } from "../../Components";
import { useParams, useHistory } from "react-router-dom";
import {
  NotifyModal,
  SubscriptionCard,
  Button,
  priceToExtraPercent,
} from "../../Components";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

const Shop = ({ shopsData, user }) => {
  const history = useHistory();

  let { shopId } = useParams();

  let shop = shopsData.find((x) => x.id === shopId);
  let subscriptions = shop ? shop.subscriptions : null;

  let googleShopAddress = encodeURIComponent(shop.address).replace(",", "");

  const [isNotifyModal, setNotifyModal] = useState(false);
  const [shopscribeMembership, setShopscribeMembership] = useState();

  useEffect(() => {
    if (user) {
      setShopscribeMembership(
        user.subscriptions && user.subscriptions.find((s) => !s.shopId)
      );
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.Webflow.destroy();
    window.Webflow.ready();
  }, []);

  const getPerksWidth = (perks) => {
    let percent = 100 / perks.length;
    return percent + "%";
  };

  return (
    <div>
      <NavBar forShops={false} />
      {!shop && (
        <div className="utility-page-wrap">
          <div className="utility-page-content w-form">
            <h2 className="heading">Page Not Found</h2>
            <p>
              The page you are looking for doesn&#x27;t exist or has been moved.
            </p>
            <a href="/" className="button w-button">
              Back to Home
            </a>
          </div>
        </div>
      )}
      {shop && (
        <div>
          <div
            className="section-hero up-on-mobile"
            style={{ padding: "160px 30px 70px" }}
          >
            {isMobile && (
              <div
                className="row"
                style={{ width: "100%", maxWidth: "1500px" }}
              >
                <div className="column">
                  <div data-w-id="Div Block" className="content">
                    <img
                      src={shop.shopImage}
                      loading="lazy"
                      style={{ width: "100%", maxWidth: "600px" }}
                      alt={shop.name}
                      className="image-company"
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              className="row"
              style={{
                width: "100%",
                maxWidth: "1500px",
              }}
            >
              <div className="column">
                <div className="content" style={{ height: "100%" }}>
                  <div className="block-hero" style={{ height: "100%" }}>
                    <h1
                      data-w-id="Heading 2"
                      className="heading-hero"
                      style={{
                        marginBottom: "-5px",
                        fontSize: isMobile ? "auto" : "50px",
                      }}
                    >
                      {shop.name}
                    </h1>

                    {shop.owner && (
                      <p className="shop-description">
                        by <b>{shop.owner}</b>
                      </p>
                    )}
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "10px",
                          lineHeight: "150%",
                          whiteSpace:
                            typeof shop.address === "string"
                              ? "inherit"
                              : "pre-wrap",
                        }}
                      >
                        {typeof shop.address === "string"
                          ? shop.address
                          : "\n" + shop.address.join("\n")}
                      </p>
                    </div>
                    <p data-w-id="Paragraph 2" className="shop-description">
                      {shop.description}
                    </p>
                    {!isMobile && (
                      <Button
                        onClick={() =>
                          user
                            ? history.push(`/profile?shopId=${shop.id}`)
                            : history.push(`/free-trial`)
                        }
                        style={{ marginTop: "32px" }}
                      >
                        {user ? "Use your membership" : "Get a free membership"}
                      </Button>
                    )}
                    <a
                      href={`https://www.google.com/maps/dir/?api=1&destination=${googleShopAddress}`}
                      className="nav-button w-button"
                      style={{
                        marginTop: 0,
                        border: "none",
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {"Get Directions >"}
                    </a>
                  </div>
                </div>
              </div>
              <div className="column">
                <div data-w-id="Div Block" className="content">
                  <img
                    src={shop.shopImage}
                    loading="lazy"
                    style={{ width: "100%", maxWidth: "600px" }}
                    alt={shop.name}
                    className="image-company"
                    id="shop-cover-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="section"
            style={{ paddingTop: "70px", backgroundColor: "#f7f0e8" }}
          >
            <div className="content">
              <div className="block-hero">
                <h3 data-w-id="Heading 6">What Members Get</h3>
                <div className="shop-perks">
                  <div
                    className="company-info"
                    key={"perk-" + 1}
                    style={{
                      width: getPerksWidth(shop.perks),
                      margin: "0 10px",
                      display: "inline-block",
                      verticalAlign: "top",
                      lineHeight: "140%",
                    }}
                  >
                    <div className="number-company-info">{1 + "."}</div>
                    <p className="heading-company-info">
                      {shopscribeMembership && shopscribeMembership.price
                        ? priceToExtraPercent(
                            shopscribeMembership.price
                          ).toFixed(0)
                        : "Up to " + priceToExtraPercent(100).toFixed(0)}
                      % <b>extra money</b> to spend here
                    </p>
                  </div>
                  {shop.perks.map((perk, i) => {
                    return (
                      <div
                        className="company-info"
                        key={"perk-" + i + 1}
                        style={{
                          width: getPerksWidth(shop.perks),
                          margin: "0 10px",
                          display: "inline-block",
                          verticalAlign: "top",
                          lineHeight: "140%",
                        }}
                      >
                        <div className="number-company-info">{i + 2 + "."}</div>
                        <p className="heading-company-info">{perk}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="content">
              <div className="block-center">
                <h2
                  data-w-id="c0134b8c-83d5-8cb3-75cd-01896103e19b"
                  className="heading"
                >
                  How it works
                </h2>
              </div>
              <div className="w-layout-grid grid-sequence">
                <div className="sequence">
                  <div className="icon-sequence-bg">
                    <div className="number-sequence-bg">
                      <div className="number-sequence">1</div>
                    </div>
                    <img
                      src="images/brand.svg"
                      loading="lazy"
                      alt=""
                      className="icon-sequence"
                    />
                  </div>
                  <h4 className="heading-sequence">
                    Visit {shop.name} in-store
                  </h4>
                  <p
                    className="paragraph-sequence"
                    style={{
                      whiteSpace:
                        typeof shop.address === "string"
                          ? "inherit"
                          : "pre-wrap",
                    }}
                  >
                    <b>
                      {typeof shop.address === "string"
                        ? shop.address === ""
                          ? "They have multiple locations. Check their website to find them"
                          : "Their address is " + shop.address
                        : "Their locations are:\n" + shop.address.join("\n")}
                    </b>
                    .
                  </p>
                </div>
                <img
                  src="images/arrow-1.svg"
                  loading="lazy"
                  data-w-id="Image"
                  alt=""
                  id="w-node-image-45b06075"
                  className="img-arrow---1"
                />
                <div className="sequence">
                  <div className="icon-sequence-bg">
                    <div className="number-sequence-bg">
                      <div className="number-sequence">2</div>
                    </div>
                    <img
                      src="images/scan.svg"
                      loading="lazy"
                      alt=""
                      className="icon-sequence"
                    />
                  </div>
                  <h4 className="heading-sequence">Scan in-store QR code</h4>
                  <p className="paragraph-sequence">
                    When asked to pay, scan the QR code in-store or open your
                    Shopscribe profile to pay with your credit.
                  </p>
                </div>
                <img
                  src="images/arrow-2.svg"
                  loading="lazy"
                  data-w-id="8fdb0733-9c0b-9d9a-d4ac-47309542a143"
                  alt=""
                  id="w-node-_8fdb0733-9c0b-9d9a-d4ac-47309542a143-45b06075"
                  className="img-arrow---2"
                />
                <div data-w-id="Div Block 7" className="sequence">
                  <div className="icon-sequence-bg">
                    <div className="number-sequence-bg">
                      <div className="number-sequence">3</div>
                    </div>
                    <img
                      src="images/icon-6.svg"
                      loading="lazy"
                      alt=""
                      className="icon-sequence"
                    />
                  </div>
                  <h4 className="heading-sequence">Use your credit to pay</h4>
                  <p className="paragraph-sequence">
                    Just type in how much you're spending and show the
                    confirmation to the staff. All done!
                  </p>
                </div>
              </div>
              {!isMobile && (
                <Button
                  onClick={() =>
                    user
                      ? history.push(`/profile?shopId=${shop.id}`)
                      : history.push(`/free-trial`)
                  }
                  style={{ marginTop: "32px" }}
                >
                  {user ? "Use your membership" : "Get a membership"}
                </Button>
              )}
            </div>
          </div>
          <div className="section" style={{ backgroundColor: "#f7f0e8" }}>
            <div className="row" style={{ maxHeight: "400px", width: "80%" }}>
              <div className="column" style={{ margin: "0 10px" }}>
                <div
                  data-w-id="Div Block"
                  className="content"
                  style={{ width: "100%" }}
                >
                  <img
                    src={shop.ownerImage}
                    loading="lazy"
                    alt={shop.name}
                    className="image-company"
                  />
                </div>
              </div>
              <div className="column" style={{ margin: "0 10px" }}>
                <div
                  data-w-id="Div Block"
                  className="content"
                  style={{ width: "100%" }}
                >
                  <img
                    src={shop.profileImage}
                    loading="lazy"
                    alt={shop.name}
                    className="image-company"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="section"
            style={{
              padding: subscriptions.length > 0 ? "auto" : 0,
              backgroundColor: "#f7f0e8",
            }}
          >
            {subscriptions.length > 0 && (
              <>
                <h3 style={{ textAlign: "center" }}>
                  {shop.name} <span className="text-accent">memberships</span>
                </h3>
                <p>
                  {" "}
                  These are subscriptions just for {shop.name}, if you're a
                  regular - they're perfect!
                </p>
              </>
            )}
            <div
              className="block-hero"
              style={{ height: "100%", marginTop: "0" }}
            >
              {subscriptions.map((subscription) => {
                return (
                  <SubscriptionCard
                    subscription={subscription}
                    userData={user}
                    setNotifyModal={setNotifyModal}
                    key={subscription.id}
                  />
                );
              })}
            </div>
          </div>
          <div
            className="section"
            style={{
              backgroundColor: "#f7f0e8",
            }}
          >
            <div className="content">
              <div className="block-hero">
                <h1 data-w-id="Heading 2" className="heading-hero">
                  Discover more stores like{" "}
                  <span className="text-accent">{shop.name}</span>
                </h1>
                <a href="/shops" className="button w-button">
                  More great shops
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <NotifyModal isOpen={isNotifyModal} setIsOpen={setNotifyModal} />
      {isMobile && (
        <div
          style={{
            display: "flex",
            position: "fixed",
            left: 0,
            bottom: 0,
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
            backgroundColor: "#fdf8f4",
            height: "auto",
            boxShadow: "0 4px 12px rgb(0 0 0 / 24%)",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "70%",
              maxWidth: "500px",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "20px 0px",
            }}
          >
            <Button
              small
              onClick={() =>
                user
                  ? history.push(`/profile?shopId=${shop.id}`)
                  : history.push(`/free-trial`)
              }
            >
              {user ? "Use your membership here" : "Try Shopscribe for free"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Shop;
