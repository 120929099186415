import PropTypes from "prop-types";

export const Card = ({ width, height, style, children }) => {
  return (
    <div className="plan" style={{ ...style, width: width, height: height }}>
      {children}
    </div>
  );
};

export default Card;

Card.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
};
