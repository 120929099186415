import { shopsData } from "../../Data";

// The minimum subscription price on Shopscribe
const MIN_PRICE = 20;
// The maximum subscription price on Shopscribe
const MAX_PRICE = 150;
// The price threshold after which subscribers get the maxium discount
const PRICE_THRESHOLD = 60;
// The default membership price that users are charged after the free trial
const PRICE_AFTER_TRIAL = 40;

const SUB_BOX_PRICE = 25;

/*
 * This returns the average discount that shops on Shopscribe are offering
 */
const getAverageDiscount = () => {
  let discounts = shopsData.reduce((result, shop) => {
    if (shop.generalDiscount) result.push(shop.generalDiscount);
    return result;
  }, []);
  return discounts.reduce((a, b) => a + b, 0) / discounts.length || 0;
};

/*
 * This takes an input price the user subscribes to on a monthly basis, and converts that into
 * the average amount of total credit the user is able to use across shops.
 */
export const priceToCredit = (p) => {
  let price = parseFloat(p);
  let discount = priceToDiscount(price);
  // Once we know the discount to apply, we use this formula to transform in additonal credit and
  // get the total credit: price * 100 / (100 - discount)
  return parseFloat((price * 100) / (100 - discount)).toFixed(2);
};

/*
 * This takes an input price the user subscribes to on a monthly basis, and returns the additional credit % they get
 */
export const priceToExtraPercent = (p) => {
  let price = parseFloat(p);
  let discount = priceToDiscount(price);
  return 100 / (1 - discount / 100) - 100;
};

/*
 * This takes a price that user is paying and returns the savings they're making on that purchase
 */
export const getAmountSaved = (p, discount) => {
  return ((p * discount) / 100).toFixed(2);
};

/*
 * This returns the default membership price that users are charged after the free trial
 */
export const getDefaultPrice = () => {
  return PRICE_AFTER_TRIAL;
};

export const getDefaultSubBoxPrice = () => {
  return SUB_BOX_PRICE;
};

/*
 * This takes an input price (from a range of prices) and convert to the corresponding discount in a range of discounts
 */
export const priceToDiscount = (price) => {
  const averageDiscount = getAverageDiscount();
  // Given the range of prices, this defines where the input value is in the range: ((input - min) * 100) / (max - min)
  let positionInRange =
    price >= PRICE_THRESHOLD
      ? 100
      : ((price - MIN_PRICE) * 100) / (PRICE_THRESHOLD - MIN_PRICE);
  // Given the position in the price range, we take the same position in the discount range using: (position * (max - min) / 100) + min
  return (positionInRange * (averageDiscount - 13)) / 100 + 13;
};

export const getMaxPrice = () => {
  return MAX_PRICE;
};

export const getMinPrice = () => {
  return MIN_PRICE;
};
