import { useState } from "react";
import PropTypes from "prop-types";
import { Range, getTrackBackground } from "react-range";

const Slider = ({ step, min, max, value, onChange, price = false, style }) => {
  const [values, setValues] = useState([value]);

  return (
    <div
      style={{
        ...style,
        width: "100%",
        fontSize: "14px",
      }}
    >
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        onChange={(values) => {
          setValues(values);
          onChange(values[0]);
        }}
        onFinalChange={(values) => onChange(values[0])}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values,
                  colors: ["rgb(255, 126, 103)", "rgb(226, 226, 226)"],
                  min: min,
                  max: max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              height: "20px",
              width: "20px",
              borderRadius: "100%",
              backgroundColor: "rgb(255, 126, 103)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 10px rgba(255, 126, 103, 0.33)",
              outline: "none",
            }}
          >
            <output
              style={{
                marginBottom: "55px",
                fontSize: "22px",
                fontWeight: "bold",
                color: "rgb(255, 126, 103)",
                position: "absolute",
                top: "-30px",
              }}
            >
              {price && "£"}
              {values[0]}
            </output>
          </div>
        )}
      />
    </div>
  );
};

export default Slider;

Slider.propTypes = {
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  price: PropTypes.bool,
  style: PropTypes.object,
};
