import PropTypes from "prop-types";

const allItems = [
  { name: "coffee", unitPrice: 2.5, shopName: "Urban Social Coffee" },
  { name: "breakfast", unitPrice: 5, shopName: "Ginette French Café" },
  { name: "meal", unitPrice: 9, shopName: "Babuji Indian Restaurant" },
  { name: "fruit basket", unitPrice: 3.5, shopName: "Nature's Delight" },
  { name: "bubble tea", unitPrice: 3, shopName: "Dragon Cat Café" },
  { name: "treatment", unitPrice: 20, shopName: "The Beauty Retreat" },
  { name: "dinner", unitPrice: 15, shopName: "Faros London" },
];

const TangibleExample = ({ price, style }) => {
  // We return three lines, each line has a maximum spend (budget)
  const budgets = [price * 0.15, price * 0.35, price * 0.5];
  let usedAlready = [];

  // This converts a budget to the actual items you can get with that budget
  const getItemsFromBudget = (b) => {
    // Remove items more expensive than budget or that have been used already
    let items = allItems.filter(
      (i) => i.unitPrice <= b && !usedAlready.includes(i.name)
    );
    if (items.length === 0) return { quantity: 1, item: allItems[0] };
    // Find the item which price is closest to budget divided by 3
    let closest = { name: "", priceDistance: Infinity };
    items.forEach((item) => {
      let distance = Math.abs(item.unitPrice - b / 3);
      if (distance < closest.priceDistance)
        closest = { name: item.name, priceDistance: distance };
    });
    // See how many of those you can fit in the budget
    let item = items.find((i) => i.name === closest.name);
    let quantity = Math.round(b / item.unitPrice);
    usedAlready.push(item.name);
    return { quantity, item };
  };

  return (
    <div
      className="shop-description"
      style={{ ...style, fontSize: "12px", textAlign: "left" }}
    >
      {budgets.map((budget, i) => {
        if (i === 0) usedAlready = [];
        let { quantity, item } = getItemsFromBudget(budget);
        return (
          <span key={"example-" + i}>
            ✔ {i > 0 && "and "}
            {quantity} {item.name + (quantity > 1 ? "s" : "")} from{" "}
            {item.shopName}
            <br />
          </span>
        );
      })}
      <span>✔ and Special members' perks at all shops!</span>
    </div>
  );
};

export default TangibleExample;

TangibleExample.propTypes = {
  price: PropTypes.number,
  style: PropTypes.object,
};
