import { useEffect, useState } from "react";
import { subscribeToShopscribe } from "../../firebase.js";
import { useParams, useHistory } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import Loader from "react-loader-spinner";
import { priceToCredit, getDefaultPrice } from "../../Components";
import { request, subscribeToBox } from "../../backend.js";

const FREE_TRIAL_CREDIT = 7;
const FREE_TRIAL_DURATION = 7;

const Checkout = ({ user, refreshUserInfo, deployed }) => {
  const { amount } = useParams();
  const stripe = useStripe();
  const history = useHistory();

  const paymentId = new URLSearchParams(window.location.search).get(
    "session_id"
  );

  const email = new URLSearchParams(window.location.search).get("email");
  const type = new URLSearchParams(window.location.search).get("type");

  const [subFunctionRun, setSubFunctionRun] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    async function redirect() {
      if (user) {
        // Prevent user with subscription to get a trial
        if (amount === "trial" && user.membershipId) {
          history.push("/profile");
        } else if (paymentId) {
          const subId = await getSubId();
          if (subId) {
            subscribe(subId);
          } else {
            history.push(`/profile`);
          }
        } else if (!paymentId && !user.demo) stripeCheckout();
      } else {
        if (!paymentId && email) stripeCheckout();
      }
    }
    redirect();
    //eslint-disable-next-line
  }, [user]);

  const getSubId = async () => {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/get-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sessionId: paymentId,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        return result.subId;
      });
  };

  const subscribe = async (subId) => {
    if (!subFunctionRun && paymentId) {
      setSubFunctionRun(true);
    }
    if (type === "box") {
      const createSub = localStorage.getItem("createSub");
      const recipientName = localStorage.getItem("recipientName");
      const address = localStorage.getItem("address");
      const deliveryInfo = localStorage.getItem("deliveryInfo");
      const dietaryInfo = localStorage.getItem("dietaryInfo");
      const deliveryDate = localStorage.getItem("deliveryDate");
      const aptOrSuite = localStorage.getItem("aptOrSuite");
      const town = localStorage.getItem("town");
      const postCode = localStorage.getItem("postCode");
      const boxTypeId = localStorage.getItem("boxTypeId");
      const batchId = localStorage.getItem("batchId");
      if (createSub) {
        localStorage.removeItem("createSub");
        // Pause subscription until next batch / relevant cut off date charge
        await request(
          `${process.env.REACT_APP_BACKEND_URL}/pause-subscription`,
          "POST",
          {
            subId: subId,
          }
        );
        subscribeToBox(
          user,
          subId,
          boxTypeId,
          recipientName,
          address,
          aptOrSuite,
          town,
          postCode,
          deliveryInfo,
          dietaryInfo,
          deliveryDate,
          batchId
        )
          .then(() => refreshUserInfo())
          .then(() => {
            localStorage.removeItem("recipientName");
            localStorage.removeItem("address");
            localStorage.removeItem("deliveryInfo");
            localStorage.removeItem("dietaryInfo");
            localStorage.removeItem("deliveryDate");
            localStorage.removeItem("aptOrSuite");
            localStorage.removeItem("town");
            localStorage.removeItem("postCode");
            localStorage.removeItem("boxTypeId");
            localStorage.removeItem("batchId");
            localStorage.removeItem("cutOffDate");
            history.push(`/profile`);
          });
      } else {
        console.error(
          "Tried to create sub without local storage - sub not created"
        );
      }
    } else {
      subscribeToShopscribe({
        user: user,
        price: parseFloat(amount === "trial" ? getDefaultPrice() : amount),
        totalCredit: parseFloat(
          amount === "trial" ? FREE_TRIAL_CREDIT : priceToCredit(amount)
        ),
        id: subId,
        demo: user.demo,
        freeTrialDuration: amount === "trial" ? FREE_TRIAL_DURATION : 0,
      })
        .then(() => refreshUserInfo())
        .then(() => {
          history.push(`/profile`);
        });
    }
  };

  const stripeCheckout = () => {
    let postBody = {};
    postBody.amount = amount === "trial" ? getDefaultPrice() : amount;
    postBody.customerEmail = email ? email : user.email;
    postBody.frontendUrl = window.location.origin;
    postBody.freeTrial = amount === "trial" ? FREE_TRIAL_DURATION : 0;
    postBody.cutOffDate = localStorage.getItem("cutOffDate");
    if (type === "box") {
      postBody.productId = process.env.REACT_APP_STRIPE_SUB_BOX_PRODUCT_ID;
      postBody.type = "box";
    }
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/stripe-checkout`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(postBody),
    })
      .then((response) => response.json())
      .then((result) => {
        stripe.redirectToCheckout({
          sessionId: result.sessionId,
        });
      });
  };

  return (
    <div className="account">
      <div>
        {user && user.demo ? (
          <div>
            <p>
              You're on a <b>demo account</b>, you won't be able to really use
              your membership. Register a new account to purchase a valid
              membership
            </p>
            <div style={{ margin: "50px 0" }}>
              <button
                data-w-id="eaf3ca25-4a9b-efa9-341b-8e26bf9b8a1f"
                onClick={() => subscribe()}
                className="button w-button"
                style={{ fontFamily: "DM Sans" }}
              >
                I understand :)
              </button>
            </div>
          </div>
        ) : (
          <div className="block-account">
            <Loader type="TailSpin" color="#ff7e67" height={80} width={80} />
            <h6 style={{ color: "#ff7e67" }}>
              {paymentId
                ? "Receiving your payment..."
                : "Setting up secure payment for you..."}
            </h6>
            <p style={{ color: "#ff7e67", fontSize: "smaller" }}>
              (Do not leave this page!)
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkout;
