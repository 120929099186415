import Modal from "react-modal";

const NotifyModal = ({ isOpen, setIsOpen }) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div style={{ fontFamily: "DM Sans !important" }}>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        contentLabel="Pickup"
        style={{
          overlay: {
            position: "fixed",
            display: "inline-flex",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: "15px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999999999
          },
          content: {
            margin: "auto",
            padding: "25px",
            boxShadow: "none",
            outline: "none"
          }
        }}
        className="company-info"
      >
        <div>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center"
            }}
          >
            <p>
              Oh no! This subscription is currently a <b>work in progress</b> :({" "}
              <br /> We'll ping you as soon as it's up.
            </p>
            <a
              href="https://forms.gle/c92LTVQPNY4jWtAE7"
              target="_blank"
              rel="noreferrer"
              className="button-small outline w-button"
            >
              Notify Me
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NotifyModal;
