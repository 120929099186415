import Modal from "react-modal";
import React, { useState, useEffect } from "react";
import ReactMomentCountDown from "react-moment-countdown";
import Lottie from "react-lottie-player";
import moment from "moment";
import { Card } from "../../Components";

import animationFile from "./validAnimation.json";

const PICKUP_TIME = 2;

const ShopConfirmation = ({
  isOpen,
  setClosed,
  shopName,
  amount,
  isDemoAccount,
}) => {
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    setCountdown(moment().add(PICKUP_TIME, "minutes"));
  }, [isOpen]);

  const closeModal = () => {
    setClosed(false);
    setCountdown(null);
  };

  return (
    <div style={{ fontFamily: "DM Sans !important" }}>
      {shopName && amount && (
        <div>
          <Modal
            isOpen={isOpen}
            onRequestClose={() => closeModal()}
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
            contentLabel="Pickup"
            style={{
              overlay: {
                position: "fixed",
                display: "inline-flex",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 999999999,
              },
              content: {
                maxWidth: "375px",
                width: "100%",
                margin: "auto",
                padding: "25px",
                boxShadow: "none",
                outline: "none",
                backgroundColor: "transparent",
              },
            }}
            className="company-info"
          >
            <Card width="100%">
              <div style={{ width: "100%" }}>
                <img
                  src="/images/close.svg"
                  loading="lazy"
                  alt=""
                  style={{
                    position: "relative",
                    margin: "-15px -15px 0 0",
                    width: "15px",
                    height: "auto",
                    opacity: ".33",
                    float: "right",
                    cursor: "pointer",
                  }}
                  onClick={() => closeModal()}
                />
              </div>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src="/images/logo-orange.svg"
                  loading="lazy"
                  alt=""
                  className="logo"
                />
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "110%",
                    opacity: ".5",
                    marginTop: "15px",
                    marginBottom: 0,
                  }}
                >
                  Show this to the shop staff
                  <br />
                  in the next{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "larger",
                      lineHeight: "140%",
                    }}
                  >
                    {countdown && (
                      <ReactMomentCountDown
                        toDate={countdown}
                        targetFormatMask="m:ss"
                        onCountdownEnd={() => closeModal()}
                      />
                    )}{" "}
                    minutes
                  </span>
                </p>
                <div className="content" style={{ marginTop: "50px" }}>
                  <div
                    className="shop-description"
                    style={{ marginBottom: "5px" }}
                  >
                    Shop
                  </div>
                  <h3>{shopName}</h3>
                  <div
                    className="shop-description"
                    style={{ marginBottom: "5px" }}
                  >
                    Amount
                  </div>
                  <h3 style={{ marginBottom: 0 }}>
                    £{parseFloat(amount).toFixed(2)}
                  </h3>
                </div>
              </div>
            </Card>
            <br />
            <Card width="100%">
              {isDemoAccount ? (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <h4 style={{ marginBottom: 0, color: "#ff7e67" }}>
                    THIS IS NOT A VALID MEMBERSHIP
                  </h4>
                  <p
                    style={{
                      fontSize: "14px",
                      lineHeight: "110%",
                      opacity: ".5",
                      marginTop: "5px",
                      marginBottom: 0,
                    }}
                  >
                    You're on a demo account
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Lottie
                    loop
                    animationData={animationFile}
                    play
                    style={{
                      width: "80px",
                      height: "80px",
                      transform: "scale(1.5)",
                    }}
                  />
                  <h4
                    style={{
                      margin: "10px 0 0 0",
                      color: "#61B329",
                      fontSize: "24px",
                    }}
                  >
                    Valid Membership
                  </h4>
                </div>
              )}
            </Card>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ShopConfirmation;
