import "./App.css";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  Homepage,
  Shop,
  Shops,
  UserProfile,
  Login,
  SignUp,
  SingleShopCheckout,
  Checkout,
  Pricing,
  ShopDashboard,
  About,
  MembershipPicker,
  FreeTrial,
  SubBoxesSignUp,
  Giveaway,
  MigrateUser,
} from "./Pages";
import { AuthRoute } from "./Components";
import { shopsData } from "./Data";
import { auth } from "./firebase";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import LogRocket from "logrocket";
import { generateUserDocument } from "./backend";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

const DEPLOYED =
  window.location.hostname !== "localhost" &&
  window.location.hostname !== "127.0.0.1";

const App = () => {
  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [queryString, setQueryString] = useState("");

  // eslint-disable-next-line
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
  );

  const refreshUserInfo = async () => {
    const userData = await generateUserDocument(user);
    setUser(userData);
  };

  useEffect(() => {
    const searchString = window.location.search;
    setQueryString(searchString);
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const user = await generateUserDocument(userAuth);
        setUser(user);
        // This is an example script - don't forget to change it!
        if (user) {
          LogRocket.identify(user.uid, {
            name: user.displayName,
            email: user.email,

            // Add your own custom user variables here, ie:
            subBoxUser: user.subBoxUser,
            subBoxPaid: user.subBoxPaid,
            subBoxId: user.subBoxId,
          });
        }
      } else {
        setUser(null);
      }
      setAuthLoading(false);
    });
  }, []);

  return (
    <div className="App">
      <Elements stripe={stripePromise}>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                window.location.href = "hiring.html";
              }}
            />
            <Route
              exact
              path="/apply"
              render={() => {
                window.location.href = "apply.html";
              }}
            />
            <Route exact path="/membership">
              <Homepage user={user} />
            </Route>
            <Route
              path="/forshops"
              render={() => {
                window.location.href = "forshops.html";
              }}
            />
            <Route exact path="/shops">
              <Shops user={user} />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route exact path="/box">
              <Redirect to="/" />
            </Route>
            <Route path="/box-signup">
              <SubBoxesSignUp user={user} authLoading={authLoading} />
            </Route>
            <AuthRoute
              path="/profile"
              user={user}
              loading={authLoading}
              auth={
                <UserProfile user={user} refreshUserInfo={refreshUserInfo} />
              }
              unAuth={<Redirect to="/login" />}
            />
            <AuthRoute
              path="/login"
              user={user}
              loading={authLoading}
              auth={<Redirect to="/profile" />}
              unAuth={<Login />}
            />
            <AuthRoute
              path="/signup"
              user={user}
              loading={authLoading}
              auth={<Redirect to="/profile" />}
              unAuth={<SignUp />}
            />
            <AuthRoute
              path="/qrcode"
              user={user}
              loading={authLoading}
              auth={
                <Redirect to={{ pathname: "/profile", search: queryString }} />
              }
              unAuth={<Redirect to="/" />}
            />
            <Route path="/pricing">
              <Pricing />
            </Route>
            <Route path="/free-trial">
              <FreeTrial user={user} shopsData={shopsData} />
            </Route>
            <Route path="/jump-on-board">
              <MembershipPicker
                user={user}
                refreshUserInfo={refreshUserInfo}
                shopsData={shopsData}
              />
            </Route>
            <Route path="/giveaway">
              <Giveaway />
            </Route>
            <Route
              path="/admin"
              component={() => {
                window.location.href =
                  "https://cloud.prisma.io/krthush/shopscribe/production/databrowser";
                return null;
              }}
            />
            <Route path="/migrate-user/:userId">
              <MigrateUser />
            </Route>
            <Route path="/checkout/:amount">
              <Checkout
                user={user}
                refreshUserInfo={refreshUserInfo}
                deployed={DEPLOYED}
              />
            </Route>
            <Route path="/:shopId/dashboard">
              <ShopDashboard shopsData={shopsData} />
            </Route>
            <Route path="/:shopId/:subscriptionId/checkout">
              <SingleShopCheckout
                shopsData={shopsData}
                user={user}
                refreshUserInfo={refreshUserInfo}
                deployed={DEPLOYED}
              />
            </Route>
            <Route path="/:shopId">
              <Shop shopsData={shopsData} user={user} />
            </Route>
          </Switch>
        </Router>
      </Elements>
    </div>
  );
};

export default App;
