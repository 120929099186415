const Footer = () => {
  return (
    <div
      className="section-footer late-load"
      id="contact-shopscribe"
      style={{ backgroundColor: "#f7f0e8" }}
    >
      <div className="content">
        <div className="w-layout-grid grid-footer">
          <div className="block-footer">
            <img
              src="images/logo.svg"
              loading="lazy"
              alt=""
              className="logo-footer"
            />
            <p className="paragraph-footer">
              First Floor, 57 Greek Street
              <br />
              London W1D 3DX
              <br />
              <a href="mailto:info@shopscribe.com">info@shopscribe.com</a>
            </p>
            <div className="footer-down">
              <div className="text-footer-down">
                Shopscribe, Inc. 2021. All rights reserved :)
              </div>
            </div>
          </div>
          <div></div>
          <div></div>
          <div style={{ margin: "0 auto" }}>
            <div style={{ display: "flex" }}>
              <a
                href="https://www.linkedin.com/company/shopscribe"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="images/linkedin.svg"
                  alt="linkedin logo"
                  loading="lazy"
                  className="logo-footer"
                />
              </a>
              <a
                href="https://www.instagram.com/weareshopscribe/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="images/instagram.svg"
                  loading="lazy"
                  alt=""
                  className="logo-footer"
                />
              </a>
              <a
                href="https://www.facebook.com/weareshopscribe"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="images/facebook.svg"
                  loading="lazy"
                  alt=""
                  className="logo-footer"
                  style={{ marginRight: 0 }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
