import { useEffect } from "react";
import { subscribeToShop } from "../../firebase.js";
import { useParams, useHistory } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import Loader from "react-loader-spinner";
import uuid from "react-uuid";

const SingleShopCheckout = ({ user, shopsData, refreshUserInfo, deployed }) => {
  const { shopId, subscriptionId } = useParams();
  const stripe = useStripe();
  const history = useHistory();

  let shop = shopsData.find((x) => x.id === shopId);
  let subscription = shop.subscriptions
    ? shop.subscriptions.find((x) => x.id === subscriptionId)
    : null;

  const paymentId = new URLSearchParams(window.location.search).get(
    "session_id"
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    async function redirect() {
      if (user) {
        // If payment already successful, create the subscription
        if (paymentId) {
          let paymentIsValid = await validatePayment();
          if (
            paymentIsValid &&
            (!user.subscription ||
              (user.subscriptions &&
                !user.subscriptions.some((e) => e.uniqueId === paymentId)))
          ) {
            subscribe();
          }
        }
        // Otherwise, go to the checkout page
        if (!paymentId && !user.demo) stripeCheckout();
      }
    }
    redirect();
    //eslint-disable-next-line
  }, [user]);

  const validatePayment = async () => {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/get-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sessionId: paymentId,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        return result.valid;
      });
  };

  const subscribe = async () => {
    subscribeToShop(
      user,
      shop.id,
      subscription,
      -1,
      user.demo,
      paymentId ?? uuid()
    )
      .then(() => refreshUserInfo())
      .then(() => {
        // Go to profile
        history.push(`/profile`);
      });
  };

  const stripeCheckout = () => {
    return fetch(
      `${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          subscription: subscription,
          shopId: shopId,
          customerEmail: user.email,
          frontendUrl: window.location.origin,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        stripe.redirectToCheckout({
          sessionId: result.sessionId,
        });
      });
  };

  return (
    <div className="account">
      <div>
        {user && user.demo ? (
          <div>
            <p>
              You're on a <b>demo account</b>, you won't be able to really use
              your membership. Register a new account to purchase a valid
              membership
            </p>
            <div style={{ margin: "50px 0" }}>
              <button
                data-w-id="eaf3ca25-4a9b-efa9-341b-8e26bf9b8a1f"
                onClick={() => subscribe()}
                className="button w-button"
                style={{ fontFamily: "DM Sans" }}
              >
                I understand :)
              </button>
            </div>
          </div>
        ) : (
          <div className="block-account">
            <Loader type="TailSpin" color="#ff7e67" height={80} width={80} />
            <h6 style={{ color: "#ff7e67" }}>
              {paymentId
                ? "Receiving your payment..."
                : "Setting up secure payment for you..."}
            </h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleShopCheckout;
