import PropTypes from "prop-types";
import React, { useState } from "react";

const Button = ({
  secondary,
  onClick,
  width = "100%",
  small,
  children,
  style = {},
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      onClick={onClick}
      className="w-button button"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        backgroundColor: secondary ? "transparent" : "#ff7e67",
        color: secondary ? "#0c1d37" : "#fff",
        border: secondary ? "1px solid #0c1d37" : "none",
        boxShadow: secondary
          ? "none"
          : hovered
          ? "0px 15px 25px rgba(255, 126, 103, 0.3)"
          : "0px 5px 15px rgba(255, 126, 103, 0.4)",
        padding: small ? "10px 25px" : "15px 30px",
        fontSize: secondary || small ? "17px" : "20px",
        transform: hovered ? "translateY(-5px)" : "none",
        transition: "all 0.3s ease 0s",
        outline: "none",
        margin: small ? 0 : "12px 0",
        width: width,
        textAlign: "center",
        maxWidth: "350px",
        ...style,
      }}
    >
      <div>{children}</div>
    </div>
  );
};

export default Button;

Button.propTypes = {
  secondary: PropTypes.bool,
  width: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
};
