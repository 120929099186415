import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { getFirebaseUserDocument } from "../../firebase";

const SubscriptionCard = ({ subscription, userData, setNotifyModal }) => {
  const history = useHistory();
  let { shopId } = useParams();
  const [user, setUser] = useState(null);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  const subscribe = async () => {
    const userDocument = await getFirebaseUserDocument(user.uid);
    if (userDocument && userDocument.uid) {
      history.push(
        user
          ? `/${shopId}/${subscription.id}/checkout`
          : `/signup?callback=/${shopId}/${subscription.id}/checkout`
      );
    } else {
      alert(
        "Sorry! we're phasing out memberships - we don't support memberships for box subscribers."
      );
    }
  };

  const renderPrice = (subscription) => {
    const frequency =
      subscription.frequency === 1
        ? "day"
        : subscription.frequency === 7
        ? "week"
        : subscription.frequency === 14
        ? "two weeks"
        : subscription.frequency === 30
        ? "month"
        : subscription.frequency === 180
        ? "6 months"
        : subscription.frequency === 365
        ? "year"
        : subscription.frequency + "days";
    return "£" + subscription.price + "/" + frequency;
  };

  return (
    <div
      className="subscription-card"
      key={subscription.id}
      onClick={() =>
        subscription.placeholder ? setNotifyModal(true) : subscribe()
      }
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img
        src={subscription.image}
        loading="lazy"
        alt=""
        className="shop-profile-landing"
      />
      <div className="shop-text-landing">
        <h4 style={{ marginBottom: "5px" }}>{subscription.title}</h4>
        <p>{subscription.description}</p>
      </div>
      <div className="subs-price">
        {hovered ? (
          <div
            onClick={() => {}}
            style={{
              marginBottom: "25px",
              backgroundColor: "#0c1d37",
              color: "white",
              padding: "5px 15px",
            }}
            className="nav-button w-button"
          >
            Subscribe Now {">"}
          </div>
        ) : (
          <h4 style={{ color: "#ff7e67" }}>{renderPrice(subscription)}</h4>
        )}
        <p className="cancel-label">Cancel anytime</p>
      </div>
    </div>
  );
};

export default SubscriptionCard;
