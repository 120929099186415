import { React, Component } from "react";
import userMarker from "./userMarker.svg";

const imageOffset = {
  left: 10,
  top: 15,
};

class UserMarker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  render() {
    const { left, top, onClick } = this.props;

    const style = {
      position: "absolute",
      transform: `translate(${left - imageOffset.left}px, ${
        top - imageOffset.top
      }px)`,
      cursor: onClick ? "pointer" : "default",
    };

    return (
      <div style={style} className="pigeon-click-block">
        <img
          src={userMarker}
          width={15}
          height={15}
          alt=""
          style={{ boxShadow: "0px 0px 5px #005EB8", borderRadius: "100%" }}
        />
      </div>
    );
  }
}

export default UserMarker;
