import { firestore, getFirebaseUserDocument } from "./firebase";
import emailjs from "emailjs-com";
import { createAirtableSubBoxRecord } from "./airtable";
import moment from "moment";

// Fetch wrapper to make requests to backend server API
export const request = async (url, method, body) => {
  const response = await fetch(url, {
    method: method,
    headers: { "Content-Type": "application/json" },
    body: body ? JSON.stringify(body) : undefined,
  });
  if (response.status < 200 && response.status > 299) {
    console.log(response);
    console.error("API fetch error");
    return null;
  } else if (response.status === 204) {
    return null;
  } else {
    return await response.json();
  }
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;
  const userId = user.id || user.uid;
  let body = { ...additionalData };
  body.id = userId;
  if (user.email) body.email = user.email;
  if (body.displayName) body.fullName = body.displayName;
  let userDocument;
  const userOnFirebase = await getFirebaseUserDocument(userId);
  if (userOnFirebase && !userOnFirebase.deprecated) {
    userDocument = userOnFirebase;
  } else {
    const userOnPostgres = await request(
      `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`,
      "GET"
    );
    if (userOnPostgres) {
      userDocument = userOnPostgres;
    } else {
      userDocument = await request(
        `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`,
        "POST",
        body
      );
    }
    if (userDocument.subId) {
      const sub = await request(
        `${process.env.REACT_APP_BACKEND_URL}/subs/${userDocument.subId}`,
        "GET"
      );
      userDocument.sub = sub;
      userDocument.subBoxId = userDocument.subId;
    }
    userDocument.displayName = userDocument.fullName;
  }
  userDocument.id = userId;
  userDocument.uid = userId;
  if (userDocument.boxes && userDocument.boxes.length > 0) {
    // TODO: currently using 1st box as "only" one for now
    userDocument.subBoxPaid = true;
    userDocument.subBoxUser = true;
    userDocument.address = userDocument.boxes[0].address;
    userDocument.aptOrSuite = userDocument.boxes[0].aptOrSuite;
    userDocument.town = userDocument.boxes[0].town;
    userDocument.postCode = userDocument.boxes[0].postCode;
    userDocument.deliveryInfo = userDocument.boxes[0].deliveryInfo;
    userDocument.dietaryInfo = userDocument.boxes[0].dietaryInfo;
    userDocument.displayName = userDocument.boxes[0].recipientName;
    userDocument.nextDeliveryDate = userDocument.boxes[0].deliveryDate;
  }
  return userDocument;
};

export const subscribeToBox = async (
  user,
  subId,
  boxTypeId,
  recipientName,
  address,
  aptOrSuite,
  town,
  postCode,
  deliveryInfo,
  dietaryInfo,
  deliveryDate,
  batchId,
  sendConfirmationEmail = true
) => {
  const userId = user.id || user.uid;
  if (userId) {
    const subOnPostgres = await request(
      `${process.env.REACT_APP_BACKEND_URL}/subs`,
      "POST",
      {
        id: subId,
        userId: userId,
      }
    );
    if (subOnPostgres) {
      const boxOnPostgres = await request(
        `${process.env.REACT_APP_BACKEND_URL}/boxes`,
        "POST",
        {
          subId: subId,
          userId: userId,
          boxTypeId: boxTypeId,
          recipientName: recipientName,
          address: address,
          deliveryInfo: deliveryInfo,
          dietaryInfo: dietaryInfo,
          deliveryDate: deliveryDate,
          aptOrSuite: aptOrSuite,
          town: town,
          postCode: postCode,
          batchId: batchId,
        }
      );
      if (boxOnPostgres) {
        createAirtableSubBoxRecord(
          25,
          moment(deliveryDate).format("YYYY-MM-DD"),
          recipientName,
          user.phone,
          address + aptOrSuite + town + postCode,
          user.email,
          deliveryInfo,
          dietaryInfo,
          userId
        );
        // Send confirmation email
        sendConfirmationEmail &&
          !user.demo &&
          emailjs.send(
            "service_gmail",
            "template_boxes",
            { email_address: user.email },
            "user_yMEuniWliXIzjHSwGSRJi"
          );
      } else {
        console.error("Error creating box on postgres");
      }
    } else {
      console.error("Error creating sub on postgres");
    }
  } else {
    console.error("Error no user id for creating subTobox");
  }
};

export const migrateUserToPostgres = async (userId) => {
  const userDocument = await firestore.doc(`users/${userId}`).get();
  const userOnFirebase = userDocument.data();
  if (userOnFirebase) {
    let body = {};
    body.id = userId;
    body.email = userOnFirebase.email;
    if (userOnFirebase.displayName) body.fullName = userOnFirebase.displayName;
    if (userOnFirebase.phone) body.phone = userOnFirebase.phone;
    if (userOnFirebase.points) body.points = userOnFirebase.points;
    if (userOnFirebase.subBoxSubId) body.subId = userOnFirebase.subBoxSubId;
    const userDocument = await request(
      `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`,
      "POST",
      body
    );
    console.log(userDocument);
    if (userOnFirebase.subBoxPaid) {
      if (userOnFirebase.subBoxSubId) {
        let subDocument = null;
        subDocument = await request(
          `${process.env.REACT_APP_BACKEND_URL}/subs/${userOnFirebase.subBoxSubId}`,
          "GET"
        );
        if (!subDocument) {
          subDocument = await request(
            `${process.env.REACT_APP_BACKEND_URL}/subs/`,
            "POST",
            {
              id: userOnFirebase.subBoxSubId,
              userId: userId,
            }
          );
        }
        console.log(subDocument);
        if (subDocument) {
          let boxBody = {};
          boxBody.subId = subDocument.id;
          boxBody.userId = userId;
          boxBody.boxTypeId = 1;
          boxBody.recipientName = userOnFirebase.displayName
            ? userOnFirebase.displayName
            : "unknown";
          boxBody.address = userOnFirebase.address
            ? userOnFirebase.address
            : "unknown";
          if (userOnFirebase.deliveryInfo)
            boxBody.deliveryInfo = userOnFirebase.deliveryInfo;
          if (userOnFirebase.dietaryInfo)
            boxBody.dietaryInfo = userOnFirebase.dietaryInfo;
          if (userOnFirebase.nextDeliveryDate)
            boxBody.deliveryDate = moment(
              userOnFirebase.nextDeliveryDate
            ).toISOString();
          if (userDocument.boxes.length < 1) {
            const boxDocument = await request(
              `${process.env.REACT_APP_BACKEND_URL}/boxes/`,
              "POST",
              boxBody
            );
            console.log(boxDocument);
          }
        }
      } else {
        throw new Error("Error in logic for migration");
      }
    }
    if (userDocument) {
      await firestore.doc(`users/${userId}`).update({
        deprecated: true,
      });
    }
    return userDocument;
  } else {
    return {};
  }
};
