import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { Link, useHistory } from "react-router-dom";
import { generateUserDocument } from "../../backend.js";
import { signInWithGoogle, auth } from "../../firebase.js";

const errorCodes = {
  "auth/invalid-email": "That doesn't look like an email address :)",
  "auth/weak-password": "The password must be 6 characters long or more.",
};

const SignUp = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const successCallback = new URLSearchParams(window.location.search).get(
    "callback"
  );

  const createUserWithEmailAndPasswordHandler = async (
    event,
    email,
    password
  ) => {
    event.preventDefault();
    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      generateUserDocument(user, { displayName });
      if (successCallback) history.push(successCallback);
    } catch (error) {
      setError(errorCodes[error.code] ?? "Error on signing up, try again!");
      setSubmitted(false);
    }

    setEmail("");
    setPassword("");
    setDisplayName("");
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    } else if (name === "displayName") {
      setDisplayName(value);
    }
  };

  return (
    <div>
      <div>
        <div className="account">
          <a
            href="/"
            data-w-id="f6d39ff8-752c-4106-c067-25966622553d"
            className="brand-account w-nav-brand"
          >
            <img
              src="images/logo-orange.svg"
              loading="lazy"
              alt=""
              className="logo"
            />
          </a>
          {!submitted && (
            <div className="fixWidth">
              <div
                data-w-id="1e4857dc-6e9f-b392-2236-96c15a7560e1"
                className="block-account"
              >
                <div className="account-form">
                  <h3 className="heading-form">Sign Up</h3>
                  <p className="paragraph-form">
                    Welcome to Shopscribe, please register here to enjoy your
                    first subscription!
                  </p>
                  {error !== null && (
                    <div className="error-message w-form-fail">
                      <div className="error-text">{error}</div>
                    </div>
                  )}
                  <div className="form-block w-form">
                    <div data-name="Sign up" className="form">
                      <input
                        type="text"
                        className="text-field w-input"
                        maxLength="256"
                        name="displayName"
                        value={displayName}
                        placeholder="Full name"
                        id="name"
                        onChange={(event) => onChangeHandler(event)}
                      />
                      <input
                        type="email"
                        className="text-field w-input"
                        maxLength="256"
                        name="userEmail"
                        value={email}
                        placeholder="Email address"
                        id="Email"
                        required=""
                        onChange={(event) => onChangeHandler(event)}
                      />
                      <input
                        type="password"
                        className="text-field w-input"
                        maxLength="256"
                        name="userPassword"
                        value={password}
                        placeholder="Password"
                        id="Password"
                        required=""
                        onChange={(event) => onChangeHandler(event)}
                      />
                      <input
                        type="submit"
                        value="Submit"
                        className="submit-button orange w-button"
                        onClick={(event) => {
                          createUserWithEmailAndPasswordHandler(
                            event,
                            email,
                            password
                          );
                          setSubmitted(true);
                        }}
                      />
                      <div className="text-form">or</div>
                      <div
                        className="button-login w-inline-block"
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          setSubmitted(true);
                          let result = await signInWithGoogle();
                          if (successCallback && result)
                            history.push(successCallback);
                          if (!result) setSubmitted(false);
                        }}
                      >
                        <img
                          src="images/icon-google.svg"
                          loading="lazy"
                          data-w-id="Image"
                          alt=""
                          className="icon-login"
                        />
                        <div className="text-login">
                          Sign up with Google
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-account">
                <p>
                  Used <b>just an email</b> to get your subscription? <br />
                  <b>Sign up</b> with the same email to get into your account!
                </p>
              </div>
              <div
                data-w-id="c79bff7a-25bf-c657-cba2-061e2a3f4e5e"
                className="text-account"
              >
                Already have an account?{" "}
                <Link
                  to={`/login${
                    successCallback ? "?callback=" + successCallback : ""
                  }`}
                >
                  Login
                </Link>
              </div>
            </div>
          )}
          {submitted && (
            <div className="block-account">
              <Loader type="TailSpin" color="#ff7e67" height={80} width={80} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default SignUp;
