import React, { useState } from "react";
import Modal from "react-modal";
import Loader from "react-loader-spinner";
import { subscribeToShopscribe } from "../../firebase";
import { priceToCredit, Button } from "..";

const SubRenewModal = ({
  user,
  isOpen,
  setIsOpen,
  refreshSubscription,
  oldMembership,
}) => {
  // Ensure conversion to floats & calculate remaining credits
  const floatPrice = parseFloat(oldMembership.price);
  const floatCredit = parseFloat(priceToCredit(floatPrice));
  const floatRemainingCredit = parseFloat(oldMembership.remainingCredit);
  let rolloverCredit = 0;
  if (floatRemainingCredit <= floatCredit) {
    rolloverCredit = floatRemainingCredit;
  } else {
    rolloverCredit = floatCredit;
  }
  const floatNewRemainingCredit = floatCredit + rolloverCredit;
  const [isLoading, setLoading] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };

  const renewSub = async () => {
    setLoading(true);
    if (user) {
      //Get sub id
      let updatedStripeSub = {};
      if (!oldMembership.demo) {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/renew-subscription`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              subId: user.membershipId,
            }),
          }
        );
        updatedStripeSub = await response.json();
      }
      if (
        oldMembership.demo ||
        (updatedStripeSub && updatedStripeSub?.id === user.membershipId)
      ) {
        subscribeToShopscribe({
          user: user,
          price: floatPrice,
          totalCredit: floatCredit,
          id: oldMembership.uniqueId,
          remainingCredit: floatNewRemainingCredit,
          demo: oldMembership.demo,
          sendConfirmationEmail: false,
        }).then(() => {
          refreshSubscription();
          closeModal();
        });
      } else {
        throw new Error("Stripe: Error updating the subscription");
      }
    } else {
      throw new Error("Error updating the subscription - no user");
    }
    setLoading(false);
  };

  return (
    <div style={{ fontFamily: "DM Sans !important" }}>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        contentLabel="Pickup"
        style={{
          overlay: {
            position: "fixed",
            display: "inline-flex",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: "15px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999999999,
          },
          content: {
            margin: "auto",
            padding: "42px",
            boxShadow: "none",
            outline: "none",
          },
        }}
        className="company-info"
      >
        <div>
          <img
            src="/images/close.svg"
            loading="lazy"
            alt=""
            style={{
              position: "relative",
              width: "15px",
              height: "auto",
              opacity: ".33",
              float: "right",
              cursor: "pointer",
              margin: "-15px -15px 15px 15px",
            }}
            onClick={() => closeModal()}
          />
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h4>Want to renew your membership?</h4>
            <p>
              £{rolloverCredit.toFixed(2)} credits will roll over.
              <br /> Total new credit will be £
              {floatNewRemainingCredit.toFixed(2)}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {isLoading ? (
                <div style={{ marginTop: "25px" }}>
                  <Loader
                    type="TailSpin"
                    color="#ff7e67"
                    height={50}
                    width={50}
                  />
                </div>
              ) : (
                <Button
                  small
                  target="_blank"
                  rel="noreferrer"
                  className="button-small outline w-button"
                  onClick={() => renewSub()}
                >
                  Renew
                </Button>
              )}
            </div>
            <p style={{ fontSize: "14px", marginTop: "25px" }}>
              Your card will be charged now
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SubRenewModal;
