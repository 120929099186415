/* eslint-disable no-useless-escape */
import { NavBar, Footer } from "../../Components";
import ReactTooltip from "react-tooltip";

const Pricing = () => {
  return (
    <div>
      <NavBar />
      <div className="section-hero" style={{ paddingBottom: 0 }}>
        <div className="content">
          <div className="block-hero">
            <h1 data-w-id="Heading 2" className="heading-hero">
              Totally <span className="text-accent">free</span> to sign up
            </h1>
            <p>
              <b>No setup fees. No fixed costs. No hidden expenses.</b> We only
              make money if your shop makes money.
            </p>
          </div>
        </div>
      </div>
      <div
        className="account"
        style={{ minHeight: "auto", paddingTop: "25px", marginTop: "50px" }}
      >
        <div
          data-w-id="402f8ecc-de4b-c51e-bebe-3f36ccedf631"
          className="plan"
          style={{ width: "90%", maxWidth: "600px", padding: "25px 40px" }}
        >
          <div className="price-block">
            <div className="price-info">£</div>
            <div className="price">0</div>
            <div className="price-info">/mo</div>
          </div>
          <p className="paragraph-plan">
            We only charge 10% of the revenue we create for you.
            <span
              data-tip="Heads up, Deliveroo takes up to 35%"
              data-for="path"
              style={{
                display: "inline-block",
                opacity: 0.33,
                width: "17px",
                paddingBottom: "3px",
                marginLeft: "3px",
              }}
            >
              <img alt="" src="/images/info.svg" />
            </span>
          </p>
          <ReactTooltip effect="solid" id="path" />
          <div className="w-layout-grid grid-check-plan">
            <div className="check-block-plan">
              <div className="check-plan">
                <img
                  src="/images/check-orange.svg"
                  loading="lazy"
                  alt=""
                  className="icon-check"
                />
              </div>
              <div className="text-check-plan">
                We get subscribers to spend monthly at local businesses like
                yours
              </div>
            </div>
            <div className="check-block-plan">
              <div className="check-plan">
                <img
                  src="/images/check-orange.svg"
                  loading="lazy"
                  alt=""
                  className="icon-check"
                />
              </div>
              <div className="text-check-plan">
                We promote your shop to customers
              </div>
            </div>
            <div className="check-block-plan">
              <div className="check-plan">
                <img
                  src="/images/check-orange.svg"
                  loading="lazy"
                  alt=""
                  className="icon-check"
                />
              </div>
              <div className="text-check-plan">
                We take quality photos of your goods
              </div>
            </div>
            <div className="check-block-plan">
              <div className="check-plan">
                <img
                  src="/images/check-orange.svg"
                  loading="lazy"
                  alt=""
                  className="icon-check"
                />
              </div>
              <div className="text-check-plan">
                We provide free marketing materials
              </div>
            </div>
            <div className="check-block-plan">
              <div className="check-plan">
                <img
                  src="/images/check-orange.svg"
                  loading="lazy"
                  alt=""
                  className="icon-check"
                />
              </div>
              <div className="text-check-plan">
                We run social media and in-person events to promote your shop,
                all on us.
              </div>
            </div>
          </div>
          <a
            href="https://forms.gle/8K9znyYmHdJZf3rr6"
            target="_blank"
            rel="noreferrer"
            className="button-small outline w-button"
          >
            Get Started
          </a>
        </div>
      </div>
      <div className="section">
        <div className="content">
          <div
            className="block-hero"
            style={{ marginBottom: "70px", lineHeight: "140px !important" }}
          >
            <h4 style={{ lineHeight: "140%" }}>
              "The average London independent coffee shop serves almost 100,000
              customers a year. If just 75 become members, they could make an
              additional £22,500 annually."
            </h4>
          </div>
          <div
            data-w-id="d0dc812c-ec2f-fd6d-6ad9-77361725f73b"
            className="w-layout-grid grid-banner"
          >
            <div className="text-banner">
              <h2 className="heading white">
                Nothing to lose, customers to gain
              </h2>
              <p className="paragraph white">
                Ready to join our community and unlock recurring revenue and new
                customers? No credit card needed – ever.
              </p>
              <div>
                <h4 style={{ color: "white" }}>
                  Reach out to us at{" "}
                  <a
                    href="mailto:info@shopscribe.com"
                    style={{ color: "#ff7e67", textDecoration: "none" }}
                  >
                    info@shopscribe.com
                  </a>
                </h4>
              </div>
            </div>
            <div className="img-banner">
              <div className="block-app-screen---c">
                <img
                  src="https://images.squarespace-cdn.com/content/v1/59ba4af29f8dce3da268ffcb/1612366517384-L3WSD6X4WQYBBCCXM4EG/ke17ZwdGBToddI8pDm48kNbLqx_FIYjfhtEsCHEHzad7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UX_hdIVndeO72MD00jTnc1n42Pd5vs4VrV4yXG_EV_fzwRAeN1AbZG4OR41R6pDVyg/Personal+brand+photographer+in+London+with+Ben+Rothery"
                  loading="lazy"
                  alt=""
                  className="app-screen---c"
                />
              </div>
              <div className="block-circle---c-down">
                <img
                  src="/images/orange-circle.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 24vw, (max-width: 767px) 25vw, 24vw"
                  srcSet="
                  /images/orange-circle-p-500.png 500w,
                  /images/orange-circle.png       730w
                "
                  alt=""
                  className="image-circle---c1"
                />
                <img
                  src="/images/orange-circle.png"
                  loading="lazy"
                  sizes="9vw"
                  srcSet="
                  /images/orange-circle-p-500.png 500w,
                  /images/orange-circle.png       730w
                "
                  alt=""
                  className="image-circle---c2"
                />
              </div>
              <div className="block-circle---c-top">
                <img
                  src="/images/orange-circle.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 18vw, 19vw"
                  srcSet="
                  /images/orange-circle-p-500.png 500w,
                  /images/orange-circle.png       730w
                "
                  alt=""
                  className="image-circle---c3"
                />
                <img
                  src="/images/orange-circle.png"
                  loading="lazy"
                  sizes="10vw"
                  srcSet="
                  /images/orange-circle-p-500.png 500w,
                  /images/orange-circle.png       730w
                "
                  alt=""
                  className="image-circle---c4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Pricing;
