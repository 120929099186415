import Modal from "react-modal";
import { isMobile } from "react-device-detect";
import validator from "validator";
import { recordGiveawayEmail } from "../../airtable";

import { Footer } from "../../Components";
import { useEffect, useState, useRef } from "react";
import Lottie from "react-lottie-player";
import ReactTooltip from "react-tooltip";

import locationAnimation from "./location-lottie.json";
import giftAnimation from "./gift-lottie.json";
import heartAnimation from "./heart-lottie.json";

const PREVIEW_SHOPS = [
  "Get products from amazing...",
  "Delis",
  "Bakeries",
  "Organic Shops",
  "Flower Shops",
  "Wine Shops",
  "Patisseries",
  "Farmers Markets",
  "Restaurants",
  "Plant Shops",
  "Bars",
  "Ethnic Shops",
  "Coffee Roasters",
  "And more!",
];

const SOCIAL_IMAGES = [
  {
    src: "https://res.cloudinary.com/shopscribe/image/upload/v1645434357/1_dumjps.jpg",
  },
  {
    src: "https://res.cloudinary.com/shopscribe/image/upload/v1647249176/4_adbrxi.png",
  },
  {
    src: "https://res.cloudinary.com/shopscribe/image/upload/v1645434357/2_w8fpl4.jpg",
  },
  {
    src: "https://res.cloudinary.com/shopscribe/image/upload/v1647249175/5_dh9pmu.png",
  },
  {
    src: "https://res.cloudinary.com/shopscribe/image/upload/v1645434357/3_zijhop.jpg",
  },
  {
    src: "https://res.cloudinary.com/shopscribe/image/upload/v1647249181/1_tl1o3t.png",
  },
  {
    src: "https://res.cloudinary.com/shopscribe/image/upload/v1645434357/4_klrrm7.jpg",
  },
  {
    src: "https://res.cloudinary.com/shopscribe/image/upload/v1647249179/2_lmkm27.png",
  },
  {
    src: "https://res.cloudinary.com/shopscribe/image/upload/v1645434357/5_i5gjh9.jpg",
  },
  {
    src: "https://res.cloudinary.com/shopscribe/image/upload/v1647249178/3_f6krsh.png",
  },
  {
    src: "https://res.cloudinary.com/shopscribe/image/upload/v1645434357/6_ajl7ju.jpg",
  },
];

const Giveaway = ({ user }) => {
  const socialItemsRef = useRef();

  setTimeout(function () {
    var elements = document.getElementsByClassName("late-load");
    for (let el of elements) {
      console.log(el);
      el.style.opacity = 1;
    }
  }, 700);

  const [locationIconAnimation, animateLocationIcon] = useState(0);
  const [giftIconAnimation, animateGiftIcon] = useState(0);
  const [heartIconAnimation, animateHeartIcon] = useState(0);
  const [scrollForward, setScrollForward] = useState(true);
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
  }, []);

  useEffect(() => {
    // Social Images Timer
    const TOLERANCE = 1;
    const socialTimer = setInterval(() => {
      if (socialItemsRef.current.scrollLeft === 0) {
        setScrollForward(true);
      }
      if (
        socialItemsRef.current.scrollWidth <=
        socialItemsRef.current.scrollLeft +
          socialItemsRef.current.clientWidth +
          TOLERANCE
      ) {
        setScrollForward(false);
      }
      if (scrollForward) {
        socialItemsRef.current.scrollLeft +=
          socialItemsRef.current.scrollWidth / SOCIAL_IMAGES.length;
      } else {
        socialItemsRef.current.scrollLeft -=
          socialItemsRef.current.scrollWidth / SOCIAL_IMAGES.length;
      }
    }, 2500);
    return () => {
      window.clearInterval(socialTimer);
    };
  }, [scrollForward]);

  return (
    <div>
      <div>
        <div
          data-w-id="083b3595-12c0-3eb5-8a08-5d830155c0c8"
          className="nav"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 999999,
          }}
        >
          <div
            style={{
              width: isMobile ? "fit-content" : "100%",
              maxWidth: "1200px",
              margin: "50px auto",
            }}
          >
            <a
              href="/"
              aria-current="page"
              className="brand w-nav-brand w--current"
            >
              <img
                src="/images/logo-orange.svg"
                loading="lazy"
                sizes="100vw"
                alt=""
                className="logo"
              />
            </a>
          </div>
        </div>
        <Modal
          isOpen={submitted}
          onRequestClose={() => setSubmitted(false)}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
          contentLabel="Yay!"
          style={{
            overlay: {
              position: "fixed",
              display: "inline-flex",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              padding: "15px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 999999999,
            },
            content: {
              margin: "auto",
              padding: "42px",
              boxShadow: "none",
              outline: "none",
            },
          }}
          className="company-info"
        >
          <div>
            <h3 className="heading-form">Yay! Good luck 🍀</h3>
            <p>
              Thanks for participating, we'll reach out by email within a few
              days to let you know your outcome!
            </p>
          </div>
        </Modal>
        <div
          style={
            isMobile
              ? { minHeight: "100vh", height: "100%" }
              : { height: "98vh" }
          }
        >
          <div
            data-w-id="bfdef6b2-2e26-0bbb-6944-284fe24ab051"
            className="block-hero-img"
            style={{
              height: "100%",
              marginTop: 0,
              padding: "0 30px",
            }}
          >
            <div
              className="row"
              style={{
                width: "100%",
                height: "100%",
                maxWidth: "1200px",
              }}
            >
              <div
                className="column content"
                style={{
                  flex: "55%",
                  textAlign: isMobile ? "center" : "left",
                  padding: isMobile ? "150px 0 0" : "80px 0 0 0 ",
                }}
              >
                <div style={{ width: "100%" }}>
                  <h1
                    data-w-id="Heading 2"
                    className="heading-hero"
                    style={{
                      fontSize: isMobile ? "4.7vh" : "65px",
                      marginBottom: "25px",
                    }}
                  >
                    <span className="text-accent">Win 3 boxes</span> of unique
                    products from local shops!
                  </h1>
                  <p
                    className="shop-description"
                    style={{ marginBottom: "50px" }}
                  >
                    Giveaway ends March 27, 2022 at 11:59 PM. We’ll email the
                    winner! We might also tell you about some great local shops,
                    products and special discount codes :)
                  </p>
                  <h5 data-w-id="Paragraph 2">
                    Enter your valid email address to participate 🥳
                  </h5>
                  <div
                    className="button-container late-load"
                    style={{
                      width: "100%",
                      maxWidth: "500px",
                    }}
                  >
                    <div style={{ marginTop: "20px" }}>
                      <div className="form-block w-form">
                        <div data-name="Log in" id="mc_embed_signup">
                          <input
                            type="email"
                            className="text-field w-input"
                            maxLength="256"
                            name="EMAIL"
                            data-name="Email"
                            placeholder="Email address"
                            required=""
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                          />
                          <input
                            type="submit"
                            value="Enter the giveaway"
                            className="submit-button orange w-button"
                            style={{
                              opacity: validator.isEmail(email) ? 1 : 0,
                              cursor: validator.isEmail(email)
                                ? "pointer"
                                : "default",
                              transition: "0.2s",
                              width: "100%",
                              marginTop: "15px",
                            }}
                            onClick={async (event) => {
                              if (validator.isEmail(email)) {
                                recordGiveawayEmail(email).then(() => {
                                  setSubmitted(true);
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="column late-load"
                style={{
                  flex: "45%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    backgroundImage:
                      "url(https://res.cloudinary.com/shopscribe/image/upload/v1644340594/blob-bg_up2kju.svg)",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    transform: "scale(1.5)",
                    opacity: ".95",
                    margin: isMobile ? "40px auto 0" : "auto",
                    maxHeight: isMobile ? "250px" : "auto",
                  }}
                >
                  <img
                    src="https://res.cloudinary.com/shopscribe/image/upload/v1647368994/box-png_1_psq16b-min_copy_lb319u.png"
                    alt="Shopscribe subscription box"
                    className="banner"
                    data-tip="🤤"
                    data-for="product-image"
                    style={{
                      margin: "auto",
                      height: "100%",
                      objectFit: "contain",
                      transform: isMobile
                        ? "scale(0.7)"
                        : "scale(0.7) translate(20px,0)",
                      maxHeight: isMobile ? "250px" : "auto",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <ReactTooltip
            id="product-image"
            place="right"
            delayShow={150}
            className="sub-box-tooltip-big"
          />
        </div>
      </div>
      <div className="ticker-wrap">
        <div className="ticker">
          {PREVIEW_SHOPS.map((shop, i) => {
            return (
              <div
                key={i}
                className="ticker__item"
                style={{
                  padding: isMobile ? "0 40px" : "0 50px",
                }}
              >
                {shop}
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="section"
        style={{
          padding: "150px 0",
        }}
      >
        <div className="row" style={{ width: "100%", maxWidth: "1200px" }}>
          <div className="column">
            <div className="content" style={{ height: "100%" }}>
              <div data-w-id="Div Block" className="content">
                {!isMobile && (
                  <img
                    src="https://images.pexels.com/photos/4473496/pexels-photo-4473496.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                    loading="lazy"
                    style={{
                      width: "100% !important",
                      minHeight: "30px",
                      height: "400px",
                    }}
                    className="image-company"
                    alt="Support Local"
                  />
                )}
              </div>
            </div>
          </div>
          <div
            data-w-id="c0134b8c-83d5-8cb3-75cd-01896103e19b"
            className="column content"
            style={{
              height: isMobile ? "100%" : "400px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="block-hero" style={{ height: "100%" }}>
              <h2 className="heading" style={{ padding: "0 50px" }}>
                Be proud of supporting{" "}
                <span className="text-accent">local</span>
              </h2>
              <p
                style={{
                  padding: isMobile ? "0 50px" : "0 100px",
                  maxWidth: "100%",
                }}
                className="paragraph-feature"
              >
                76,000+ small businesses in London closed permanently because of
                lockdown.
              </p>
              {isMobile && (
                <img
                  src="https://images.pexels.com/photos/4473496/pexels-photo-4473496.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  loading="lazy"
                  style={{
                    width: "100% !important",
                    minHeight: "30px",
                    height: "100%",
                  }}
                  className="image-company"
                  alt="Support Local"
                />
              )}
              <p
                style={{
                  padding: isMobile ? "0 50px" : "0 100px",
                  maxWidth: "100%",
                }}
                className="paragraph-feature"
              >
                Shopscribe is supporting dozens of independent business owners
                and their families, sending tens of thousands of pounds their
                way.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="section"
        style={{
          backgroundColor: "#f7f0e8",
          padding: "50px 30px 30px",
        }}
      >
        <div data-w-id="Div Block" className="content">
          <div
            className="w-layout-grid grid-feature-box"
            style={{
              marginBottom: "30px",
            }}
          >
            <div
              data-w-id="7ff82385-63af-97e4-0df9-20a52258e593"
              className="feature"
            >
              <div
                className="icon-feature-bg"
                onMouseEnter={() =>
                  animateHeartIcon(heartIconAnimation === false ? 0 : false)
                }
              >
                <Lottie
                  animationData={heartAnimation}
                  loop={heartIconAnimation}
                  play
                  style={{
                    width: "35px",
                    height: "35px",
                    transform: "scale(1.6)",
                  }}
                />
              </div>
              <h5 className="heading-feature">Easily support local</h5>
              <p className="paragraph-feature">
                By using Shopscribe, you empower small business owners to
                continue doing what they love - not big chains.
              </p>
            </div>
            <div
              data-w-id="b24198d2-60fb-b52c-8736-dd0afc0f60da"
              className="feature"
            >
              <div
                className="icon-feature-bg"
                onMouseEnter={() =>
                  animateLocationIcon(
                    locationIconAnimation === false ? 0 : false
                  )
                }
              >
                <Lottie
                  animationData={locationAnimation}
                  loop={locationIconAnimation}
                  play
                  speed={1.5}
                  style={{
                    width: "35px",
                    height: "35px",
                    transform: "scale(1.7)",
                  }}
                />
              </div>
              <h5 className="heading-feature">Discover hidden gems</h5>
              <p className="paragraph-feature">
                We make it easy for you to discover the best small businesses
                around you, wherever they may be hidden.
              </p>
            </div>
            <div
              data-w-id="bc8a8e07-3be7-78fc-0781-49bbd191ec59"
              className="feature"
            >
              <div
                className="icon-feature-bg"
                onMouseEnter={() =>
                  animateGiftIcon(giftIconAnimation === false ? 0 : false)
                }
              >
                <Lottie
                  animationData={giftAnimation}
                  loop={giftIconAnimation}
                  play
                  style={{
                    width: "35px",
                    height: "35px",
                    transform: "scale(1.6)",
                  }}
                />
              </div>
              <h5 className="heading-feature">Treat yourself</h5>
              <p className="paragraph-feature">
                Each surprise box accommodates your dietary restrictions and
                allergies. You’ll always get something new each month.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="section"
        style={{
          paddingTop: "70px",
          paddingBottom: "0px",
          backgroundColor: "#fdf8f4",
        }}
      >
        <div className="content">
          <div
            className="block-center"
            style={{ width: "100%", maxWidth: "1200px" }}
          >
            <h2
              data-w-id="c0134b8c-83d5-8cb3-75cd-01896103e19b"
              className="heading"
            >
              The perfect <span className="text-accent">subscription box</span>{" "}
              doesn't exi-
            </h2>
            <div
              style={{
                width: "100%",
                maxWidth: "1200px",
                display: "flex",
                flexDirection: "row",
                overflowX: "scroll",
                whiteSpace: "nowrap",
                scrollBehavior: "smooth",
                height: isMobile ? "600px" : "500px",
              }}
              className="custom-scrollbar"
              ref={socialItemsRef}
            >
              {SOCIAL_IMAGES.map((item, i) => {
                return (
                  <div
                    style={{
                      flex: isMobile ? "0 0 100%" : "0 0 25%",
                      padding: "30px",
                    }}
                    key={i}
                  >
                    <img
                      src={item.src}
                      alt="Social Proof"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "24px",
                        boxShadow: "0 25px 50px -10px rgba(100, 44, 0, 0.07)",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Giveaway;
