import { ShopsList, NavBar } from "../../Components";
import { useEffect } from "react";

const Shops = ({ user }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <NavBar forShops={false} showSignUp={true} user={user} />
      <div style={{ marginTop: "75px" }}>
        <ShopsList />
      </div>
    </div>
  );
};

export default Shops;
