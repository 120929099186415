import React, { useState } from "react";
import Modal from "react-modal";
import CurrencyInput from "react-currency-input-field";
import Loader from "react-loader-spinner";
import { subscribeToShopscribe } from "../../firebase";
import { priceToCredit, Button } from "..";

const SubChangeModal = ({
  user,
  isOpen,
  setIsOpen,
  refreshSubscription,
  oldMembership,
}) => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const updateSub = async () => {
    setLoading(true);
    if (user) {
      //Get sub id
      let updatedStripeSub = {};
      if (!oldMembership.demo) {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/update-subscription`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              subId: user.membershipId,
              price: amount,
            }),
          }
        );
        updatedStripeSub = await response.json();
      }
      if (
        oldMembership.demo ||
        (updatedStripeSub &&
          updatedStripeSub?.id === user.membershipId &&
          updatedStripeSub?.plan?.amount === amount * 100)
      ) {
        // Ensure conversion to floats
        const floatPrice = parseFloat(amount);
        const floatCredit = parseFloat(priceToCredit(floatPrice));
        const floatOldPrice = parseFloat(oldMembership.price);
        let floatRemainingCredit = parseFloat(oldMembership.remainingCredit);
        // If higher price, rollover previous credits. If lower price keep same credits, change price which will update credits next cycle
        if (floatPrice > floatOldPrice) floatRemainingCredit += floatCredit;
        subscribeToShopscribe({
          user: user,
          price: floatPrice,
          totalCredit: floatCredit,
          id: oldMembership.uniqueId,
          remainingCredit: floatRemainingCredit,
          demo: oldMembership.demo,
          sendConfirmationEmail: false,
        }).then(() => {
          refreshSubscription();
          closeModal();
        });
      } else {
        throw new Error("Stripe: Error updating the subscription");
      }
    } else {
      throw new Error("Error updating the subscription - no user");
    }
    setLoading(false);
  };

  return (
    <div style={{ fontFamily: "DM Sans !important" }}>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        contentLabel="Pickup"
        style={{
          overlay: {
            position: "fixed",
            display: "inline-flex",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: "15px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999999999,
          },
          content: {
            margin: "auto",
            padding: "42px",
            boxShadow: "none",
            outline: "none",
          },
        }}
        className="company-info"
      >
        <div>
          <img
            src="/images/close.svg"
            loading="lazy"
            alt=""
            style={{
              position: "relative",
              width: "15px",
              height: "auto",
              opacity: ".33",
              float: "right",
              cursor: "pointer",
              margin: "-15px -15px 15px 15px",
            }}
            onClick={() => closeModal()}
          />
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <p>
              <b>New</b> monthly subscription:
              <br />
              <CurrencyInput
                id="custom-price"
                name="custom-price"
                defaultValue={""}
                decimalsLimit={2}
                allowNegativeValue={false}
                placeholder="£"
                prefix="£"
                value={amount}
                maxLength={6}
                onValueChange={(value, name) => {
                  setAmount(value ?? "");
                }}
                autoComplete="off"
                style={{
                  maxWidth: "80%",
                  width: "100px",
                  border: "none",
                  borderBottom: "2px solid",
                  font: "bold 18px DM Sans",
                  backgroundColor: "transparent",
                  outline: "none",
                  marginTop: "25px",
                }}
              />
            </p>
            <p style={{ fontSize: "14px" }}>
              Unused credits will rollover.
              <br /> Upgrades start immediately (charged).
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                small
                target="_blank"
                rel="noreferrer"
                className="button-small outline w-button"
                onClick={() => updateSub()}
              >
                Change
              </Button>
            </div>
            {loading && (
              <div style={{ marginTop: "25px" }}>
                <Loader
                  type="TailSpin"
                  color="#ff7e67"
                  height={50}
                  width={50}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SubChangeModal;
