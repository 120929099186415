import React, { useEffect, useState } from "react";
import { AddressFinder } from "@ideal-postcodes/address-finder";

const AddressAutoComplete = (props) => {
  const [enterManually, setEnterManually] = useState(false);

  useEffect(() => {
    AddressFinder.setup({
      inputField: document.getElementById("address0"),
      apiKey: "ak_kzpoye1zttby6BY73Xu6f62uADXKB",
      injectStyle: true,
      onAddressRetrieved: ({ line_1, line_2, line_3, post_town, postcode }) =>
        handleAddressChange(line_1, line_2, line_3, post_town, postcode),
    });
    //eslint-disable-next-line
  }, []);

  const handleAddressChange = (line_1, line_2, line_3, post_town, postcode) => {
    let formattedLine1 = line_2 ? line_1 + ", " + line_2 : line_1;
    props.onAddress0Change(formattedLine1);
    props.onAddress1Change(line_3 ?? "");
    props.onAddress2Change(post_town);
    props.onAddress3Change(postcode);
    setEnterManually(true);
  };

  return (
    <>
      <input
        id="address0"
        type="text"
        className="text-field w-input"
        maxLength="256"
        name="address0"
        value={props.address0}
        placeholder={enterManually ? "Address Line 1 *" : "Enter your postcode"}
        onChange={(event) => props.onChange(event)}
        style={{
          borderWidth: "1px",
          borderColor: "lightgray",
        }}
      />
      {!enterManually && (
        <small>
          <div
            target="_blank"
            rel="noreferrer"
            className="text-link"
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              margin: "-10px 0 -5px",
              textAlign: "right",
              opacity: 0.5,
            }}
            onClick={() => setEnterManually(true)}
          >
            Or your full address
          </div>
        </small>
      )}
      {enterManually && (
        <>
          <input
            id="address1"
            type="text"
            className="text-field w-input"
            maxLength="256"
            name="address1"
            value={props.address1}
            placeholder="Address Line 2"
            onChange={(event) => props.onChange(event)}
          />
          <div style={{ display: "flex" }}>
            <div style={{ flex: "50%", marginRight: "10px" }}>
              <input
                type="text"
                className="text-field w-input"
                maxLength="256"
                value={props.address2}
                placeholder="Town"
                onChange={(event) => props.onChange(event)}
              />
            </div>
            <div style={{ flex: "50%" }}>
              <input
                id="address3"
                type="text"
                className="text-field w-input"
                maxLength="256"
                name="address3"
                value={props.address3}
                placeholder="Postcode *"
                onChange={(event) => props.onChange(event)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddressAutoComplete;
