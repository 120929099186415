import { isMobile } from "react-device-detect";

const Banner = ({ children, url = "/" }) => {
  return (
    <div
      className={
        isMobile
          ? "shop-general-discount late-load"
          : "shop-general-discount banner-float late-load"
      }
      style={{
        backgroundColor: "rgba(255, 126, 103, 0.12)",
        padding: isMobile ? "7px 0px" : "7px 60px",
        boxShadow: "0px 5px 15px rgba(255, 126, 103, 0)",
        width: isMobile ? "90%" : "auto",
        marginLeft: "auto",
        marginRight: "auto"
      }}
    >
      <div style={{ 
        width: isMobile ? "90%" : "auto",
        marginLeft: "auto",
        marginRight: "auto", 
        textAlign: "center"
      }}>
        <a
          className="banner-text"
          href={url}
          data-mode="popup"
          data-size="100"
          style={{ color: "#ff7e67" }}
        >
          {isMobile && <small>{children}</small>}
          {!isMobile && <span>{children}</span>}
        </a>
      </div>
    </div>
  );
};

export default Banner;
