import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { Link, useHistory } from "react-router-dom";
import { signInWithGoogle, auth } from "../../firebase.js";
import { PasswordRecoveryModal } from "../../Components";

const errorCodes = {
  "auth/user-not-found":
    "Hmm details not valid... Used just an email to get your subscription? SIGN UP with the same email to get into your account",
  "auth/wrong-password": "Oops, wrong password!",
  "auth/invalid-email": "That doesn't look like an email address :)",
  "auth/email-already-in-use": "You already have an account! Please sign in :)",
};

const Login = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(true);
  const [passwordRecoveryShown, setPasswordRecoveryShown] = useState(false);

  const successCallback = new URLSearchParams(window.location.search).get(
    "callback"
  );

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        if (successCallback) history.push(successCallback);
      })
      .catch((error) => {
        setError(errorCodes[error.code] ?? "Error on login, try again!");
        setSubmitted(false);
        console.error("Error signing in with password and email", error);
      });
  };

  useEffect(() => {
    setInterval(() => {
      setSubmitted(false);
    }, 4000);
    return () => {};
  }, []);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <div className="account">
      <a
        href="/"
        data-w-id="f6d39ff8-752c-4106-c067-25966622553d"
        className="brand-account w-nav-brand"
      >
        <img
          src="images/logo-orange.svg"
          loading="lazy"
          alt=""
          className="logo"
        />
      </a>
      {!submitted && (
        <div className="fixWidth">
          <div
            data-w-id="1e4857dc-6e9f-b392-2236-96c15a7560e1"
            className="block-account"
          >
            <div className="account-form">
              <h3 className="heading-form">Log in to your account</h3>
              {error !== null && (
                <div className="error-message w-form-fail">{error}</div>
              )}
              <div className="form-block w-form">
                <div data-name="Log in" className="form">
                  <input
                    type="email"
                    className="text-field w-input"
                    maxLength="256"
                    name="userEmail"
                    placeholder="Email address"
                    id="Email"
                    required=""
                    value={email}
                    onChange={(event) => onChangeHandler(event)}
                  />
                  <input
                    type="password"
                    className="text-field w-input"
                    maxLength="256"
                    name="userPassword"
                    placeholder="Password"
                    id="Password"
                    required=""
                    value={password}
                    onChange={(event) => onChangeHandler(event)}
                  />
                  <input
                    type="submit"
                    value="Sign in"
                    className="submit-button orange w-button"
                    onClick={(event) => {
                      signInWithEmailAndPasswordHandler(event, email, password);
                      setSubmitted(true);
                    }}
                  />
                </div>
              </div>
              <div className="text-form">or</div>
              <div
                className="button-login w-inline-block"
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  setSubmitted(true);
                  let result = await signInWithGoogle();
                  if (successCallback && result) history.push(successCallback);
                  if (!result) setSubmitted(false);
                }}
              >
                <img
                  src="images/icon-google.svg"
                  loading="lazy"
                  data-w-id="Image"
                  alt=""
                  className="icon-login"
                />
                <div className="text-login">
                  Sign in with Google
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="text-account">
            <p>
              Used <b>just an email</b> to get your subscription? <br />
              <a href="/signup">Sign up</a> with the same email to get into your
              account!
            </p>
          </div>
          <div
            data-w-id="c79bff7a-25bf-c657-cba2-061e2a3f4e5e"
            className="text-account"
          >
            <Link
              to={`/signup${
                successCallback ? "?callback=" + successCallback : ""
              }`}
            >
              Create an account
            </Link>{" "}
              ·  {" "}
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setPasswordRecoveryShown(true);
              }}
              style={{
                cursor: "pointer",
              }}
            >
              Forgot password?
            </a>
          </div>
        </div>
      )}
      <PasswordRecoveryModal
        isOpen={passwordRecoveryShown}
        setIsOpen={setPasswordRecoveryShown}
        email={email}
      />
      {submitted && (
        <div className="block-account">
          <Loader type="TailSpin" color="#ff7e67" height={80} width={80} />
          <h6 style={{ color: "#ff7e67" }}>
            Wait while the minions do their work...
          </h6>
        </div>
      )}
    </div>
  );
};

export default Login;
