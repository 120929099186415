/*************************
 * SHOP:
{
  name: "",
  type: "",
  id: "",
  owner: "",
  address: "" or [""],
  coordinates: [0,0],
  neighbourhood: "",
  description: "",
  profileImage: "",
  ownerImage: "",
  shopImage: "",
  perks: [""],
  generalDiscount: 20,
  subscriptions: [
    {
      image: "",
      title:"",
      id:"",
      price:5.0,
      frequency:30,
      description:"",
      totalRedeemables: 1,
      placeholder: true
    }
  ]
}
*/

export const Shops = [
  {
    name: "Urban Social Coffee",
    type: "Contemporary Coffee",
    id: "urbansocial",
    owner: "Volkan",
    address: "236 Upper St, London N1 1RU",
    coordinates: [51.544747, -0.103581],
    neighbourhood: "Islington",
    description:
      "Londoners on a coffee crawl head to Urban Social to satiate their senses. The lure of specialty coffee crafted by the shop’s skilled and creative baristas has been honed and earned over several years of experience and experimentation. With an electric air and rich ambience for socialisation, our coffee shop and hidden courtyard bring together a diverse mix of regulars, tourists, and out-of-towners alike. Menu: https://urbansocialcoffee.co.uk/#menu",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638785477/Shops/urbansocial/urban3_tylkoh.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638785485/Shops/urbansocial/urban1_knuq15.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638785493/Shops/urbansocial/urbanvenue_if90im.jpg",
    perks: ["Exclusive invites to taste new brunch items"],
    generalDiscount: 20,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638785990/Shops/urbansocial/urban2_mclzaw.jpg",
        title: "Regular Coffee Membership",
        id: "urbansocial-small",
        price: 23.0,
        frequency: 30,
        description:
          "Enjoy 10 coffees each month for the price of 8! Includes dairy alternatives. Doesn't include cold beverages.",
        totalRedeemables: 10,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786010/Shops/urbansocial/urbancoffee_xmwyds.jpg",
        title: "Large Coffee Membership",
        id: "urbansocial-large",
        price: 42.0,
        frequency: 30,
        description:
          "Enjoy 20 coffees each month for the price of 15! Includes dairy alternatives. Doesn't include cold beverages.",
        totalRedeemables: 20,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786016/Shops/urbansocial/urbanjuive_oqi9yx.jpg",
        title: "Urban Juice Club",
        id: "urbansocial-juice",
        price: 20.0,
        frequency: 30,
        description:
          "Members get 5 juices for the price of 4. Only the freshest fruits and veggies go into our cold press juicer, sans any sweetener or artificial ingredient.",
        totalRedeemables: 5,
      },
    ],
  },
  {
    name: "Nature's Delight",
    type: "Natural Food Shop",
    id: "naturesdelight",
    owner: "Adam and Marton",
    address: "779 Fulham Rd, London SW6 5HA",
    coordinates: [51.4720306, -0.2103925],
    neighbourhood: "Fulham",
    generalDiscount: 15,
    description:
      "We feed you natural seasonal produce and dried goods from some of London's best Artisans.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638785801/Shops/naturesdelight/Screenshot-2021-07-16-at-13-11-33_ib6juy.png",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638785847/Shops/naturesdelight/AF1QipOv6-dv4j3WQBGjpqiaRRq0qI28olxWsHxNN_fu_art2d8.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638785841/Shops/naturesdelight/AF1QipPn4EG3bsUXRZbOMwzk3to5hvJEZgFDbx8CmWWa_hgc8vl.jpg",
    perks: ["Free daily tea or coffee!"],
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786218/Shops/naturesdelight/pexels-photo-4592667_mowbfj.jpg",
        title: "Weekly breakfast box",
        id: "naturesdelight-breakfast",
        price: 20.0,
        frequency: 7,
        description:
          "Get a superb breakfast box, including selected natural products such as milk, granola, fruit and honey - worth £25.",
        totalRedeemables: 1,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786233/Shops/naturesdelight/Article-How-to-Italian-Aperitivo-Spread-Spritz-Cocktail-Recipe_k7howf.jpg",
        title: "Get 2 aperitif boxes a month",
        id: "naturesdelight-aperitif",
        price: 40.0,
        frequency: 30,
        description:
          "Become a member and enjoy two monthly fine aperitifs. Includes selected products such as Aperol, charcuterie, cheese, dried tomato and olives. Worth 25£ each box.",
        totalRedeemables: 2,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786244/Shops/naturesdelight/AF1QipPLUInUjuURkfM253Dl7fxm8n78LYLqEo5XZzRg_zyjxcg.jpg",
        title: "Weekly fruit and veggies",
        id: "naturesdelight-fruitveg",
        price: 15.0,
        frequency: 7,
        description:
          "Become a member and get £20 weekly to spend on delicious fruit and vegetables. You'll save £20 every month!",
        totalRedeemables: 1,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786256/Shops/naturesdelight/Screenshot-2021-07-16-at-13-10-09_xuzurd.png",
        title: "Daily juice shot",
        id: "naturesdelight-shot",
        price: 10.0,
        frequency: 7,
        description:
          "Fresh, intense shots using natural ingredients to help renew you for the day",
        totalRedeemables: 7,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786269/Shops/naturesdelight/box_kicdar.jpg",
        title: "Weekly second chance box",
        id: "naturesdelight-zerowaste",
        price: 3.0,
        frequency: 7,
        description:
          "Help reducing food waste by getting a selection of fruits and veg that hasn't been cleared/sold during the week (but still awesome!). Items are subject to availability.",
        totalRedeemables: 1,
      },
    ],
  },
  {
    name: "Faros London",
    type: "Mediterranean Restaurant",
    owner: "Burak",
    id: "faros",
    address: "57 Grays Inn Rd, London WC1X 8PP",
    neighbourhood: "Bloomsbury",
    coordinates: [51.5221883, -0.1140395],
    description:
      "Located in the heart of London, Faros is a modern restaurant with a fresh take on a mixture of Mediterranean and Italian cuisines. Our ethos ‘Flavours of the Sun’ sounds as warming as it tastes, and will leave you reminiscing about beautiful Mediterranean islands. With our passion for food, filled with colour and culture, we will bring freshness and quality to your plate. Check out our menu: http://farosrestaurant.co.uk/menu/",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638786420/Shops/faros/1.8To5H_landscape_bicmpr.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638786435/Shops/faros/photo-1598866594230-a7c12756260f_ohvgef.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638786451/Shops/faros/faros_g6mned.jpg",
    perks: [],
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786468/Shops/faros/photo-1569494315581-abddb8d41cfe_ofoik8.jpg",
        title: "Get 3 meals a month",
        id: "faros-3meals",
        price: 30.0,
        frequency: 30,
        description:
          "Get any pizza or pasta of your choice 3 times a month, excluding main courses. Enjoy our specialities on a regular basis!",
        totalRedeemables: 3,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786486/Shops/faros/photo-1591243315780-978fd00ff9db_x9nwit.jpg",
        title: "Cocktail club",
        id: "faros-cocktail",
        price: 25.0,
        frequency: 30,
        description:
          "Usually £9 each, enjoy a cocktail whenever you want, 4 times a month. Shareable with friends!",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786504/Shops/faros/photo-1495474472287-4d71bcdd2085_hbyov7.jpg",
        title: "Monthly coffee membership",
        id: "faros-coffee",
        price: 20.0,
        frequency: 30,
        description:
          "Enjoy 20 artisan coffees each month! Includes dairy alternatives.",
        totalRedeemables: 30,
      },
    ],
  },
  {
    name: "Pizza at Home",
    type: "Pizzeria",
    owner: null,
    id: "pah",
    address: "86 Lillie Road, London, SW6 1TL",
    coordinates: [51.485698, -0.202314],
    neighbourhood: "West Kensington",
    description:
      "A local favorite in West Kensington, all of our pizzas are hand-stretched to perfection and topped with our house-made pizza sauce, fresh basil, and high-quality mozzarella cheese.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638786595/Shops/pah/32228697_q5zm3l.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638786616/Shops/pah/Screenshot-2021-07-06-at-13-13-34_akeqvw.png",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638786639/Shops/pah/97409_zx8rn7.jpg",
    perks: ["Free bottle of prosecco for your birthday with group of 6 people"],
    generalDiscount: 35,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786662/Shops/pah/pah1_tvlvet.webp",
        title: "Pizza at Home Club",
        id: "pah-club",
        price: 30.0,
        frequency: 30,
        description:
          "The first rule of pizza club is: You get any of our 12” pizzas for free, 4 times a month.",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786686/Shops/pah/pah2_rtdu3l.jpg",
        title: "Lean, Green Membership",
        id: "pah-green",
        price: 26.0,
        frequency: 30,
        description:
          "Become a member and get any salad of your choice, 4 times a month.",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786711/Shops/pah/pah3_ts8vyl.jpg",
        title: "Wine and Dine Society",
        id: "pah-wine",
        price: 30.0,
        frequency: 30,
        description:
          "Dinner with the housemates or date night sorted. Get any 14” pizza and a bottle of house wine on a monthly basis.",
        totalRedeemables: 1,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786737/Shops/pah/pah4_spb4bp.png",
        title: "Sharing is Caring Membership",
        id: "pah-sharing",
        price: 24.0,
        frequency: 30,
        description:
          "Get any of our 18” pizzas, twice a month. Fun fact: one 18” pizza is larger than two 12” pizzas.",
        totalRedeemables: 2,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786662/Shops/pah/pah1_tvlvet.webp",
        title: "Annual Pizza at Home Club",
        id: "pah-club-annual",
        price: 60.0,
        frequency: 365,
        description:
          "The first rule of pizza club is: You get any of our 12” pizzas for free, 8 times a year.",
        totalRedeemables: 8,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786686/Shops/pah/pah2_rtdu3l.jpg",
        title: "Annual Lean, Green Membership",
        id: "pah-green-annual",
        price: 52.0,
        frequency: 365,
        description:
          "Become a member and get any salad of your choice, 8 times a year.",
        totalRedeemables: 8,
      },
    ],
  },
  {
    name: "Jan’s Thai Food",
    type: "Street Thai",
    owner: "Jan",
    id: "jan",
    address: "The Blue Market Place, Bermondsey, London, SE163UQ",
    coordinates: [51.49257295, -0.0637003433515656],
    neighbourhood: "Bermondsey",
    description:
      "You can enjoy the true flavours of Thailand in London thanks to Jan’s Thai food! Using fresh, traditional Thai ingredients, all meals and sauces are prepared by Jan herself, Monday to Saturday at the Blue Market.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638786843/Shops/jan/Thai-Red-Curry-square-FS-51_l73lpz.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638786873/Shops/jan/Jans-Thai-Food-Slider-01_ny2cgz.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638786902/Shops/jan/image_sxvsor.jpg",
    perks: ["Free order of spring rolls every 1st meal of the month"],
    generalDiscount: 15,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786933/Shops/jan/Thai-Red-Curry_1_yxybmm.jpg",
        title: "2 meals + 2 spring rolls",
        id: "jan-2meals",
        price: 12.0,
        frequency: 30,
        description:
          "Become a member and get a free meal of your choice 2 times a month (excluding prawns) and 2 spring rolls with the first order every month, made with love by Jan and her daughter.",
        totalRedeemables: 2,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786966/Shops/jan/thai_tjgmgw.jpg",
        title: "4 meals + 3 spring rolls",
        id: "jan-meal",
        price: 21.0,
        frequency: 30,
        description:
          "Become a member and get a free meal of your choice 4 times a month (excluding prawns) and 3 spring rolls with the first order every month, made with love by Jan and her daughter.",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638786999/Shops/jan/Thai-Red-Curry-with-Cauliflower-and-Potatoes_llnlen.jpg",
        title: "8 meals + 6 spring rolls",
        id: "jan-8meals",
        price: 42.0,
        frequency: 30,
        description:
          "Become a member and get a free meal of your choice 8 times a month (excluding prawns) and 6 spring rolls with the first order every month, made with love by Jan and her daughter.",
        totalRedeemables: 8,
      },
    ],
  },
  {
    name: "VeganE",
    type: "Vegan Restaurant",
    owner: "Amir",
    id: "vegane",
    address: "102 Lillie Rd, London SW6 7SR",
    coordinates: [51.4852998, -0.2030601],
    neighbourhood: "Fulham",
    description:
      "100% plant based and 100% homemade. We are a pillar of the vegan community, and are proud to serve top-quality dishes made from the freshest ingredients. Generous size portions and delicious food – we make sure that vegan is not boring!",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638787093/Shops/vegane/photo-1554980291-c3e7cea75872_aafpwa.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638787283/Shops/vegane/vegane_ennuhx.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638787243/Shops/vegane/AF1QipP6d6vJJ74F3U6nVJrLEK3J3LjiF9FSMXvD17fn_tntl9w.jpg",
    perks: [
      "Members have priority booking",
      "Free bottle of prosecco, wine, or any cocktail for your birthday",
      "Coming soon: Members get surprise treats when ordering on food delivery platforms",
    ],
    generalDiscount: 40,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638787129/Shops/vegane/photo-1574894709920-11b28e7367e3_jdvbsz.jpg",
        title: "4 main courses monthly",
        id: "vegane-4mains",
        price: 30.0,
        frequency: 30,
        description:
          "Get any main of your choice, 4 times a month. Enjoy our homemade vegan dishes on a regular basis!",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638787093/Shops/vegane/photo-1554980291-c3e7cea75872_aafpwa.jpg",
        title: "4 three-course meals a month",
        id: "vegane-3courses",
        price: 80.0,
        frequency: 30,
        description:
          "Become a member and get any starter, main course, dessert, and soft drink – 4 times a month. You’ll save over £30 each month.",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638787166/Shops/vegane/photo-1534353473418-4cfa6c56fd38_p9zzel.jpg",
        title: "8 fresh juices monthly",
        id: "vegane-juice",
        price: 35.0,
        frequency: 30,
        description:
          "Usually £6-9 each, pick up a fresh juice whenever you want, 8 times a month.",
        totalRedeemables: 8,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638787129/Shops/vegane/photo-1574894709920-11b28e7367e3_jdvbsz.jpg",
        title: "8 main courses yearly",
        id: "vegane-4mains-annual",
        price: 60.0,
        frequency: 365,
        description:
          "Get any main of your choice, 8 times a year. Enjoy our homemade vegan dishes on a regular basis!",
        totalRedeemables: 8,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638787093/Shops/vegane/photo-1554980291-c3e7cea75872_aafpwa.jpg",
        title: "8 three-course meals a year",
        id: "vegane-3courses-annual",
        price: 160.0,
        frequency: 365,
        description:
          "Become a member and get any starter, main course, dessert, and soft drink – 8 times a year. You’ll save over £60.",
        totalRedeemables: 8,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638787058/Shops/vegane/photo-1534353473418-4cfa6c56fd38_ikgcoc.jpg",
        title: "16 fresh juices yearly",
        id: "vegane-juice-annual",
        price: 70.0,
        frequency: 365,
        description:
          "Usually £6-9 each, pick up a fresh juice whenever you want, 16 times a year.",
        totalRedeemables: 16,
      },
    ],
  },
  {
    name: "Dragon Cat Café",
    type: "Bubble Tea Shop",
    id: "dragoncat",
    owner: "Johannes and Yen",
    address: "18A Broadway Shopping Centre, London W6 9YD",
    coordinates: [51.492304, -0.224556],
    neighbourhood: "Hammersmith",
    description:
      "At Dragon Cat Cafe, we freshly brew every drink, ensuring the best tasting tea every time. We freshly press our fruits, avoiding jams and fruit flavoured syrups. We have created many original seasonal drinks, snagged a few awards along the way, and remain committed to giving you a taste of Taiwan.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638804783/Shops/dragoncat/image_bo9ebi.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638804825/Shops/dragoncat/dragoncat_ntxf9n.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638804868/Shops/dragoncat/image_bs06nr.jpg",
    perks: [],
    generalDiscount: 20,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638804913/Shops/dragoncat/GiuliaVerdinelli_DragonCatCafe_OreoMilkTea3_ysb9bd.jpg",
        title: "Medium Dragon Cat membership",
        id: "dragoncat-medium",
        price: 19.0,
        frequency: 30,
        description:
          "Enjoy any medium fresh tea or traditional milk tea whenever, 6 times a month – 1 topping included each time. Extra toppings can be purchased at the till.",
        totalRedeemables: 6,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638804825/Shops/dragoncat/dragoncat_ntxf9n.jpg",
        title: "Large Dragon Cat membership",
        id: "dragoncat-large",
        price: 21.0,
        frequency: 30,
        description:
          "Enjoy any large fresh tea or traditional milk tea whenever, 6 times a month – 1 topping included each time. Extra toppings can be purchased at the till.",
        totalRedeemables: 6,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638804913/Shops/dragoncat/GiuliaVerdinelli_DragonCatCafe_OreoMilkTea3_ysb9bd.jpg",
        title: "Medium Dragon Cat - Annual",
        id: "dragoncat-m-annual",
        price: 38.0,
        frequency: 365,
        description:
          "Enjoy any medium fresh tea or traditional milk tea whenever, 12 times a year – 1 topping included each time. Extra toppings can be purchased at the till.",
        totalRedeemables: 12,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638804825/Shops/dragoncat/dragoncat_ntxf9n.jpg",
        title: "Large Dragon Cat - Annual",
        id: "dragoncat-l-annual",
        price: 42.0,
        frequency: 365,
        description:
          "Enjoy any large fresh tea or traditional milk tea whenever, 12 times a year – 1 topping included each time. Extra toppings can be purchased at the till.",
        totalRedeemables: 12,
      },
    ],
  },
  {
    name: "Everyday Sunshine, by RUDE",
    type: "Art & Coffee",
    id: "everydaysun",
    owner: "Rupert and Abi",
    address: "49 Barbauld Rd, London N16 0RT",
    coordinates: [51.5577465, -0.0804476],
    neighbourhood: "Stoke Newington",
    description:
      "Bright, uplifting, original artwork by RUDE. Stationery, merchandise and greeting cards, served up with coffee realness and exceptional cakes and pastries. Come and say hello to us at Everyday Sunshine! Check out our art prints at: shop.thisisrude.com",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638805030/Shops/everydaysun/esprofile_sycroo.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638805077/Shops/everydaysun/esshop_vbxnbz.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638805125/Shops/everydaysun/es-SHOPTrue_klpnje.jpg",
    generalDiscount: 20,
    perks: ["Perks for events coming soon!"],
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805173/Shops/everydaysun/WarOnConvenience_Thumbnail-1_uz7ses.jpg",
        title: "Small coffee membership",
        id: "everydaysun-small",
        price: 12.0,
        frequency: 30,
        description: "Enjoy 5 coffees each month, made with Allpress espresso.",
        totalRedeemables: 5,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805223/Shops/everydaysun/allpress-range1_loyvw7.jpg",
        title: "Large coffee membership",
        id: "everydaysun-large",
        price: 22.0,
        frequency: 30,
        description:
          "Enjoy 10 coffees each month, made with Allpress espresso. You’ll save almost 20% monthly.",
        totalRedeemables: 10,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805275/Shops/everydaysun/Allpress-Reusable-Cup_3-925x425_vbir7c.png",
        title: "Small alternative coffee membership",
        id: "everydaysun-sm-alt",
        price: 13.0,
        frequency: 30,
        description:
          "Enjoy 5 coffees each month, made with Allpress espresso and including dairy alternatives.",
        totalRedeemables: 5,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805329/Shops/everydaysun/In-copy-Landscape-800x600-86_fa9vdx.jpg",
        title: "Large alternative coffee membership",
        id: "everydaysun-lg-alt",
        price: 24.0,
        frequency: 30,
        description:
          "Enjoy 10 coffees each month, made with Allpress espresso and including dairy alternatives. You’ll save 20% monthly.",
        totalRedeemables: 10,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805405/Shops/everydaysun/esgreet_bek7xq.jpg",
        title: "RUDE Greeting Card Club",
        id: "everydaysun-greeting",
        price: 10.0,
        frequency: 30,
        description:
          "Everyone loves receiving thoughtful cards – make sure you always have some around! Pick 4 of our colourful graphic greeting cards monthly.",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805476/Shops/everydaysun/esprint_cq09yv.jpg",
        title: "Annual RUDE Print Club",
        id: "everydaysun-print",
        price: 100.0,
        frequency: 365,
        description:
          "Usually £35 each, pick 4 A3 prints whenever, or all at once. Great as a gift or as an annual treat to yourself.",
        totalRedeemables: 4,
      },
    ],
  },
  {
    name: "Ginette French Cafe",
    type: "French Cafe",
    id: "ginette",
    owner: "Claire",
    address: "21 Shacklewell Ln, London E8 2DA",
    coordinates: [51.5541201, -0.0686349],
    neighbourhood: "Dalston",
    description:
      "A place for all French Food Lovers, Ginette is a cosy Cafe in the heart of Dalston. For pastry lovers, coffee drinkers, and French foodies alike.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638805451/Shops/ginette/ginette-coffee2_ad9x8p.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638805456/Shops/ginette/ginette-shop_ysqzvv.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638805460/Shops/ginette/ginette2_ijmszo.jpg",
    perks: ["20% member’s discount off of events"],
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805466/Shops/ginette/ginette-coffee_h7uu1c.jpg",
        title: "Monthly coffee membership",
        id: "ginette-month",
        price: 30.0,
        frequency: 30,
        description:
          "Become a member and enjoy 15 coffees every month for just £2 each (usually £3).",
        totalRedeemables: 15,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805466/Shops/ginette/ginette-coffee_h7uu1c.jpg",
        title: "Monthly alternative coffee membership",
        id: "ginette-month-alt",
        price: 35.0,
        frequency: 30,
        description:
          "Become a member and enjoy 15 coffees with dairy alternatives for about £2.30 each (usually £3.30).",
        totalRedeemables: 15,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805478/Shops/ginette/ginette-coffee_ghnywr.jpg",
        title: "Annual coffee membership",
        id: "ginette-year",
        price: 60.0,
        frequency: 365,
        description:
          "Become a member and enjoy 30 coffees every year for just £2 each (usually £3)",
        totalRedeemables: 30,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805478/Shops/ginette/ginette-coffee_ghnywr.jpg",
        title: "Annual alternative coffee membership",
        id: "ginette-year-alt",
        price: 70.0,
        frequency: 365,
        description:
          "Become a member and enjoy 30 coffees with dairy alternatives every year for about £2.30 each (usually £3.30).",
        totalRedeemables: 30,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805478/Shops/ginette/ginette-coffee_ghnywr.jpg",
        title: "Annual large coffee membership",
        id: "ginette-year-l",
        price: 300.0,
        frequency: 365,
        description:
          "Usually £3 per cup, enjoy 180 coffees every year for about £1.65 each.",
        totalRedeemables: 180,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805478/Shops/ginette/ginette-coffee_ghnywr.jpg",
        title: "Annual large alternative coffee membership",
        id: "ginette-year-alt-l",
        price: 360.0,
        frequency: 365,
        description:
          "Usually £3.30 per cup, enjoy 180 coffees with dairy alternatives every year for just £2 each.",
        totalRedeemables: 180,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805488/Shops/ginette/ginette-breakfast_y05gfy.jpg",
        title: "Monthly breakfast membership",
        id: "ginette-breakfast",
        price: 48.0,
        frequency: 30,
        description:
          "Enjoy 8 breakfasts a month – coffee, fresh juice, and a morning pastry. You’ll save £12 each month.",
        totalRedeemables: 8,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805505/Shops/ginette/ginette-lunch_ts3arv.jpg",
        title: "Monthly lunch membership",
        id: "ginette-lunch",
        price: 45.0,
        frequency: 30,
        description:
          "Enjoy 5 lunches a month – any galette, quiche, toastie, or sandwich, plus coffee and a soft drink (Dalston’s, ginger shots, sparkling water, etc). You’ll save £12.50 each month.",
        totalRedeemables: 5,
      },
    ],
  },
  {
    name: "The Beauty Retreat",
    type: "Beauty Salon",
    id: "retreat",
    owner: "Annette",
    address: "140 Balls Pond Rd, London N1 4AD",
    coordinates: [51.54669255, -0.0845025500000007],
    neighbourhood: "Islington",
    description:
      "High quality treatments and the sweetest staff. We’re a cosy little shop in the heart of Islington, and are warm and welcoming to every client that walks through the door.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638805782/Shops/retreat/719076_wpdnbv.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638805876/Shops/retreat/br-owner_p1ikiq.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638805986/Shops/retreat/br-shop_sn1nnb.jpg",
    perks: [
      "Free treatment under £20 for new members (eyebrow threading, upper lip threading, eyebrow wax, etc...)",
    ],
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638806048/Shops/retreat/photo-1610992015732-2449b76344bc_qyzvnk.jpg",
        title: "Small Beauty Membership",
        id: "retreat-small",
        price: 40.0,
        frequency: 30,
        description:
          "Get two £25 digital vouchers to apply towards treatments each month – £10 free monthly. Multiple vouchers can be used each time.",
        totalRedeemables: 2,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638806107/Shops/retreat/photo-1618323405637-747ff9d136a2_ou1j41.jpg",
        title: "Large Beauty Membership",
        id: "retreat-large",
        price: 55.0,
        frequency: 30,
        description:
          "Get four £25 digital vouchers to apply towards treatments each month – £45 free monthly. Multiple vouchers can be used each time.",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638806166/Shops/retreat/pexels-photo-332046_wkooum.jpg",
        title: "Monthly Express Mani & Pedi (polish)",
        id: "retreat-polish",
        price: 15.0,
        frequency: 30,
        description:
          "Become a member and enjoy an express manicure and pedicure with polish once a month (usually £20)",
        totalRedeemables: 1,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638806227/Shops/retreat/719076_sdrhyj.jpg",
        title: "Monthly Express Mani & Pedi (gel)",
        id: "retreat-gel",
        price: 23.0,
        frequency: 30,
        description:
          "Become a member and enjoy an express manicure and pedicure with gel once a month (usually £30)",
        totalRedeemables: 1,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638806289/Shops/retreat/pexels-photo-7755241_zxtvte.jpg",
        title: "Shape, Paint, and Go Membership",
        id: "retreat-shape",
        price: 5.0,
        frequency: 7,
        description:
          "Come and go twice a week to change the colors on your fingers and toes (varnish only)",
        totalRedeemables: 2,
      },
    ],
  },
  {
    name: "Aura Organics Spa",
    type: "Beauty Salon",
    id: "aura",
    owner: "Danielle",
    address: "31 East Bay Lane, Canalside, Here East, Hackney Wick, E15 2GW",
    coordinates: [51.5467451, -0.0239371550493783],
    neighbourhood: "Hackney Wick",
    description:
      "We welcome you to Aura Spa. Our sacred space where you can indulge in guilt free beauty and wellbeing. We pride ourselves in high quality service with our friendly all female team. Using a blend of exotic ingredients and ancient healing remedies, we have created individually tailored treatments and globally inspired wellness rituals. All using our own range of carefully created holistic, herbal organic products.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638805789/Shops/aura/pexels-photo-3985338_wtrgc2.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638805811/Shops/aura/pexels-photo-5938576_emnft8.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638805827/Shops/aura/2020-09-15_ph0frt.jpg",
    perks: [],
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805838/Shops/aura/photo-1610992015732-2449b76344bc_ncewye.jpg",
        title: "£50 monthly treatment credit",
        id: "aura-50",
        price: 40.0,
        frequency: 30,
        description:
          "Get a £50 digital voucher to apply towards treatments each month – £10 free monthly.",
        totalRedeemables: 1,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805799/Shops/aura/photo-1618323405637-747ff9d136a2_p7zu0g.jpg",
        title: "£120 monthly treatment credit",
        id: "aura-120",
        price: 100.0,
        frequency: 30,
        description:
          "Get two £60 digital vouchers to apply towards treatments each month – £20 free monthly.",
        totalRedeemables: 2,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805851/Shops/aura/pexels-photo-332046_m2wudj.jpg",
        title: "Monthly gel mani & pedi treatment",
        id: "aura-manipedi",
        price: 70.0,
        frequency: 30,
        description:
          "Usually £75, you’ll also get a free mini facial each month from the owner herself!",
        totalRedeemables: 1,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805866/Shops/aura/photo-1575052814086-f385e2e2ad1b_kxuddo.jpg",
        title: "Monthly yoga subscription",
        id: "aura-yoga",
        price: 80.0,
        frequency: 30,
        description:
          "Usually 6 classes for £75, you’ll get 8 classes a month for just £80.",
        totalRedeemables: 8,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805811/Shops/aura/pexels-photo-5938576_emnft8.jpg",
        title: "Monthly product refill",
        id: "aura-refill",
        price: 12.0,
        frequency: 30,
        description:
          "Get a monthly refill of one of our scrubs or body creams (usually £17-20)",
        totalRedeemables: 1,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638805789/Shops/aura/pexels-photo-3985338_wtrgc2.jpg",
        title: "6 facials",
        id: "aura-facials",
        price: 325.0,
        frequency: 180,
        description:
          "Get 6 facials every half-year for less than the price of 5.",
        totalRedeemables: 6,
      },
    ],
  },
  {
    name: "49 Café",
    type: "Happy Café",
    id: "49cafe",
    owner: "Giuseppe",
    address: "49 Marchmont St, London WC1N 1AP",
    coordinates: [51.5249061, -0.125328361526323],
    neighbourhood: "Bloomsbury",
    description:
      "Beloved by local students, residents, and workers, we’re a family-owned business, the daily morning stop for everyone, and also Bloomsbury's happiest café! With fresh pastas, sandwiches, salads, pastries, and refreshments – plus great coffee and the friendliest staff – it’s no wonder why our beloved customers return every morning to queue! Menu: https://ritual.co/order/49-cafe-marchmont-st-kings-cross/10d6",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638806492/Shops/49cafe/20180131-125756_qabwlk.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638806556/Shops/49cafe/photo-1473093295043-cdd812d0e601_lmdukd.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638806661/Shops/49cafe/49cafe_xxw7r9.jpg",
    perks: ["The first 100 members get a free surprise gift!"],
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638806806/Shops/49cafe/2018-12-05_j5dlx7.jpg",
        title: "Get 5 monthly pasta meals",
        id: "49cafe-5",
        price: 20.0,
        frequency: 30,
        description:
          "Pay the price of 4 pastas a month and Giuseppe will throw in a 5th for free!",
        totalRedeemables: 5,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638806873/Shops/49cafe/20180821-100207_cda4ad.jpg",
        title: "Get 10 monthly pasta meals",
        id: "49cafe-10",
        price: 39.0,
        frequency: 30,
        description:
          "Pay the price of 8 pastas a month and Giuseppe will throw in 2 extra for free!",
        totalRedeemables: 10,
      },
    ],
  },
  {
    name: "Dot Dot Bubble Tea",
    type: "Bubble Tea & Waffles",
    id: "dotdot",
    owner: "Susie and Yandis",
    address: "37A Stoke Newington Church Street, N16 0NX",
    coordinates: [51.5620603, -0.0754513],
    neighbourhood: "Stoke Newington",
    description:
      "We are Dot Dot, a bubble tea and bubble waffle spot in Stoke Newington. We're bringing a conscientious perspective towards what is an established beverage and an iconic street snack from Hong Kong and Asia. We want to join the dots between taste and sustainability, as well as incorporating progressive flavour combinations.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1644225302/Shops/dotdot/Screenshot-2021-08-09-at-00-47-15_zjfjip_1_b1btuu.png",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638806515/Shops/dotdot/dot-owner_zugyft.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638806550/Shops/dotdot/dot-shop_pyex2j.jpg",
    generalDiscount: 15,
    perks: [
      "Member’s exclusive tasting events on new menu items, with free drinks",
    ],
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638806482/Shops/dotdot/Screenshot-2021-08-09-at-00-47-15_zjfjip.png",
        title: "Monthly Bubble Tea Club",
        id: "dotdot-bubble",
        price: 15.0,
        frequency: 30,
        description:
          "Become a member and receive 4 medium sizes bubble teas every month",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638806583/Shops/dotdot/dotdot_zvd9a0.png",
        title: "Monthly meal deal membership",
        id: "dotdot-meal",
        price: 36.0,
        frequency: 30,
        description:
          "Pick any Bubble Waffle Sandwich + any medium size Bubble Tea 4 times a month",
        totalRedeemables: 4,
      },
    ],
  },
  {
    name: "Smoky Corner",
    type: "Grill Restaurant",
    id: "smoky",
    owner: "Tural",
    address: "180 Kentish Town Rd, London NW5 2AE",
    coordinates: [51.547477, -0.141111],
    neighbourhood: "Kentish Town",
    description:
      "Visit our grill restaurant in Kentish Town and enjoy an impeccable American burger with a European touch. This impressive steak and burger house boasts a variety of unique spaces that soar over two stories, with regular live music as well.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807056/Shops/smoky/smoky-profile_ngszz5.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807132/Shops/smoky/smoky-venue_y1ic3u.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807260/Shops/smoky/smoky-owner_y2etcs.jpg",
    perks: ["Members get a free birthday surprise!"],
    generalDiscount: 15,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807369/Shops/smoky/photo-1541167760496-1628856ab772_axhddm.jpg",
        title: "Monthly coffee membership",
        id: "smoky-coffee",
        price: 15.0,
        frequency: 30,
        description:
          "Enjoy 20 artisan coffees each month! Includes dairy alternatives.",
        totalRedeemables: 20,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807445/Shops/smoky/photo-1587394214354-a427608f15cc_eq18kn.jpg",
        title: "Monthly pasta membership",
        id: "smoky-pasta",
        price: 25.0,
        frequency: 30,
        description: "Get any pasta of your choice, 4 times a month.",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807519/Shops/smoky/smoky-b_yogih1.jpg",
        title: "Monthly burger and beer membership",
        id: "smoky-burger",
        price: 30.0,
        frequency: 30,
        description:
          "Get any burger and bottled beer of your choice, 4 times a month.",
        totalRedeemables: 1,
      },
    ],
  },
  {
    name: "Pitanga",
    type: "Nigerian Food",
    id: "pitanga",
    owner: "Nky",
    address: "220 North End Rd, London W14 9NX",
    coordinates: [51.4863455, -0.2031661],
    neighbourhood: "West Kensington",
    description:
      "Born of a teenage dream to make Nigerian food as readily available in the United Kingdom as other ethnic cuisines, our carefully curated menu offers something for everyone: fish, meat, vegetarian, vegan, gluten-free, spicy, mild, sweet, savory, traditional and not-so-traditional.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807069/Shops/pitanga/pitange-1_v6ssfh.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807111/Shops/pitanga/pitanga-owner_byy3bg.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807139/Shops/pitanga/pitanga-venue_ibnrzg.webp",
    perks: [],
    generalDiscount: 25,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807167/Shops/pitanga/pitange-1_iudoon.jpg",
        title: "Get two £12.50 dining vouchers monthly",
        id: "pitanga-2",
        price: 20.0,
        frequency: 30,
        description:
          "Become a member and get two £12.50 digital vouchers to apply towards dining each month. That’s an extra 25% for free! Multiple vouchers can be used each time.",
        totalRedeemables: 2,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807194/Shops/pitanga/pitanga-2_z8kob4.jpg",
        title: "Get five £11 dining vouchers monthly",
        id: "pitanga-5",
        price: 40.0,
        frequency: 30,
        description:
          "Become a member and get five £11 digital vouchers to apply towards dining each month. That’s an extra 37% for free! Multiple vouchers can be used each time.",
        totalRedeemables: 5,
      },
    ],
  },
  {
    name: "Kamayan Sa Earl's Court",
    type: "Filipino restaurant",
    id: "kamayan",
    owner: "Arnel",
    address: "12 Kenway Rd, London SW5 0RR",
    coordinates: [51.4929208, -0.193246266332473],
    neighbourhood: "Earl's Court",
    description:
      "We at Kamayan Sa Earl's Court try to bring the culture of the Philippines to London through the universal language known as Food! We love what we do and we do it wholeheartedly. For over a decade, we’ve been transporting people back in time with Philippine customs and traditions, while enjoying the 'taste of home'. Menu: https://www.kamayansaearlscourt.co.uk/Menu",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807348/Shops/kamayan/kama-profile_mil00p.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807390/Shops/kamayan/Kamayan-buffet-style-filipino-food-barbecue-feast-810x608_qnqmll.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807438/Shops/kamayan/kama-venue_jfhifm.jpg",
    perks: ["Members get a free birthday surprise when dining in"],
    generalDiscount: 20,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807490/Shops/kamayan/kama-food_dmzl3l.jpg",
        title: "Get three £10 dining vouchers monthly",
        id: "kamayan-vouchers",
        price: 25.0,
        frequency: 30,
        description:
          "Become a member and get three £10 digital vouchers to apply towards dining each month. That’s an extra 20% for free! Multiple vouchers can be used each time.",
        totalRedeemables: 3,
      },
    ],
  },
  {
    name: "Cho Sim",
    type: "Korean Food",
    id: "chosim",
    address: "24 Goldhawk Rd, London W12 8DH",
    coordinates: [51.5028509, -0.225178575580221],
    neighbourhood: "Shepherd's Bush",
    description:
      "Serving up generous portions of Korean comfort food, it’s clear why many are heading again and again to West London to eat at Cho Sim. With modern decor paired with traditional cooking, standouts on the menu include their bibimbap, seafood pancakes, and homemade korean soups.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807573/Shops/chosim/chosim_modtt7.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807576/Shops/chosim/AF1QipMx1E5rQOcCgoPVMS26NmzktkajqguVa8QeUyLX_jmxlyz.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807579/Shops/chosim/AF1QipPPbNTiLfVbkikyU01fwLR-5FBPQhyqynzcwglm_kch7e9.png",
    perks: ["Free bottle of prosecco, wine, or any cocktail for your birthday"],
    generalDiscount: 15,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807594/Shops/chosim/AF1QipPRTJqG436fIuQYE553zrRGov2WqJn9O9MpkBcf_emdk5c.jpg",
        title: "Get 4 mains monthly",
        id: "chosim-mains",
        price: 35.0,
        frequency: 30,
        description: "Enjoy any main courses of your choice, 4 times a month.",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807586/Shops/chosim/AF1QipOxBsC7aAqOkgXJYOuIPtGQcI5Qca4-G6O25qS5_djpyoq.jpg",
        title: "Get five £10 vouchers monthly",
        id: "chosim-vouchers",
        price: 40.0,
        frequency: 30,
        description:
          "No pressure to spend £50 at once – you’ll get five £10 vouchers to spend monthly. Multiple vouchers can be used each time.",
        totalRedeemables: 5,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807609/Shops/chosim/photo-1551538827-9c037cb4f32a_k6t5qm.jpg",
        title: "Cocktail club",
        id: "chosim-cocktail",
        price: 30.0,
        frequency: 30,
        description:
          "Usually £9 each, enjoy a cocktail whenever you want, 5 times a month",
        totalRedeemables: 5,
      },
    ],
  },
  {
    name: "Green Valley",
    type: "Fresh Products",
    id: "greenvalley",
    owner: "Ozkan",
    address: "129 Essex Rd, London N1 2SN",
    coordinates: [51.5394975, -0.097863],
    neighbourhood: "Islington",
    description:
      "Produce the way it’s meant to taste, incredible coffee from Caravan Coffee Roasters, and a great selection of unique beers and wines. We’re a family-run business, and the neighborhood go-to for fresh juices, vegan products, and local products.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807664/Shops/greenvalley/greenvalley_hdsnhd.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807668/Shops/greenvalley/photo-1607522154179-da1c051ed109_temyit.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638807672/Shops/greenvalley/fv2_zszvzd.jpg",
    perks: ["Get £10 off if you spend more than £100 in a single transaction"],
    generalDiscount: 15,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807664/Shops/greenvalley/greenvalley_hdsnhd.jpg",
        title: "Green Valley Membership",
        id: "greenvalley-membership",
        price: 35.0,
        frequency: 30,
        description:
          "Get 4 monthly £10 vouchers to spend in store. Multiple vouchers can be used each time. You’ll save £60 in groceries a year, 12.5% monthly.",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807668/Shops/greenvalley/photo-1607522154179-da1c051ed109_temyit.jpg",
        title: "10 Cold-Pressed Juices",
        id: "greenvalley-juices",
        price: 39.0,
        frequency: 30,
        description: "Enjoy 10 cold-pressed juices for the price of 8!",
        totalRedeemables: 10,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807689/Shops/greenvalley/photo-1495474472287-4d71bcdd2085_s3hixa.jpg",
        title: "Caravan Coffee Membership",
        id: "greenvalley-coffee",
        price: 38.0,
        frequency: 30,
        description:
          "Enjoy 20 coffees each month for the price of 15! Includes dairy alternatives.",
        totalRedeemables: 20,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807702/Shops/greenvalley/photo-1603989873976-589abf82c54b_xl3dhy.jpg",
        title: "Craft Beer Club",
        id: "greenvalley-beer",
        price: 28.0,
        frequency: 30,
        description:
          "Keep the home bar stocked with 12 canned beers each month. Includes Beavertown beers and their limited edition Tropigamma!",
        totalRedeemables: 12,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807717/Shops/greenvalley/Website-Gifts-2_melsnm.jpg",
        title: "Monthly Caravan Coffee At-Home",
        id: "greenvalley-monthlycaravan",
        price: 14.0,
        frequency: 30,
        description:
          "Get a 250g bag of Caravan’s Daily Blend Espresso twice a month – either whole beans or ground. You’ll struggle to find a better price elsewhere!",
        totalRedeemables: 2,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638807664/Shops/greenvalley/greenvalley_hdsnhd.jpg",
        title: "Annual Green Valley Membership",
        id: "greenvalley-membership-annual",
        price: 70.0,
        frequency: 365,
        description:
          "Get eight £10 digital vouchers to spend in store each year. You’ll save 12.5% annually.",
        totalRedeemables: 8,
      },
    ],
  },
  {
    name: "Claudia's",
    type: "Cozy Coffee",
    id: "claudia",
    owner: "",
    address: "16 Kingsland Rd, London E2 8DA",
    coordinates: [51.527743, -0.077803],
    neighbourhood: "Shoreditch",
    description:
      "Frequently described as one of Shoreditch’s hidden gems, you’ll feel right at home at Claudia’s with artfully crafted coffee, loads of vegan options, and arguably London’s best homemade falafel wrap, made with love before your eyes (check out our Google reviews!). Stop in for a lemon drizzle and some great conversations!",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638812726/Shops/claudia/photo-1541167760496-1628856ab772_beamp7.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638812782/Shops/claudia/hcmp175341_801433_ts7err.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638812804/Shops/claudia/hcmp175341_801437_zl8cnu.jpg",
    perks: ["Members get a free surprise cake on their birthdays!"],
    generalDiscount: 9,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638812820/Shops/claudia/pexels-photo-1170659_ebptde.jpg",
        title: "Coffee membership",
        id: "claudia-coffee",
        price: 43.0,
        frequency: 30,
        description: "Enjoy 20 coffees each month for the price of 15!",
        totalRedeemables: 20,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638812837/Shops/claudia/photo-1541167760496-1628856ab772_kwg5tu.jpg",
        title: "Alternative coffee membership",
        id: "claudia-alternative",
        price: 49.0,
        frequency: 30,
        description:
          "Enjoy 20 coffees each month for the price of 15! Includes dairy alternatives.",
        totalRedeemables: 20,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638812741/Shops/claudia/pexels-photo-616833_uqi1io.jpg",
        title: "4 monthly smoothies",
        id: "claudia-smoothie",
        price: 13.0,
        frequency: 30,
        description: "Members get 4 monthly smoothies for the price of 3.",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638812854/Shops/claudia/hcmp175341_801432_s3qcgz.jpg",
        title: "Monthly cake club",
        id: "claudia-cake",
        price: 11.0,
        frequency: 30,
        description: "Members get 4 monthly cake slices for the price of 3.",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638812952/Shops/claudia/20180517104049_lavash-wrap-v2_kd7luq.jpg",
        title: "5 monthly falafel or chicken wraps",
        id: "claudia-5wraps",
        price: 28.0,
        frequency: 30,
        description:
          "Pay the price of 4 wraps a month and Paraskevi will throw in the 5th wrap for free!",
        totalRedeemables: 5,
      },
      {
        image:
          "https://eatforum.org/content/uploads/2019/02/2yA1C4oM-1200x675.jpg",
        title: "10 monthly falafel or chicken wraps",
        id: "claudia-10wraps",
        price: 55.0,
        frequency: 30,
        description:
          "Pay the price of 8 wraps a month and you’ll get 2 more for free! We kid you not, our falafel wraps are that addictive!",
        totalRedeemables: 10,
      },
    ],
  },
  {
    name: "Babuji",
    type: "South Asian Food",
    id: "babuji",
    owner: "Mo Miah",
    address: "343 Kentish Town Rd, London NW5 2TJ",
    coordinates: [51.5505767, -0.140942162886598],
    neighbourhood: "Kentish Town",
    description:
      "Our food is a homage to the street vendors and Cafés of South Asia. Combining dishes that our father encountered on his journeys through Bangladesh, India, and Pakistan. From Bengali bites, to street stall favourites of Mumbai, and traditional homely Pakistani dishes. By the way, we eat with our hands, cos that’s the Babuji way.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638812787/Shops/babuji/babuji-profile_dee824.png",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638812791/Shops/babuji/babuji-ownerreal_jzrz2t.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638812796/Shops/babuji/babuji-venue_g77la0.png",
    perks: [],
    generalDiscount: 15,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638812815/Shops/babuji/babuji-food2_kypnnx.jpg",
        title: "Two £10 dining vouchers monthly",
        id: "babuji-10",
        price: 15.0,
        frequency: 30,
        description:
          "Enjoy £20 of dining credit each month to spend on our dishes – £5 free monthly.",
        totalRedeemables: 2,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638812803/Shops/babuji/babuji-owner_ueoc3o.jpg",
        title: "Two £15 dining vouchers monthly",
        id: "babuji-15",
        price: 20.0,
        frequency: 30,
        description:
          "Enjoy £30 of dining credit each month – £10 free monthly.",
        totalRedeemables: 2,
      },
    ],
  },
  {
    name: "The Floating Boulangerie",
    type: "Bakery Boat",
    id: "floating",
    owner: "Lindsay and Jeremy",
    address: "Check thefloatingboulangerie.co.uk for our current location",
    neighbourhood: "London Canals",
    description:
      "An artisan micro bakery on a boat... or a floating bakery! Created by French couple Jeremy Huguet and Lindsay Morel-Huguet during the UK’s first lockdown, the bakery’s menu is abundant with classic French patisseries made with just the right amount of butter and savoir-faire.",
    profileImage: "https://i.ibb.co/wJ6mFqd/floating-profile.jpg",
    ownerImage: "https://i.ibb.co/FgPsTw0/floating-shop.jpg",
    shopImage: "https://i.ibb.co/pzmFrXr/floating-owner.jpg",
    perks: [
      "10% discount on all other experiences on Board (pizza night, private masterclass)",
    ],
    subscriptions: [
      {
        image: "https://i.ibb.co/0jTkcsd/floating-foo.jpg",
        title: "Breakfast Club",
        id: "floating-breakfast",
        price: 100.0,
        frequency: 365,
        description:
          "Usually £20 each, enjoy a traditional breakfast set menu 6 times a year, with one for free. Come on your own or share with friends! DM us on Instagram to book.",
        totalRedeemables: 6,
      },
      {
        image:
          "https://images.pexels.com/photos/821365/pexels-photo-821365.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        title: "Cheese on Board Membership",
        id: "floating-cheese",
        price: 110.0,
        frequency: 365,
        description:
          "Do you LOVE our Cheese on Board so much it’s becoming your monthly treat? Save 12% and get 5 experiences each year, for yourself or to share! DM us on Instagram to book.",
        totalRedeemables: 5,
      },
    ],
  },
  {
    name: "Andrew Jose",
    type: "Hair Salon and Academy",
    id: "andrew",
    owner: "Andrew",
    address: "1 Charlotte Street London W1T 1RB",
    coordinates: [51.51804395, -0.134545694859888],
    neighbourhood: "Fitzrovia",
    description:
      "An outstanding reputation of 32 years in the hairdressing business. We are literally famous for our blow-drys that last from dawn until dusk.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813090/Shops/andrew/andrew-blowdry_tr9gff.png",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813132/Shops/andrew/andrew-1_rc4cas.png",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813443/Shops/andrew/andrew-venue_io1wkf.jpg",
    perks: [
      "Invigorating scalp massages",
      "Bespoke hair care advice",
      "Complimentary warm and cold refreshments",
    ],
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638813090/Shops/andrew/andrew-blowdry_tr9gff.png",
        title: "Blowdry Membership",
        id: "andrew-blowdry",
        price: 125.0,
        frequency: 30,
        description:
          "Stop thinking about your hair! Get 4 blow dry pampering sessions a month at our salon!",
        totalRedeemables: 4,
      },
    ],
  },
  {
    name: "Le Petit Citron",
    type: "French Bistro",
    id: "citron",
    owner: "Emily & Lawrence",
    address: "98-100 Shepherds Bush Rd, London W6 7PD",
    coordinates: [51.49954985, -0.222292060441661],
    neighbourhood: "Hammersmith",
    description:
      "Your Family-run, Brook Green neighbourhood bistro, inspired by the flavours of Provence and the South West of France, creating food that warms your soul. Using the best sustainably-sourced produce combined with deeply satisfying, down to earth cooking, we are right here on your doorstep.",
    profileImage: "https://i.ibb.co/34hMHBb/citron-profile.jpg",
    ownerImage: "https://i.ibb.co/NWn9g0r/citron-owner.jpg",
    shopImage: "https://i.ibb.co/GPnh0Hy/citron-venue.jpg",
    perks: ["Coming soon!"],
    subscriptions: [
      {
        image:
          "https://i.ibb.co/0cGGy5P/6cfbbc8db26d8bd51c39071776dd532fbe03598b.jpg",
        title: "Weekend Brunch Membership",
        id: "citron-brunch",
        price: 35.0,
        frequency: 30,
        description:
          "Become a member and get 4 amazing weekend brunches every month! Includes any breakfast, brunch, tartine, waffle item (excluding the duck waffle). Redeemable only on the weekend!",
        totalRedeemables: 4,
      },
    ],
  },
  {
    name: "Sohishi Sushi & Bento",
    type: "Independent Sushi",
    id: "sohishi",
    owner: "Hida",
    address: "2 Charlotte Pl, London W1T 1SB",
    coordinates: [51.518945, -0.13626],
    neighbourhood: "Fitzrovia",
    description:
      "Sohishi is an independent sushi shop whose owners have decades of combined experience. Our meals are freshly handmade, better quality than the big chains, and extremely good value for money. Fresh sushi, nigiri, and rice triangles made daily. Katsu curries, vegan, and vegetarian options available as well!",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813615/Shops/sohishi/sohishi_e0nfne.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813640/Shops/sohishi/sushi3_n8l3hh.jpgg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813685/Shops/sohishi/sushi_nhfmuk.jpg",
    perks: ["Members get 20% off of catering (minimum spend £100)"],
    generalDiscount: 20,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638813714/Shops/sohishi/sushi1_rvp2kg.jpg",
        title: "Get three £13-meals a month",
        id: "sohishi-3meals",
        price: 30.0,
        frequency: 30,
        description:
          "Become a member choose up to £13 worth of food and drink per meal, 3 times a month – £9 worth of free sushi each month! REDEEMABLE ONLY AFTER 6PM.",
        totalRedeemables: 3,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638813743/Shops/sohishi/sushi2_awrqev.jpg",
        title: "Get six £14-meals a month",
        id: "sohishi-6meals",
        price: 60.0,
        frequency: 30,
        description:
          "Become a member choose up to £14 worth of food and drink per meal, 6 times a month – £24 worth of free sushi each month! REDEEMABLE ONLY AFTER 6PM.",
        totalRedeemables: 6,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638813714/Shops/sohishi/sushi1_rvp2kg.jpg",
        title: "Get twelve £13-meals a year",
        id: "sohishi-12meals",
        price: 120.0,
        frequency: 365,
        description:
          "Become a member and choose up to £13 worth of food and drink per meal, 6 times a year – £36 worth of free sushi each year! REDEEMABLE ONLY AFTER 6PM.",
        totalRedeemables: 12,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638813743/Shops/sohishi/sushi2_awrqev.jpg",
        title: "Get 18 meals a year",
        id: "sohishi-18meals",
        price: 180.0,
        frequency: 365,
        description:
          "Become a member and choose up to £14 worth of food and drink per meal, 12 times a year – £72 worth of free sushi each year! REDEEMABLE ONLY AFTER 6PM.",
        totalRedeemables: 18,
      },
    ],
  },
  {
    name: "Avoman",
    type: "Seasonal Fruit & Veg",
    id: "avoman",
    owner: "Carlos and Yusa",
    address: "87 Upper St, London N1 0NP",
    coordinates: [51.5369472, -0.103463894249305],
    neighbourhood: "Islington",
    description:
      "Avoman sell a mix of high-quality British vegetables, and specialise in Spanish avocados and mangoes. Visit them for a selection of awesome seasonal fruit and vegetables!",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813680/Shops/avoman/73ca82a46a89574c04ae0f748324440b3d5d5270_olel0m.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813687/Shops/avoman/box_xojuiu.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813705/Shops/avoman/506bb1717959958f95adc7061017d9b88d909e63_grdbqc.jpg",
    perks: ["Coming soon!"],
    generalDiscount: 10,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638813680/Shops/avoman/73ca82a46a89574c04ae0f748324440b3d5d5270_olel0m.jpg",
        title: "Avoman monthly membership",
        id: "avoman-monthly",
        price: 35.0,
        frequency: 30,
        description:
          "Get 4 monthly £10 vouchers to spend in store. Multiple vouchers can be used each time. You’ll save £60 in groceries a year, 12.5% monthly.",
        totalRedeemables: 4,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638813687/Shops/avoman/box_xojuiu.jpg",
        title: "Avoman weekly membership",
        id: "avoman-weekly",
        price: 10.0,
        frequency: 7,
        description:
          "Get a monthly £12 voucher to spend in store. You’ll save £104 in groceries a year!",
        totalRedeemables: 1,
      },
    ],
  },
  {
    name: "Ikkyu Conveni",
    type: "Asian Food",
    id: "ikkyu",
    owner: "Zhiqing",
    address: "102 Ladbroke Grove, London W11 1PY",
    coordinates: [51.51649575, -0.208966102176884],
    neighbourhood: "Notting Hill",
    description:
      "Asian supermarkets are few and far between in West London, and Ikkyu Conveni does not disappoint. Fresh sushi, piping hot katsu curries, and an extensive selection of everything to satisfy your Asian food cravings – we bet you won’t walk out empty handed ;)",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813872/Shops/ikkyu/ikkyue-owner_f5rpnl.webp",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813932/Shops/ikkyu/ikkyu-profile_opaqmz.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813974/Shops/ikkyu/ikkyue-venue_xsomro.png",
    perks: ["Coming soon!"],
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638813932/Shops/ikkyu/ikkyu-profile_opaqmz.jpg",
        title: "Get 3 sushi or katsu meals monthly",
        id: "ikkyu-sushi",
        price: 24.0,
        frequency: 30,
        description:
          "Enjoy either the Sushi Snack Box (8 pcs salmon avocado roll, 4 pcs salmon nigiri, edamame, and miso soup) or Ikkyu’s off-the-menu special (Japanese curry, chicken katsu, rice, and miso soup) 3 times a month!",
        totalRedeemables: 3,
      },
    ],
  },
  {
    name: "Masala",
    type: "Indian Restaurant",
    id: "masala",
    owner: "",
    address: "4A Hogarth Rd, London, SW5 0PT",
    coordinates: [51.4926816, -0.1927311],
    neighbourhood: "Earl's Court",
    description:
      "A local Earl’s Court favorite and family run restaurant, at Masala you’ll find homemade curries, thalis and sweet snacks, surrounded by colourful Indian decorations and whimsical folk art.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813947/Shops/masala/photo-1593560704563-f176a2eb61db_p9paxz.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813961/Shops/masala/photo-1599043513900-ed6fe01d3833_lpvmzq.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638813983/Shops/masala/AF1QipOYzDx_8QQ2bdTdSZs_sJyda3ST5nworIanzPpb_befazk.jpg",
    perks: [],
    generalDiscount: 15,
    subscriptions: [
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638814006/Shops/masala/photo-1610192244261-3f33de3f55e4_r3v4v8.jpg",
        title: "1 monthly meal",
        id: "masala-1",
        price: 10.0,
        frequency: 30,
        description:
          "Enjoy any main, rice or naan, and any starter once a month.",
        totalRedeemables: 1,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638814025/Shops/masala/pexels-photo-958545_qw0wxt.jpg",
        title: "1 monthly veggie meal",
        id: "masala-1veg",
        price: 8.0,
        frequency: 30,
        description:
          "Enjoy any vegetarian main, rice or naan, and any vegetarian starter once a month.",
        totalRedeemables: 1,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638813961/Shops/masala/photo-1599043513900-ed6fe01d3833_lpvmzq.jpg",
        title: "2 monthly meals",
        id: "masala-2",
        price: 18.0,
        frequency: 30,
        description:
          "Enjoy any main, rice or naan, and any starter twice a month.",
        totalRedeemables: 2,
      },
      {
        image:
          "https://res.cloudinary.com/shopscribe/image/upload/v1638814041/Shops/masala/photo-1588166524941-3bf61a9c41db_y3sjam.jpg",
        title: "2 monthly veggie meals",
        id: "masala-2veg",
        price: 14.0,
        frequency: 30,
        description:
          "Enjoy any vegetarian main, rice or naan, and any vegetarian starter twice a month.",
        totalRedeemables: 2,
      },
    ],
  },
  {
    name: "Marrakech Kitchen",
    type: "Maroccan Food",
    id: "marrakech",
    owner: "Rafik",
    address: [
      "Swiss Cottage Farmers Market (Wednesday)",
      "Hammersmith Lyric Sq. Market (Thursday)",
    ],
    coordinates: [
      [51.54454, -0.168961],
      [51.49328, -0.226052],
    ],
    neighbourhood: "Hammersmith",
    description:
      "Moroccan food combined with Middle Eastern cuisine, cooked fresh daily by Rafik himself. Rafik previously worked in catering for Harrods and Fortnum and Mason, before beginning Marrakech Kitchen over 10 years ago in Portobello Market.",
    profileImage:
      "https://www.marrakech-kitchen.co.uk/_webedit/cached-images/18-0-0-991-10000-7180-1920.jpg",
    ownerImage:
      "https://www.marrakech-kitchen.co.uk/_webedit/cached-images/19-0-442-15-8831-6626-1696.jpg",
    shopImage:
      "https://www.marrakech-kitchen.co.uk/_webedit/cached-images/37-0-0-0-9574-10000-512.jpg",
    perks: ["£1 member’s discount off of meals after using up memberships"],
    subscriptions: [
      {
        image:
          "https://www.marrakech-kitchen.co.uk/_webedit/cached-images/14-0-0-2901-10000-3307-1024.jpg",
        title: "2 meals a month",
        id: "marrakech-monthly",
        price: 15.0,
        frequency: 30,
        description:
          "Ranging from £7 to £9.50, get 2 meals a month – choose from anything on the menu",
        totalRedeemables: 2,
      },
      {
        image:
          "https://www.marrakech-kitchen.co.uk/_webedit/cached-images/43-0-0-1852-10000-4238-1920.jpg",
        title: "6 meals a year",
        id: "marrakech-annual",
        price: 48.0,
        frequency: 365,
        description:
          "Ranging from £7 to £9.50, get 6 meals a year – choose from anything on the menu",
        totalRedeemables: 6,
      },
    ],
  },
  {
    name: "Monami Eat",
    type: "Street Vietnamese",
    id: "monami",
    owner: "Lewis",
    address: "Hammersmith Lyric Square Market (on Wed and Thur)",
    coordinates: [51.49328, -0.226052],
    neighbourhood: "Hammersmith",
    description:
      "More than a decade after we started our first Vietnamese street food stall, we’ve established ourselves as one of London's best, known for our best selling 'Honey Roast Pork Banh Mi'. With quality fresh ingredients, we leave trails of rice noodles and delicious aromas on every corner that we touch.",
    profileImage: "https://i.ibb.co/dQdh0sK/monami-food.jpg",
    ownerImage: "https://i.ibb.co/Lk5Z2f0/monami-owner.jpg",
    shopImage: "https://i.ibb.co/r2LxsZD/monami-shop.jpg",
    perks: ["Members get £1 off of all other meals"],
    subscriptions: [
      {
        image: "https://i.ibb.co/dQdh0sK/monami-food.jpg",
        title: "2 meals + 4 free dumplings each time",
        id: "monami-2",
        price: 17.0,
        frequency: 30,
        description:
          "Become a member and get a meal of your choice 2 times a month, and 4 free homemade dumplings (usually £4) every meal.",
        totalRedeemables: 2,
      },
      {
        image: "https://i.ibb.co/7QZm1Yf/monami-profile.jpg",
        title: "4 meals + 4 free dumplings each time",
        id: "monami-4",
        price: 32.0,
        frequency: 30,
        description:
          "Become a member and get a meal of your choice 4 times a month, and 4 free homemade dumplings (usually £4) every meal.",
        totalRedeemables: 4,
      },
    ],
  },
  {
    name: "Here Organic",
    type: "Organic Products",
    owner: "Quintino",
    id: "hereorg",
    address: "Chelsea Farmers Market, 125 Sydney St, London SW3 6NR",
    coordinates: [51.48826505, -0.169808201134641],
    neighbourhood: "Chelsea",
    description: "100% Organic since 1999. Right in the heart of Chelsea",
    profileImage:
      "https://images.pexels.com/photos/709817/pexels-photo-709817.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    ownerImage:
      "https://images.happycow.net/venues/1024/10/75/hcmp107505_462337.jpeg",
    shopImage:
      "https://images.happycow.net/venues/1024/10/75/hcmp107505_462340.jpeg",
    perks: [],
    subscriptions: [
      {
        image:
          "https://images.unsplash.com/photo-1619582894454-cbdd4e2c456d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=894&q=80",
        title: "Here Organic Membership",
        id: "hereorg-extra",
        price: 30.0,
        frequency: 30,
        description:
          "Spend £30 and get a monthly £35 voucher to spend in store. You’ll get £60 in free groceries each year, saving you 15% monthly.",
        totalRedeemables: 1,
      },
    ],
  },
  {
    name: "Fine Cut Butchers",
    type: "Local Butcher",
    owner: "Fabio",
    id: "finecut",
    address: "51 Blue Anchor Ln, London SE16 3UL",
    coordinates: [51.4931493, -0.0636252],
    neighbourhood: "Bermondsey",
    description:
      "Fine Cut Butchers works with the best products from Brazil, Italy and Portugal. Our local shop boasts an incredible butcher’s counter, artisan coffee, and delicatessen with hard-to-find products. Our hidden restaurant is opening in June – pick your meat at the counter and we’ll grill it in front of your eyes.",
    profileImage:
      "https://hepburnsfood.co.uk/wp-content/uploads/2020/10/c449aa46-eff4-4822-a87c-54166ef32551.jpg",
    ownerImage:
      "https://i2.wp.com/www.bluebermondsey.co.uk/wp-content/uploads/2021/04/Fine-Cut-Butchers-Logo.png?ssl=1",
    shopImage:
      "https://images.pexels.com/photos/5313886/pexels-photo-5313886.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    perks: [
      "Free members-only product samples monthly",
      "Brazilian BBQ workshops coming soon ;)",
    ],
    subscriptions: [
      {
        image:
          "https://images.pexels.com/photos/5774145/pexels-photo-5774145.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        title: "Monthly butcher’s box",
        id: "finecut-box",
        price: 45.0,
        frequency: 30,
        description:
          "Get our most popular butcher’s box monthly – 1 kg sausage, 1.5 kg picanha (rump cap), 2 kg ribs, and 1 kg pork belly. Substitutions available!",
        totalRedeemables: 1,
      },
      {
        image:
          "https://images.pexels.com/photos/3687999/pexels-photo-3687999.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        title: "Weekly grocery box",
        id: "finecut-grocerybox",
        price: 80.0,
        frequency: 30,
        description:
          "Become a member and get up to £23 of meat and groceries for free, 4 times a month. Redeem Monday through Wednesday. You’ll get £144 in free groceries each year.",
        totalRedeemables: 4,
      },
      {
        image:
          "https://images.pexels.com/photos/5774154/pexels-photo-5774154.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        title: "Fine Cut’s grande membership",
        id: "finecut-grande",
        price: 80.0,
        frequency: 30,
        description:
          "Spend £80 and get FIVE £20 MONTHLY VOUCHERS to spend in store. Multiple vouchers can be used each time. In total, you’ll get £240 in free meat and groceries a year!",
        totalRedeemables: 5,
      },
      {
        image:
          "https://images.pexels.com/photos/5313867/pexels-photo-5313867.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        title: "Fine Cut’s pequeno membership",
        id: "finecut-pequeno",
        price: 45.0,
        frequency: 30,
        description:
          "Spend £45 and get £50 monthly to spend in store. You’ll get £60 in free meat and groceries each year, saving you 10% monthly.",
        totalRedeemables: 1,
      },
      {
        image:
          "https://images.pexels.com/photos/1170659/pexels-photo-1170659.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        title: "Monthly coffee membership",
        id: "finecut-coffee",
        price: 20.0,
        frequency: 30,
        description:
          "Move aside Pret! Get any artisan coffee on our menu, once a day.",
        totalRedeemables: 30,
      },
    ],
  },
  {
    name: "Cantinho da Maya",
    type: "Brazilian Food",
    owner: "Maya",
    id: "maya",
    address: "281 Southwark Park Rd, London SE16 3TP",
    coordinates: [51.4921215, -0.0605211],
    neighbourhood: "Bermondsey",
    description:
      "Cantinho da Maya has evolved over the years, but we remain true to our founding vision - a community restaurant - a place where people of all ages would feel welcome - a suitable place for a business lunch, a first meeting or a celebration.",
    profileImage:
      "https://blog.amigofoods.com/wp-content/uploads/2019/04/brazilian-foods.jpg",
    ownerImage:
      "https://www.curtamais.com.br/uploads/midias/e16e2b81d47ceb75b558cd0fa52370b8.jpg",
    shopImage:
      "https://media-cdn.tripadvisor.com/media/photo-s/13/cd/60/7a/dsc-4264-largejpg.jpg",
    perks: [],
    subscriptions: [
      {
        image:
          "https://www.oliviascuisine.com/wp-content/uploads/2020/04/brazilian-black-beans-stew.jpg",
        title: "Weekly lunch special",
        id: "maya-lunch",
        price: 17.0,
        frequency: 30,
        description:
          "Become a member and get their off-the-menu lunch special 4 times a month, made with love by Maya herself. Rice, beans, protein, and veggies – topped with traditional Brazilian fixings.",
        totalRedeemables: 4,
      },
    ],
  },
  {
    name: "Kennedy's of London",
    type: "British Eatery",
    owner: null,
    id: "kennedys",
    address: [
      "184-186 Goswell Road, EC1V 7DT",
      "169-171 Whitecross Street, EC1Y 8JT",
      "609 Fulham Road, SW6 5UA (formerly Coffee&Milk)",
    ],
    neighbourhood: "Old Street",
    description:
      "The Kennedy’s brand has been around for nearly 150 years as a quality purveyor of British fayre. To generations it has become synonymous with long established traditional food. We at Kennedy’s have maintained that proud tradition, whilst also adding innovative dishes to the range, to satisfy even the most discerning of palates.",
    profileImage:
      "https://www.kennedyslondon.co.uk/wp-content/uploads/2015/04/KE_791.jpg",
    ownerImage:
      "https://www.kennedyslondon.co.uk/wp-content/uploads/2015/04/IMG_5773.jpg",
    shopImage:
      "https://resizer.otstatic.com/v2/photos/wide-huge/3/28331433.jpg",
    perks: [
      "Members' venue hiring for events",
      "Exclusive invites to taste new offerings",
    ],
    subscriptions: [
      {
        image:
          "https://d1ralsognjng37.cloudfront.net/82968f75-8c73-48e9-8478-b0ba23b15661",
        title: "Get a weekly lunch",
        id: "kennedys-lunch",
        price: 29.0,
        frequency: 30,
        description:
          "Get a meal of your choice from the lunch menu, 4 times a month. Enjoy our specialities on a regular basis!",
        totalRedeemables: 4,
      },
      {
        image:
          "https://b.zmtcdn.com/data/pictures/chains/1/6123511/ffa1d3e10bb7b57bbf622122bfb7c693.jpg",
        title: "Get a bi-weekly dinner",
        id: "kennedys-dinner",
        price: 19.0,
        frequency: 30,
        description:
          "Get a dinner of your choice from the 'a la carte' menu, two times a month!",
        totalRedeemables: 4,
      },
      {
        image:
          "https://images.pexels.com/photos/1170659/pexels-photo-1170659.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        title: "Get a daily coffee",
        id: "kennedys-coffee",
        price: 29.0,
        frequency: 30,
        description:
          "Enjoy 30 coffees a month from any of our locations! The coffees can be redeemed multiple times daily, within the 30 coffees limit.",
        totalRedeemables: 30,
      },
      {
        image:
          "https://images.pexels.com/photos/3008/drinks-supermarket-cans-beverage.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        title: "Free drinks with every meal",
        id: "kennedys-drinks",
        price: 5.0,
        frequency: 30,
        description:
          "Get a surprise drink with every meal from the menu a la carte. That can be alcoholic or non-alcoholic and varies based on the current stock.",
        totalRedeemables: 100,
      },
    ],
  },
  {
    name: "Hidden Planet",
    type: "Artistic Plants",
    owner: "Ben",
    id: "hiddenp",
    address: "111A Commercial St, London E1 6BG",
    coordinates: [51.51984865, -0.0746937186468661],
    neighbourhood: "Shoreditch",
    description: `Hidden Planet, named after the owner Ben’s nature illustration books, will catch your eye immediately. You simply have to go in! The range of products is simple but alluring – animal illustration prints and plants of all sizes. His stunning prints and plants will go well together in any room of your house.`,
    profileImage: "https://pbs.twimg.com/media/Ey88JDlXAAQvI1F.jpg",
    ownerImage:
      "https://images.squarespace-cdn.com/content/v1/59ba4af29f8dce3da268ffcb/1612366517384-L3WSD6X4WQYBBCCXM4EG/ke17ZwdGBToddI8pDm48kNbLqx_FIYjfhtEsCHEHzad7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UX_hdIVndeO72MD00jTnc1n42Pd5vs4VrV4yXG_EV_fzwRAeN1AbZG4OR41R6pDVyg/Personal+brand+photographer+in+London+with+Ben+Rothery",
    shopImage: "https://pbs.twimg.com/media/Ey88JDlXAAQvI1F.jpg",
    perks: [
      "Members-only after-hours evenings with drinks provided",
      "Free Hidden Planet calendar after being a member for a year",
      "Priority access to ticketed events",
    ],
    subscriptions: [
      {
        image: "https://pbs.twimg.com/media/E0EcG8kXIAAy8L9.jpg",
        title: "Monthly plant, illustration and greeting card",
        id: "hiddenp-plant",
        price: 22.0,
        frequency: 30,
        description: `Become a member and get a free monthly small plant, A4 print, and greeting card. You’ll also get all of the exclusive member perks below!`,
        totalRedeemables: 1,
      },
    ],
  },
  {
    name: "Egro",
    type: "Italian groceries",
    id: "egro",
    owner: "Alessandro",
    address: "19 Camden Passsage, London N1 8EA",
    coordinates: [51.53498, -0.10391],
    neighbourhood: "Islington",
    description:
      "EGRO POP Bistro & Lounge! Every weekend Breakfast, Lunch and dinner, Happy Hour! All our ingredients are sourced from small producers, mostly Italian but also local. Many events in our calendar! Live performance and Yoga class on Saturday, Grocery & Craft Market on Sunday! & more…",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638539599/Shops/egro/609ea035ddcf627469a1183e_83_vyisa3.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638539601/Shops/egro/PXL_20211201_155111193_fmudps.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1638539600/Shops/egro/PXL_20211201_154632679_czuqje.jpg",
    perks: [
      "Bring a friend and get two additional free coffees!",
      "On your first order of minimum £30, get a free 100ml bottle of extra virgin oil",
    ],
    generalDiscount: 15,
    subscriptions: [],
  },
  {
    name: "Sproot",
    type: "Eatery",
    id: "sproot",
    owner: "Angus",
    address: "116D Upper Street, London N1 1QP",
    coordinates: [51.539011, -0.102637],
    neighbourhood: "Islington",
    description:
      "We are SPROOT! London’s new SUSTAINABLE fast-food brand and we are here to stay. Using exclusively seasonal produce, we will offer hearty plant-based dishes, fresh smoothies and specialty coffee as you’ve never seen before. Using fresh local produce, we hope to create great tasting bowls of food that our customers can enjoy over and over and over again.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/c_scale,w_500/v1639125993/Shops/sproot/IMG_20211208_171942_tqrmcu.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/c_scale,w_500/v1639127012/Shops/sproot/IMG_20211208_171909_mrmixv.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/c_scale,w_500/v1639125993/Shops/sproot/IMG_20211208_172125_tqbiiv.jpg",
    perks: ["Free bowl on your birthday 🎂", "Buy one side, get one free!"],
    generalDiscount: 10,
    subscriptions: [],
  },
  {
    name: "La Pasta",
    type: "Restaurant",
    id: "lapasta",
    owner: "Milaim Limani",
    address: "17-19 Highbury Cor, London N5 1RA",
    coordinates: [51.546559, -0.104152],
    neighbourhood: "Islington",
    description:
      "Iconic Italian Pizzeria located just outside Highbury & Islington station. La Pasta is family run business which offers a range of freshly mades pizzas and pastas as well as an extensive wine selection.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1639132245/Shops/lapasta/photo2jpg_kjx7qi.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1639133099/Shops/lapasta/penne-gratin_bxkzeq.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1639132587/Shops/lapasta/258844873_1588873964791198_3980866200566631789_n_a1e7sg.jpg",
    perks: [],
    generalDiscount: 20,
    subscriptions: [],
  },
  {
    name: "Flok Salon",
    type: "Beauty Salon",
    id: "flok",
    owner: "Drini",
    address: "358 Caledonian Rd, London N1 1DU",
    coordinates: [51.541225, -0.116847],
    neighbourhood: "Islington",
    description:
      "Put your feet up, let your hair down and get it styled to perfection. Soothing tunes in the background, up-and-coming art on the walls – all washed down with a fine tea. What's not to like?",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1639568670/Shops/flok/profile_vwxmru.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1639568670/Shops/flok/owner_h6xlhd.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1639568670/Shops/flok/shop_tzalih.jpg",
    perks: [],
    generalDiscount: 10,
    subscriptions: [],
  },
  /*{
    name: "Temptations Café",
    type: "Coffee Shop",
    id: "temptations",
    owner: "Nilesh Bhudia",
    address: "25-27 Highbury Corner, N5 1RA",
    coordinates: [51.5466, -0.1042],
    neighbourhood: "Islington",
    description:
      "Wonderful place for breakfast and lunch. An atmosphere that's relaxing and very zen, so you can just sit in here for hours with a cuppa and watch the world go by.",
    profileImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1639569728/Shops/temptations/profile_djv9mp.jpg",
    ownerImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1639569728/Shops/temptations/owner_z06pb7.jpg",
    shopImage:
      "https://res.cloudinary.com/shopscribe/image/upload/v1639569728/Shops/temptations/shop_fdnw8g.jpg",
    perks: [],
    generalDiscount: 10,
    subscriptions: [],
  },*/
];

// Export shops that have a generalDiscount, which means they are on Shopscribe version 2
export const v2shops = Shops.filter((shop) => shop.generalDiscount).sort(
  (a, b) => (a.generalDiscount > b.generalDiscount ? -1 : 1)
);
