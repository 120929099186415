import "./Tabs.css";
import { useState } from "react";
import PropTypes from "prop-types";
import { Notification } from ".";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Tabs = ({ tabs = [], setCurrentTab = () => {}, style = {} }) => {
  const [tabChecked, setTabChecked] = useState(
    tabs.findIndex((tab) => tab.default) ?? 0
  );

  const selectTab = (tabNo) => {
    setTabChecked(tabNo);
    setCurrentTab(tabNo);
  };

  return (
    <div style={{ ...style }}>
      <div className="tabs">
        {tabs.map((tab, i) => {
          return (
            <div style={{ width: `${100 / tabs.length}%` }} key={"key-" + i}>
              <input
                type="radio"
                id={"radio-" + i}
                name="tabs"
                defaultChecked={tab.default}
                onChange={() => selectTab(i)}
              />
              <label
                className="tab"
                htmlFor={"radio-" + i}
                style={{ width: "100%" }}
              >
                {isMobile ? (
                  <FontAwesomeIcon icon={tab.icon} size={"lg"} />
                ) : (
                  tab.text
                )}
                {tab.notification && (
                  <Notification>{tab.notification}</Notification>
                )}
              </label>
            </div>
          );
        })}
        <span
          className="glider"
          style={{
            transform: `translateX(${tabChecked * 100}%)`,
            width: `${isMobile ? 94 / tabs.length : 96.3 / tabs.length}%`,
          }}
        ></span>
      </div>
    </div>
  );
};

export default Tabs;

Tabs.propTypes = {
  tabs: PropTypes.array,
  setCurrentTab: PropTypes.func,
  style: PropTypes.object,
};

// tabs = [
//   {
//     text: "Example 1",
//     icon: {fontAwesome Icon},
//     default: true,
//     notification: "2"
//   },
//   {
//     text: "Example 2",
//     icon: {fontAwesome Icon},
//   },
// ];
