import moment from "moment";

var Airtable = require("airtable");
var base = new Airtable({ apiKey: "keyL6M1NeiO8H5Cpc" }).base(
  "applxcHDdHMa1E95I"
);
var giveawayBase = new Airtable({ apiKey: "keyL6M1NeiO8H5Cpc" }).base(
  "appkpy7pR1hzmddVG"
);

export const createAirtableSubBoxRecord = (
  price,
  nextDeliveryDate,
  displayName,
  phone,
  address,
  email,
  deliveryInfo,
  dietaryInfo,
  subId
) => {
  const fields = {
    Status: "Ordered",
    PaymentStatus: "Paid",
    TotalOrderPrice: price,
    "Delivery Date": nextDeliveryDate,
    "Customer Name": displayName,
    "Customer Phone number": phone,
    Address: address,
    Email: email,
    "Dietary Info": dietaryInfo,
    "Delivery Info": deliveryInfo,
    "Random GIF": randomGIF(),
    "Stripe subId": subId,
  };
  base("Subscriptions").create(
    [
      {
        fields: fields,
      },
    ],
    function (err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        console.log(record.getId());
      });
    }
  );
};

export const recordGiveawayEmail = async (email) => {
  const fields = {
    Email: email,
    Date: moment().format("YYYY-MM-DD"),
  };
  giveawayBase("Emails").create(
    [
      {
        fields: fields,
      },
    ],
    (err, records) => {
      if (err) {
        console.error(err);
        return null;
      }
      records.forEach(function (record) {
        return record.getId();
      });
    }
  );
};

const randomGIF = () => {
  const GIFs = [
    "https://media.giphy.com/media/2zlSwREmLmiQw/giphy.gif",
    "https://media.giphy.com/media/3oEjI798qgG6UMhJm0/giphy.gif",
    "https://media.giphy.com/media/l4KibK3JwaVo0CjDO/giphy.gif",
    "https://media.giphy.com/media/izbpiMMRS1GumbSLTv/giphy.gif",
    "https://media.giphy.com/media/GV3aYiEP8qbao/giphy.gif",
    "https://media.giphy.com/media/y8Mz1yj13s3kI/giphy.gif",
    "https://media.giphy.com/media/a6h71JgwZKz6w/giphy.gif",
    "https://media.giphy.com/media/5GoVLqeAOo6PK/giphy.gif",
    "https://media.giphy.com/media/1ThndC5odGuUU/giphy.gif",
    "https://media.giphy.com/media/10bHcDcPM925ry/giphy.gif",
  ];
  return GIFs[Math.floor(Math.random() * GIFs.length)];
};
