import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Map as PigeonMap, Marker } from "pigeon-maps";
import { UserMarker } from ".";
import { isMobile } from "react-device-detect";

function mapTiler(x, y, z, dpr) {
  return `https://api.maptiler.com/maps/bright/256/${z}/${x}/${y}@2x.png?key=vDlXzHIlG2KgRZgZH8mq`;
}

const Map = ({
  width = null,
  height = null,
  center = [51.524995, -0.125402],
  shops = [],
  setSelectedShop = null,
  onClick = () => {},
}) => {
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setUserLocation([position.coords.latitude, position.coords.longitude]);
    });
  }, []);

  return (
    <div
      style={{
        width: width ?? window.innerWidth,
        height: height ?? window.innerHeight,
      }}
      onMouseDown={(event) => {
        isMobile && event.stopPropagation();
      }}
    >
      <PigeonMap
        provider={mapTiler}
        zoom={11}
        defaultCenter={center}
        center={center}
        twoFingerDrag={true}
        onClick={() => {
          onClick();
        }}
      >
        {shops &&
          shops
            .filter((s) => s.coordinates)
            .map((shop) => {
              return typeof shop.coordinates[0] === "number" ? (
                <Marker
                  key={"mrkr-" + shop.id}
                  width={40}
                  anchor={shop.coordinates}
                  color="rgb(255, 126, 103)"
                  onClick={() => {
                    setSelectedShop && setSelectedShop(shop);
                  }}
                />
              ) : (
                shop.coordinates.map((coo, i) => {
                  return (
                    <Marker
                      key={"mrkr-" + shop.id + i}
                      width={40}
                      anchor={coo}
                      color="rgb(255, 126, 103)"
                      hover={true}
                      onClick={() => {
                        setSelectedShop && setSelectedShop(shop);
                      }}
                    />
                  );
                })
              );
            })}
        {userLocation && (
          <UserMarker
            key={"mrkr-user"}
            width={40}
            anchor={userLocation}
            onClick={() => {}}
          />
        )}
      </PigeonMap>
    </div>
  );
};

export default Map;

Map.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  center: PropTypes.array,
  shops: PropTypes.array,
  setSelectedShop: PropTypes.func,
  onClick: PropTypes.func,
};
