import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import SearchField from "react-search-field";
import { MultiSelect } from "react-multi-select-component";
import { useState, useEffect } from "react";
import { Map, Button } from "../../Components";
import { isMobile } from "react-device-detect";
import { v2shops } from "../../Data";

const shopsData = v2shops;
const totalShops = shopsData.length;

const ShopsList = ({
  shopsToPreview = totalShops,
  showMap = true,
  width = null,
  style = {},
}) => {
  const [shops, setShops] = useState(shopsData);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const history = useHistory();

  const goToShopPage = (id) => {
    history.push("/" + id);
  };

  const resetFilters = () => {
    setShops(shopsData);
    setSelectedLocations([]);
  };

  const searchShops = (value) => {
    let filteredData = shopsData.filter(
      (shop) =>
        shop.name.toLowerCase().includes(value.toLowerCase()) ||
        shop.type.toLowerCase().includes(value.toLowerCase()) ||
        shop.neighbourhood.toLowerCase().includes(value.toLowerCase())
    );
    setShops(filteredData);
  };

  const filterByLocation = (locations) => {
    setSelectedLocations(locations);
    setShops(
      locations.length === 0
        ? shopsData
        : shopsData.filter((shop) =>
            locations.map((l) => l.value).includes(shop.neighbourhood)
          )
    );
  };

  const getLocationList = () => {
    // Get the location for each shop, remove all duplicates, sort them alphabetically, turn into objects {label, value}
    const locations = [...new Set(shopsData.map((s) => s.neighbourhood))]
      .sort()
      .map((l) => {
        return { label: l, value: l };
      });
    return locations;
  };

  const shopsLocationFirst = (location) => {
    let sortedShops = shops;
    let shopsToAdd = [];
    // Remove location shops first (which will be added)
    shops.forEach((shop) => {
      if (shop.neighbourhood === location) {
        const shopToRemove = shop;
        const removeIndex = sortedShops.indexOf(shopToRemove);
        sortedShops.splice(removeIndex, 1);
        shopsToAdd.push(shopToRemove);
      }
    });
    // Sort removed (to be added) location shops
    shopsToAdd.reverse();
    // Add back the sorted location shops to start
    shopsToAdd.forEach((shop) => {
      sortedShops.unshift(shop);
    });
    setShops(sortedShops);
  };

  useEffect(() => {
    shopsLocationFirst("Islington");
    setShops(shopsToPreview ? shops.slice(0, shopsToPreview) : shops);
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ ...style, width: width }}>
      {showMap && (
        <Map
          height={isMobile ? null : 400}
          width={width}
          shops={shops}
          center={
            isMobile
              ? [51.52 - (window.innerHeight * 0.0002 - 0.07), -0.125]
              : [51.52, -0.125]
          }
          setSelectedShop={(selectedShop) => {
            setShops([shopsData.find((s) => s.id === selectedShop.id)]);
          }}
          onClick={() => {
            shops.length < totalShops && resetFilters();
          }}
        />
      )}
      <div
        className="block-hero"
        style={
          isMobile
            ? {
                marginTop:
                  isMobile && showMap ? (-window.innerHeight - 78) / 2 : 0,
                width: "90%",
                marginLeft: "5%",
              }
            : {}
        }
      >
        <div className="content">
          {isMobile && shops.length < totalShops && showMap && (
            <div style={{ zIndex: 1 }}>
              <Button
                small
                onClick={() => resetFilters()}
                style={{
                  marginTop: "auto",
                  width: "175px",
                }}
              >
                See all {">"}
              </Button>
            </div>
          )}
          <div
            className="row search-row"
            style={
              isMobile
                ? { margin: 0 }
                : { margin: showMap ? "-75px 0 30px" : "0 0 15px" }
            }
          >
            <div className="column" style={{ flex: "65%" }}>
              <SearchField
                placeholder="Search shops"
                onChange={(value) => searchShops(value)}
                onRequestSearch={(value) => searchShops(value)}
                classNames="search-box"
              />
            </div>
            <div className="column" style={{ flex: "35%" }}>
              <MultiSelect
                options={getLocationList()}
                value={selectedLocations}
                onChange={filterByLocation}
                overrideStrings={{ selectSomeItems: "Filter by location" }}
                disableSearch={true}
                hasSelectAll={false}
              />
            </div>
          </div>
          {shops.map((shop, i) => (
            <div
              className="app-screen---hero-1"
              onClick={() => goToShopPage(shop.id)}
              key={"shop-" + i}
              style={
                shop.unlisted ? { visibility: "hidden", display: "none" } : {}
              }
            >
              <img
                src={shop.profileImage}
                loading="lazy"
                alt=""
                className="shop-profile-landing"
              />
              <div className="shop-text-landing">
                <h4>{shop.name}</h4>
                <p>
                  {shop.type} · {shop.neighbourhood}
                </p>
              </div>
              <img
                src="images/arrow.svg"
                loading="lazy"
                alt=""
                className="shop-arrow-landing"
              />
            </div>
          ))}
          {shops.length === 0 && (
            <div>
              <p>
                <br />
                <b>No results for that yet :(</b>
              </p>
              <a
                href="https://forms.gle/Goaii3Uz3nCa2Y696"
                target="_blank"
                rel="noreferrer"
                className="nav-button w-button"
              >
                Recommend a shop {">"}
              </a>
            </div>
          )}
          <a
            href="/"
            className="nav-button w-button"
            style={{
              marginTop: "15px",
              border: "none",
            }}
            onClick={(e) => {
              e.preventDefault();
              if (shops.length < totalShops) {
                resetFilters();
              } else {
                setShops(shopsData.slice(0, 3));
              }
            }}
          >
            {shops.length < totalShops ? "See All >" : "< See Less"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ShopsList;

ShopsList.propTypes = {
  shopsData: PropTypes.array,
  shopsToPreview: PropTypes.number,
  showMap: PropTypes.bool,
  width: PropTypes.number,
  style: PropTypes.object,
};
