import Modal from "react-modal";
import React, { useState } from "react";
import ReactSwipeButton from "react-swipe-button";
import ReactMomentCountDown from "react-moment-countdown";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import Lottie from "react-lottie-player";

import animationFile from "./validAnimation.json";

const PICKUP_TIME = 2;

const PickupModal = ({
  isOpen,
  setIsOpen,
  pickupInfo,
  setPickupConfirmed,
  isDemoAccount,
}) => {
  const { shop, subscription, userSubscription, userName } = pickupInfo ?? {};
  const [swiped, setSwiped] = useState(false);
  const [countdown, setCountdown] = useState(null);

  const confirmPickup = () => {
    setPickupConfirmed(true);
    setSwiped(true);
    setCountdown(moment().add(PICKUP_TIME, "minutes"));
  };

  const closeModal = () => {
    setIsOpen(false);
    setSwiped(false);
    setCountdown(null);
  };

  return (
    <div style={{ fontFamily: "DM Sans !important" }}>
      {pickupInfo && (
        <Modal
          isOpen={isOpen}
          onRequestClose={() => closeModal()}
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
          contentLabel="Pickup"
          style={{
            overlay: {
              position: "fixed",
              display: "inline-flex",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              padding: "15px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 999999999,
            },
            content: {
              maxWidth: "400px",
              margin: "auto",
              padding: "25px",
              boxShadow: "none",
              outline: "none",
            },
          }}
          className="company-info"
        >
          <div>
            <img
              src="/images/close.svg"
              loading="lazy"
              alt=""
              style={{
                position: "relative",
                width: "15px",
                height: "auto",
                opacity: ".33",
                float: "right",
                cursor: "pointer",
              }}
              onClick={() => closeModal()}
            />
            {swiped ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src="/images/logo-orange.svg"
                  loading="lazy"
                  alt=""
                  className="logo"
                />
                <br />
                <h4
                  style={{
                    margin: "0",
                    fontSize: "24px",
                  }}
                >
                  {subscription.title}
                </h4>
                <p
                  style={{
                    margin: "5px 0 10px",
                    fontSize: "14px",
                    lineHeight: "120%",
                  }}
                >
                  <b>{shop.name}</b> -{" "}
                  {`${userSubscription.remainingItems}/${userSubscription.totalItems} remaining`}
                </p>
                <div
                  className="nav-button w-button"
                  data-tip={subscription.description}
                  data-for="pickup-description"
                  data-event="click"
                  style={{ marginBottom: "24px", padding: "5px 15px" }}
                >
                  More info {">"}
                </div>
                <ReactTooltip
                  effect="solid"
                  id="pickup-description"
                  place="bottom"
                  globalEventOff="click"
                />
                <h5 style={{ marginBottom: "24px" }}>
                  <img
                    src="/images/user.svg"
                    alt=""
                    width="20px"
                    height="20px"
                  />
                  {" " + userName}
                </h5>
                <div
                  className="company-info"
                  style={{
                    padding: "15px",
                    backgroundColor: "#fdf8f4",
                    boxShadow: "none",
                    margin: 0,
                    width: "100%",
                  }}
                >
                  {isDemoAccount ? (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <h4 style={{ marginBottom: 0, color: "#ff7e67" }}>
                        THIS IS NOT A VALID MEMBERSHIP
                      </h4>
                      <p
                        style={{
                          fontSize: "14px",
                          lineHeight: "110%",
                          opacity: ".5",
                          marginTop: "5px",
                          marginBottom: 0,
                        }}
                      >
                        You're on a demo account
                      </p>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Lottie
                        loop
                        animationData={animationFile}
                        play
                        style={{
                          width: "80px",
                          height: "80px",
                          transform: "scale(1.5)",
                        }}
                      />
                      <h4
                        style={{
                          margin: "10px 0 0 0",
                          color: "#61B329",
                          fontSize: "24px",
                        }}
                      >
                        Valid Membership
                      </h4>

                      <p
                        style={{
                          fontSize: "14px",
                          lineHeight: "110%",
                          opacity: ".5",
                          marginTop: "5px",
                          marginBottom: 0,
                        }}
                      >
                        Show this to the shop staff in the next{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "larger",
                            lineHeight: "140%",
                          }}
                        >
                          {countdown && (
                            <ReactMomentCountDown
                              toDate={countdown}
                              targetFormatMask="m:ss"
                              onCountdownEnd={() => closeModal()}
                            />
                          )}{" "}
                          minutes
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div>
                  <h4
                    style={{
                      fontSize: "24px",
                    }}
                  >
                    Ready to collect this?
                  </h4>
                  <div
                    className="company-info"
                    style={{
                      padding: "15px",
                      backgroundColor: "#fdf8f4",
                      boxShadow: "none",
                      width: "100%",
                    }}
                  >
                    <h5
                      style={{
                        lineHeight: "110%",
                        margin: "0",
                      }}
                    >
                      <a
                        href={"/" + shop.id + "/" + subscription.id}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        {subscription.title}
                      </a>
                    </h5>
                    <p
                      style={{
                        fontSize: "14px",
                        lineHeight: "110%",
                        margin: "5px 0 0",
                        fontWeight: "bold",
                      }}
                    >
                      {`${userSubscription.remainingItems}/${userSubscription.totalItems} remaining`}{" "}
                      · {shop.name}
                    </p>
                    <div
                      className="nav-button w-button"
                      data-tip={subscription.description}
                      data-for="pickup-description"
                      data-event="click"
                      style={{ marginTop: "10px", padding: "5px 15px" }}
                    >
                      More info {">"}
                    </div>
                    <ReactTooltip
                      effect="solid"
                      id="pickup-description"
                      place="bottom"
                      globalEventOff="click"
                    />
                  </div>
                  <br />
                  <p
                    style={{
                      fontSize: "14px",
                      lineHeight: "120%",
                      margin: "20px 0",
                      color: "#ff7e67",
                    }}
                  >
                    Swipe and show the order to the staff.{" "}
                    <b>Make sure to swipe only when ready to collect!</b>
                  </p>
                  <ReactSwipeButton
                    text="Swipe to collect"
                    color="#ff7e67"
                    onSuccess={() => confirmPickup()}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PickupModal;
