import React from "react";

const Loading = () => {
  return (
    <div className="account" style={{ padding: "0px" }}>
        <div className="block-account" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
          <div className="pulse-circle" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "120px",
            width: "120px"
          }}>
            <img
              src="/images/logo-app.svg"
              loading="lazy"
              alt=""
              height={80} 
              width={80} 
            />
          </div>
        </div>
    </div>
  );
};

export default Loading;
