import React from "react";
import {
  Route,
} from "react-router-dom";
import {
  Loading
} from "../../Pages";

/**
 * Ensures Auth is ready (shows loading page while waiting for Auth)
 *
 * @param {User} user
 * @param {boolean} loading
 * @param {JSX.Element} [auth] Elements to render if Auth
 * @param {JSX.Element} [unAuth] Elements to render if unAuth
 * @return {JSX.Element}
 */
const AuthRoute = (props) => {

  if (!props.loading) {
    if (props.user) {
      return (
        <Route path={props.path}>
          {props.auth}
        </Route>
      );      
    } else {
      return (
        <Route path={props.path}>
          {props.unAuth}
        </Route>
      );
    }
  } else {
    return (
      <Loading/>
    );
  }

};

export default AuthRoute;