import { useHistory } from "react-router-dom";
import { NavBar, Button, Footer, ShopsList } from "../../Components";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { analytics } from "../../firebase";
import Lottie from "react-lottie-player";

import basketAnimation from "./basket-lottie.json";
import locationAnimation from "./location-lottie.json";
import giftAnimation from "./gift-lottie.json";
import heartAnimation from "./heart-lottie.json";
import partyAnimation from "./party-lottie.json";

const Homepage = ({ user }) => {
  const history = useHistory();

  const [basketIconAnimation, animateBasketIcon] = useState(0);
  const [locationIconAnimation, animateLocationIcon] = useState(0);
  const [giftIconAnimation, animateGiftIcon] = useState(0);
  const [heartIconAnimation, animateHeartIcon] = useState(0);
  const [partyIconAnimation, animatePartyIcon] = useState(0);

  useEffect(() => {
    setTimeout(function () {
      var elements = document.getElementsByClassName("late-load");
      for (let el of elements) el.style.opacity = 1;
    }, 1000);
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
  }, []);

  const onFreeTrialClicked = () => {
    analytics.logEvent("home_page_free_trial_clicked");
    history.push("/free-trial");
  };

  return (
    <div>
      <div className="late-load">
        <NavBar forShops={false} padded={false} hideMenu={true} />
      </div>
      <div
        className="section-hero"
        style={
          isMobile ? { height: window.innerHeight - 50 } : { height: "95vh" }
        }
      >
        <div
          data-w-id="bfdef6b2-2e26-0bbb-6944-284fe24ab051"
          className="block-hero-img"
          style={{ height: isMobile ? "100%" : "auto", marginTop: 0 }}
        >
          <div className="content" style={{ margin: "auto" }}>
            <div className="row" style={{ width: "100%" }}>
              <div
                className="column content"
                style={{ flex: "60%", textAlign: isMobile ? "center" : "left" }}
              >
                <div style={{ width: "100%" }}>
                  <h1
                    data-w-id="Heading 2"
                    className="heading-hero"
                    style={{
                      fontSize: isMobile ? "5.5vh" : "80px",
                    }}
                  >
                    Enjoy top quality
                    <br />
                    products <span className="text-accent">for less</span>
                  </h1>
                  <p
                    data-w-id="Paragraph 2"
                    className="paragraph-hero small-text-shops"
                    style={{ marginBottom: isMobile ? "25px" : "34px" }}
                  >
                    Try Shopscribe and get extra money to spend at the best
                    local businesses around you, plus exclusive perks and
                    benefits.
                  </p>
                  <div
                    className="button-container late-load"
                    style={{
                      margin: isMobile ? "100px auto 0" : "25px 0 0",
                      width: "100%",
                      maxWidth: "350px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        history.push(
                          user && user.membershipId ? "/profile" : "/free-trial"
                        )
                      }
                    >
                      {user && user.membershipId
                        ? "Redeem your credit"
                        : "Get 1 week free"}
                    </Button>
                    <div
                      style={{
                        marginTop: "-12px",
                      }}
                    >
                      <Button secondary onClick={() => history.push("/shops")}>
                        Discover shops nearby
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column content late-load" style={{ flex: "40%" }}>
                {!isMobile && (
                  <div
                    style={{
                      height: window.innerHeight / 1.65 + 24,
                      width: "auto",
                      maxWidth: "500px",
                      padding: "12px",
                      backgroundColor: "white",
                      borderRadius: "15px",
                      margin: "auto",
                      boxShadow: "0 25px 50px -10px rgba(100, 44, 0, 0.07)",
                    }}
                  >
                    <video
                      height={window.innerHeight / 1.65}
                      width="auto"
                      muted="muted"
                      loop="loop"
                      playsInline="playsinline"
                      data-autoplay="true"
                      data-lazyload="true"
                      data-poster="images/screenshot2.png"
                      className="video__video"
                      autoPlay={true}
                      poster="images/screenshot2.png"
                    ></video>
                    <div className="block-circle---hero-1 late-load">
                      <div className="circle-animation-2">
                        <img
                          src="images/transparent-circle.png"
                          loading="lazy"
                          sizes="9vw"
                          srcSet="
                images/transparent-circle-p-500.png 500w,
                images/transparent-circle.png       730w
              "
                          alt=""
                          className="image-circle---5"
                        />
                      </div>
                      <div className="circle-animation-3">
                        <img
                          src="images/transparent-circle.png"
                          loading="lazy"
                          sizes="(max-width: 479px) 4vw, 5vw"
                          srcSet="
                images/transparent-circle-p-500.png 500w,
                images/transparent-circle.png       730w
              "
                          alt=""
                          className="image-circle---9"
                        />
                      </div>
                    </div>
                    <div className="block-circle---hero-3 late-load">
                      <div className="circle-animation-1">
                        <img
                          src="images/orange-circle.png"
                          loading="lazy"
                          sizes="(max-width: 479px) 18vw, 19vw"
                          srcSet="
                images/orange-circle-p-500.png 500w,
                images/orange-circle.png       730w
              "
                          alt=""
                          className="image-circle---4"
                        />
                      </div>
                      <div className="circle-animation-2">
                        <img
                          src="images/orange-circle.png"
                          loading="lazy"
                          sizes="6vw"
                          srcSet="
                images/orange-circle-p-500.png 500w,
                images/orange-circle.png       730w
              "
                          alt=""
                          className="image-circle---8"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="section"
        style={{
          backgroundColor: "#f7f0e8",
          padding: "100px 30px",
        }}
      >
        <div data-w-id="Div Block" className="content">
          <div className="w-layout-grid grid-feature">
            <div data-w-id="Div Block 3" className="feature">
              <div
                className="icon-feature-bg"
                onMouseEnter={() =>
                  animateBasketIcon(basketIconAnimation === false ? 0 : false)
                }
              >
                <Lottie
                  animationData={basketAnimation}
                  loop={basketIconAnimation}
                  play
                  style={{
                    width: "35px",
                    height: "35px",
                    transform: "scale(1.6)",
                  }}
                />
              </div>
              <h5 className="heading-feature">Get more for less</h5>
              <p className="paragraph-feature">
                We give you extra money to spend at the best local shops and
                eateries in your neighbourhood.
              </p>
            </div>
            <div
              data-w-id="7ff82385-63af-97e4-0df9-20a52258e593"
              className="feature"
            >
              <div
                className="icon-feature-bg"
                onMouseEnter={() =>
                  animateLocationIcon(
                    locationIconAnimation === false ? 0 : false
                  )
                }
              >
                <Lottie
                  animationData={locationAnimation}
                  loop={locationIconAnimation}
                  play
                  speed={1.5}
                  style={{
                    width: "35px",
                    height: "35px",
                    transform: "scale(1.7)",
                  }}
                />
              </div>
              <h5 className="heading-feature">Discover hidden gems</h5>
              <p className="paragraph-feature">
                We make it easy for you to discover the best small businesses
                around you, wherever they may be hidden.
              </p>
            </div>
            <div
              data-w-id="b24198d2-60fb-b52c-8736-dd0afc0f60da"
              className="feature"
            >
              <div
                className="icon-feature-bg"
                onMouseEnter={() =>
                  animateGiftIcon(giftIconAnimation === false ? 0 : false)
                }
              >
                <Lottie
                  animationData={giftAnimation}
                  loop={giftIconAnimation}
                  play
                  style={{
                    width: "35px",
                    height: "35px",
                    transform: "scale(1.6)",
                  }}
                />
              </div>
              <h5 className="heading-feature">Enjoy top quality</h5>
              <p className="paragraph-feature">
                Treat yourself more often to the best products and services,
                with exclusive members' perks and benefits.
              </p>
            </div>
            <div
              data-w-id="bc8a8e07-3be7-78fc-0781-49bbd191ec59"
              className="feature"
            >
              <div
                className="icon-feature-bg"
                onMouseEnter={() =>
                  animateHeartIcon(heartIconAnimation === false ? 0 : false)
                }
              >
                <Lottie
                  animationData={heartAnimation}
                  loop={heartIconAnimation}
                  play
                  style={{
                    width: "35px",
                    height: "35px",
                    transform: "scale(1.6)",
                  }}
                />
              </div>
              <h5 className="heading-feature">Support local</h5>
              <p className="paragraph-feature">
                By using Shopscribe, you empower small business owners to
                continue doing what they love - not big chains.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section" style={{ height: "100vh", padding: "0" }}>
        <div className="row" style={{ width: "100%", height: "100%" }}>
          <div
            className="column"
            style={{
              backgroundImage:
                "url(https://res.cloudinary.com/shopscribe/image/upload/v1640470472/landing-mini_x9ko0h.jpg)",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            {!isMobile && (
              <img
                src="/images/wave.svg"
                alt=""
                style={{ float: "right", height: "100%", marginRight: "-85px" }}
              />
            )}
          </div>
          <div
            className="column"
            style={{
              maxHeight: "100vh",
              overflow: "auto",
              width: "100%",
              margin: isMobile ? "auto 0" : "auto 0 auto -25px",
              padding: "25px 0",
            }}
          >
            <ShopsList
              showMap={false}
              shopsToPreview={4}
              style={{ marginTop: isMobile ? "0 !important" : "auto" }}
            />
          </div>
        </div>
      </div>
      <div
        className="section"
        style={{
          backgroundColor: "#f7f0e8",
        }}
      >
        <div className="content">
          <div className="block-center">
            <h2
              data-w-id="c0134b8c-83d5-8cb3-75cd-01896103e19b"
              className="heading"
            >
              How it works
            </h2>
          </div>
          <div className="w-layout-grid grid-sequence">
            <div data-w-id="Div Block 7" className="sequence">
              <div
                className="icon-sequence-bg"
                onMouseEnter={() =>
                  animateGiftIcon(giftIconAnimation === false ? 0 : false)
                }
              >
                <div className="number-sequence-bg">
                  <div className="number-sequence">1</div>
                </div>
                <Lottie
                  animationData={giftAnimation}
                  loop={giftIconAnimation}
                  play
                  style={{
                    width: "35px",
                    height: "35px",
                    transform: "scale(1.6)",
                  }}
                />
              </div>
              <h4 className="heading-sequence">
                Sign up and get free credits at local shops
              </h4>
              <p className="paragraph-sequence">
                Pick your monthly plan and get extra money in your account to
                spend across the best local businesses!
              </p>
            </div>
            <img
              src="images/arrow-1.svg"
              loading="lazy"
              data-w-id="Image"
              alt=""
              id="w-node-image-45b06075"
              className="img-arrow---1"
            />
            <div data-w-id="Div Block 7" className="sequence">
              <div
                className="icon-sequence-bg"
                onMouseEnter={() =>
                  animateBasketIcon(basketIconAnimation === false ? 0 : false)
                }
              >
                <div className="number-sequence-bg">
                  <div className="number-sequence">2</div>
                </div>
                <Lottie
                  animationData={basketAnimation}
                  loop={basketIconAnimation}
                  play
                  style={{
                    width: "35px",
                    height: "35px",
                    transform: "scale(1.6)",
                  }}
                />
              </div>
              <h4 className="heading-sequence">
                Go to shops and order as normal
              </h4>
              <p className="paragraph-sequence">
                When asked to pay, scan the QR code in-store or open your
                Shopscribe profile to pay with your credit.
              </p>
            </div>
            <img
              src="images/arrow-2.svg"
              loading="lazy"
              data-w-id="8fdb0733-9c0b-9d9a-d4ac-47309542a143"
              alt=""
              id="w-node-_8fdb0733-9c0b-9d9a-d4ac-47309542a143-45b06075"
              className="img-arrow---2"
            />
            <div data-w-id="Div Block 7" className="sequence">
              <div
                className="icon-sequence-bg"
                onMouseEnter={() =>
                  animatePartyIcon(partyIconAnimation === false ? 0 : false)
                }
              >
                <div className="number-sequence-bg">
                  <div className="number-sequence">3</div>
                </div>
                <Lottie
                  animationData={partyAnimation}
                  loop={partyIconAnimation}
                  play
                  style={{
                    width: "35px",
                    height: "35px",
                    transform: "scale(1.6)",
                  }}
                />
              </div>
              <h4 className="heading-sequence">Use your credit to pay</h4>
              <p className="paragraph-sequence">
                Just type in how much you're spending and show the confirmation
                to the staff. All done!
              </p>
            </div>
          </div>
          <Button onClick={() => onFreeTrialClicked()}>
            {user && user.membershipId
              ? "Redeem your credit"
              : "Get started for free"}
          </Button>
          {!(user && user.membershipId) && (
            <p
              className="shop-description"
              style={{
                margin: "0 auto",
                textAlign: "center",
                opacity: 0.33,
              }}
            >
              Cancel anytime
            </p>
          )}
        </div>
      </div>
      <div className="section">
        <div className="content">
          <div
            data-w-id="d0dc812c-ec2f-fd6d-6ad9-77361725f73b"
            className="w-layout-grid grid-banner"
          >
            <div className="text-banner">
              <h2 className="heading white">Keep up to date!</h2>
              <p className="paragraph white">
                Stay notified of all the new shops joining! We send an email out
                at most once every 2 weeks.
              </p>
              <div className="form-block-banner w-form" id="mc_embed_signup">
                <form
                  action="https://shopscribe.us6.list-manage.com/subscribe/post?u=19e0eb88aa9c9b287dae8e7f5&amp;id=30cd513cd1"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate form-banner"
                  target="_blank"
                  noValidate
                >
                  <div className="mc-field-group">
                    <input
                      type="email"
                      className="text-field-white w-input required email"
                      maxLength="256"
                      name="EMAIL"
                      data-name="Email"
                      placeholder="Your email"
                      id="mce-EMAIL"
                      required=""
                      defaultValue=""
                    />
                  </div>
                  <div id="mce-responses" className="clear">
                    <div
                      style={{ position: "absolute", left: "-5000px" }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_19e0eb88aa9c9b287dae8e7f5_30cd513cd1"
                        tabIndex="-1"
                        defaultValue=""
                      />
                    </div>
                    <div className="clear" style={{ marginLeft: "15px" }}>
                      <input
                        type="submit"
                        value="Go!"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="submit-button-white w-button button"
                        data-wait="Please wait..."
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="img-banner">
              <div className="block-app-screen---c">
                <img
                  src="https://i.ibb.co/JcJd9zw/ginette2.jpg"
                  loading="lazy"
                  alt=""
                  className="app-screen---c"
                />
              </div>
              <div className="block-circle---c-down">
                <img
                  src="images/orange-circle.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 24vw, (max-width: 767px) 25vw, 24vw"
                  srcSet="
                  images/orange-circle-p-500.png 500w,
                  images/orange-circle.png       730w
                "
                  alt=""
                  className="image-circle---c1"
                />
                <img
                  src="images/orange-circle.png"
                  loading="lazy"
                  sizes="9vw"
                  srcSet="
                  images/orange-circle-p-500.png 500w,
                  images/orange-circle.png       730w
                "
                  alt=""
                  className="image-circle---c2"
                />
              </div>
              <div className="block-circle---c-top">
                <img
                  src="images/orange-circle.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 18vw, 19vw"
                  srcSet="
                  images/orange-circle-p-500.png 500w,
                  images/orange-circle.png       730w
                "
                  alt=""
                  className="image-circle---c3"
                />
                <img
                  src="images/orange-circle.png"
                  loading="lazy"
                  sizes="10vw"
                  srcSet="
                  images/orange-circle-p-500.png 500w,
                  images/orange-circle.png       730w
                "
                  alt=""
                  className="image-circle---c4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
