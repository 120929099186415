import { getShopSubscriptions, getShopTransactions } from "../../firebase";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import moment from "moment";
import Protect from "react-app-protect";
import ReactTooltip from "react-tooltip";
import "react-app-protect/dist/index.css";

const ShopDashboard = ({ shopsData }) => {
  let { shopId } = useParams();
  let shop = shopsData.find((x) => x.id === shopId);
  let crypto = require("crypto");
  const [memberships, setMemberships] = useState(null);
  const [transactions, setTransactions] = useState(null);

  useEffect(() => {
    const fetchShopMemberships = async () => {
      const data = await getShopSubscriptions(shopId);
      if (data) setMemberships(data);
    };
    fetchShopMemberships();
    const fetchShopTransactions = async () => {
      const data = await getShopTransactions(shopId);
      if (data) setTransactions(data);
    };
    fetchShopTransactions();
    //eslint-disable-next-line
  }, []);

  const getPopularMemberships = () => {
    const membershipCount = {};
    memberships.forEach((m) => {
      if (membershipCount[m.subscriptionId]) {
        membershipCount[m.subscriptionId].count++;
      } else {
        membershipCount[m.subscriptionId] = {
          count: 1,
          name: shop.subscriptions.find((s) => s.id === m.subscriptionId).title,
        };
      }
    });
    return Object.values(membershipCount).sort((a, b) =>
      a.count > b.count ? 1 : b.count > a.count ? -1 : 0
    );
  };

  const calculateMonthlySubscriptionRevenue = () => {
    let total = 0;
    if (memberships) {
      if (memberships.length <= 0) return total;
      memberships.forEach((membership) => {
        if (membership.frequency === 30) total += membership.price;
        if (membership.frequency === 7) total += membership.price * 4;
        if (membership.frequency === 180) total += membership.price / 6;
        if (membership.frequency === 365) total += membership.price / 12;
      });
    }
    return total;
  };

  const calculateMonthlyTransactionRevenue = () => {
    let total = 0;
    if (transactions) {
      if (transactions.length <= 0) return total;
      transactions.forEach((transaction) => {
        if (moment().startOf("month").isBefore(transaction.timestamp)) {
          total += parseFloat(transaction.amount);
        }
      });
    }
    return total;
  };

  const calculateYearlyProjectedRevenue = () => {
    let total = 0;
    if (transactions) {
      if (transactions.length <= 0) return total;
      transactions.forEach((transaction) => {
        if (moment().startOf("year").isBefore(transaction.timestamp)) {
          total += parseFloat(transaction.amount);
        }
      });
    }
    const dayOfYear = moment().dayOfYear();
    total = (total / dayOfYear) * 365;
    total += calculateMonthlySubscriptionRevenue() * 12;
    return total;
  };

  return (
    <Protect
      blur={true}
      boxTitle="Insert your unique shop password"
      buttonLabel="Confirm"
      sha512={crypto
        .createHash("sha512")
        .update(
          shop.id +
            "-" +
            shop.id[shop.id.length - 1] +
            9 +
            shop.id.length +
            shop.id[0]
        )
        .digest("hex")}
    >
      <div className="account" style={{ paddingTop: "70px" }}>
        {memberships ? (
          <div className="dashboard">
            <div style={{ marginBottom: "50px" }}>
              <p style={{ marginBottom: "20px", marginLeft: "-10px" }}>
                <a href="/home.html">
                  <img
                    src="/images/logo-orange.svg"
                    loading="lazy"
                    alt=""
                    className="logo"
                  />
                </a>
              </p>
              <h3 style={{ marginBottom: 0 }}>{shop.name}</h3>
              <p>Shop Dashboard</p>
            </div>
            <div className="row" style={{ width: "100%" }}>
              <div className="column">
                <div className="row" style={{ width: "100%" }}>
                  {memberships.length > 0 && (
                    <div className="dashboard-box">
                      <p style={{ marginBottom: 0 }}>Total Subscriptions</p>
                      <h2 style={{ marginBottom: 0 }}>{memberships.length}</h2>
                    </div>
                  )}
                  <div className="dashboard-box">
                    <p style={{ marginBottom: 0 }}>
                      Monthly Revenue{" "}
                      <span
                        data-tip="You will receive this at the end of the month - net of our 10% fee."
                        data-for="path"
                        style={{
                          display: "inline-block",
                          opacity: 0.33,
                          width: "17px",
                          paddingBottom: "3px",
                          marginLeft: "3px",
                        }}
                      >
                        <img alt="" src="/images/info.svg" />
                      </span>
                    </p>
                    <ReactTooltip effect="solid" id="path" />
                    <h2 style={{ marginBottom: 0 }}>
                      £
                      {(
                        calculateMonthlySubscriptionRevenue() +
                        calculateMonthlyTransactionRevenue()
                      ).toFixed(2)}
                    </h2>
                  </div>
                </div>
                <div className="dashboard-box" style={{ width: "auto" }}>
                  {memberships.length > 0 && (
                    <p style={{ marginBottom: 0 }}>
                      New Subscribers this Week:{" "}
                      <b style={{ fontSize: "20px" }}>
                        {
                          memberships.filter(
                            (m) =>
                              moment().diff(m.subscriptionDate, "days") <= 7 &&
                              moment().diff(m.subscriptionDate, "days") >= 0
                          ).length
                        }
                      </b>
                    </p>
                  )}
                  <p style={{ marginBottom: 0 }}>
                    Estimated annual revenue:{" "}
                    <b style={{ fontSize: "20px" }}>
                      £{calculateYearlyProjectedRevenue().toFixed(0)}
                    </b>
                  </p>
                </div>
              </div>
              <div className="column">
                {memberships.length > 0 && (
                  <div className="dashboard-box" style={{ width: "auto" }}>
                    <p>Most popular memberships</p>
                    {getPopularMemberships().length === 0 ? (
                      <h5 style={{ marginBottom: 0 }}>No memberships yet</h5>
                    ) : (
                      getPopularMemberships().map((m, i) => {
                        return (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            key={"pop-" + i}
                          >
                            <h5 style={{ marginBottom: 0 }}>
                              {i + 1}. {m.name}
                            </h5>
                            <h5
                              style={{
                                marginBottom: 0,
                                opacity: 0.33,
                              }}
                            >
                              {m.count}
                            </h5>
                          </div>
                        );
                      })
                    )}
                  </div>
                )}
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "140%",
                    marginBottom: "10px",
                  }}
                >
                  For more information or if you have any query please reach out
                  by emailing us at{" "}
                  <a href="mailto:info@shopscribe.com">info@shopscribe.com</a>
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "140%",
                  }}
                >
                  Check{" "}
                  <a
                    href="https://ibb.co/Kz319j2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    how it works
                  </a>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="block-account">
            <Loader type="TailSpin" color="#ff7e67" height={80} width={80} />
            <h6 style={{ color: "#ff7e67" }}>
              Putting together some numbers...
            </h6>
          </div>
        )}
      </div>
    </Protect>
  );
};

export default ShopDashboard;
