import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import moment from "moment";
import emailjs from "emailjs-com";
import uuid from "react-uuid";
import { createAirtableSubBoxRecord } from "./airtable";

var firebaseConfig = {
  apiKey: "AIzaSyDGaC5tsVq70XZtcMrIFaaNgS0M-TVNh0g",
  authDomain: "shopscribe-88f37.firebaseapp.com",
  projectId: "shopscribe-88f37",
  storageBucket: "shopscribe-88f37.appspot.com",
  messagingSenderId: "613349909985",
  appId: "1:613349909985:web:600d94ab5b13e34d8d5ea0",
  measurementId: "G-SH7185V7J2",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  return auth.signInWithPopup(provider);
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;
  let userId = user.uid;
  const userRef = firestore.doc(`users/${userId}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { email, displayName } = user;
    const sameEmailUsers = await firestore
      .collection("users")
      .where("email", "==", email)
      .get();
    // Only create new users if email unique
    if (sameEmailUsers.docs.length === 0) {
      try {
        await userRef.set({
          displayName,
          email,
        });
      } catch (error) {
        console.error("Error creating user document", error);
      }
    } else {
      // If email already exists in firestore but not in auth -> guest account, grab membership and store in new auth
      const guestUserDoc = sameEmailUsers.docs[0];
      if (guestUserDoc.data().guest === true) {
        let membershipId = null;
        if (guestUserDoc.data().membershipId)
          membershipId = guestUserDoc.data().membershipId;
        try {
          await userRef.set({
            displayName,
            email,
            membershipId: membershipId,
            ...additionalData,
          });
        } catch (error) {
          console.error("Error creating user document", error);
        }
        // Update all user related databases
        const subs = await firestore
          .collection("subscriptions")
          .where("userId", "==", guestUserDoc.id)
          .get();
        subs.forEach(async (s) => {
          await s.ref.update({
            userId: user.uid,
          });
        });
        const transactions = await firestore
          .collection("transactions")
          .where("userId", "==", guestUserDoc.id)
          .get();
        transactions.forEach(async (t) => {
          await t.ref.update({
            userId: user.uid,
          });
        });
        // Delete guest user
        await guestUserDoc.ref.delete();
      }
    }
  }
  if (additionalData) {
    await userRef.update({ ...additionalData });
  }
  return getFirebaseUserDocument(userId);
};

export const getFirebaseUserDocument = async (uid) => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    const user = userDocument.data();
    if (user) {
      // Check if subscriptions[0] is an object
      if (user.subscriptions && typeof user.subscriptions[0] === "object") {
        // If yes, create a new subscription document on firebase with the same content
        for (let i = 0; i < user.subscriptions.length; i++) {
          let s = user.subscriptions[i];
          // eslint-disable-next-line
          let newSub = await subscribeToShop(user, s, i, s.demo);
        }
      } else {
        // Get the subscriptions and plug them in the user object
        user.subscriptions = await getSubscriptions(uid);
      }
      return {
        uid,
        ...user,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

export const getSubBoxUsers = async () => {
  try {
    const usersCollection = await firestore
      .collection("users")
      .where("subBoxPaid", "==", true)
      .get();
    return usersCollection;
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

export const subscribeToShop = async (
  user,
  shopId,
  subscription,
  index = -1,
  demo = false,
  uniqueId = uuid()
) => {
  // Create a new subscription object and post it to firebase
  let newSubscription = null;
  do {
    newSubscription = await createSubscriptionDocument(
      user,
      shopId,
      subscription,
      demo,
      uniqueId
    );
  } while (!newSubscription);
  // Add subscription id to the user object
  let newUser = null;
  do {
    newUser = await updateUserDocument(user, index, uniqueId);
  } while (!newUser);
  // Send confirmation email
  !demo &&
    emailjs.send(
      "service_gmail",
      "template_default",
      { email_address: newUser.email },
      "user_yMEuniWliXIzjHSwGSRJi"
    );
  return newSubscription;
};

export const subscribeToShopscribe = async ({
  user = {},
  price = 0,
  totalCredit = 0,
  id = uuid(),
  remainingCredit = undefined,
  demo = false,
  sendConfirmationEmail = true,
  freeTrialDuration = 0,
}) => {
  // Create a new subscription object and post it to firebase
  let newSubscription = null;
  do {
    try {
      const subscriptionRef = firestore.doc(`subscriptions/${id}`);
      await subscriptionRef.set({
        userId: user.uid,
        price: price,
        renewalDate: moment()
          .add(
            freeTrialDuration > 0 ? freeTrialDuration : 1,
            freeTrialDuration ? "days" : "months"
          )
          .format(),
        remainingCredit: remainingCredit ? remainingCredit : totalCredit,
        totalCredit: totalCredit,
        uniqueId: id,
        demo: demo ?? user.demo,
        freeTrialEnd: moment().add(freeTrialDuration, "days").format(),
      });
      newSubscription = true;
    } catch (error) {
      console.error(
        "Firebase: Error creating subscription document. Retrying...",
        error
      );
      newSubscription = false;
    }
  } while (!newSubscription);
  // Add subscription id to the user object
  let newUser = null;
  do {
    try {
      const userRef = firestore.doc(`users/${user.uid}`);
      await userRef.update({ membershipId: id });
      newUser = true;
    } catch (error) {
      console.error("Firebase: Error updating the user. Retrying...", error);
      newUser = false;
    }
  } while (!newUser);
  // Send confirmation email
  sendConfirmationEmail &&
    !user.demo &&
    emailjs.send(
      "service_gmail",
      "template_default",
      { email_address: user.email },
      "user_yMEuniWliXIzjHSwGSRJi"
    );
};

export const subscribeToBox = async ({
  user = {},
  price = 0,
  id = uuid(),
  demo = false,
  sendConfirmationEmail = true,
}) => {
  // Create a new subscription object and post it to firebase
  let userId = user.uid;
  if (!userId) {
    firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        userId = u.uid;
      }
    });
  }
  const subDocument = await firestore.doc(`boxes/${id}`).get();
  if (!subDocument.exists) {
    try {
      const subscriptionRef = firestore.doc(`boxes/${id}`);
      await subscriptionRef.set({
        userId: userId,
        price: price,
        uniqueId: id,
        demo: demo ?? user.demo,
      });
      createAirtableSubBoxRecord(
        parseFloat(price.toFixed(2)),
        user.nextDeliveryDate,
        user.displayName,
        user.phone,
        user.address,
        user.email,
        user.deliveryInfo,
        user.dietaryInfo,
        id
      );
    } catch (error) {
      console.error("Firebase: Error creating subscription document.", error);
    }
    // Add subscription id to the user object
    let newUser = null;
    do {
      try {
        const userRef = firestore.doc(`users/${user.uid}`);
        await userRef.update({ subBoxSubId: id, subBoxPaid: true });
        newUser = true;
      } catch (error) {
        console.error("Firebase: Error updating the user. Retrying...", error);
        newUser = false;
      }
    } while (!newUser);
  }
};

export const createSubscriptionDocument = async (
  user,
  shopId,
  subscription,
  demo,
  uniqueId
) => {
  const subscriptionRef = firestore.doc(`subscriptions/${uniqueId}`);
  const newSubscription = {
    userId: user.uid,
    shopId: shopId,
    subscriptionId: subscription.id,
    price: subscription.price,
    subscriptionDate: subscription.subscriptionDate ?? moment().format(),
    renewalDate: subscription.subscriptionDate
      ? moment(subscription.subscriptionDate)
          .add(subscription.frequency, "days")
          .format()
      : moment().add(subscription.frequency, "days").format(),
    totalItems: subscription.totalRedeemables,
    remainingItems: subscription.totalRedeemables,
    frequency: subscription.frequency,
    uniqueId: uniqueId,
    demo: demo,
  };
  try {
    await subscriptionRef.set(newSubscription);
    return true;
  } catch (error) {
    console.error("Error creating subscription document", error);
    return false;
  }
};

export const updateUserDocument = async (user, index, uniqueId) => {
  const userRef = firestore.doc(`users/${user.uid}`);
  const userDocument = await userRef.get();
  const subscriptions = userDocument.data().subscriptions ?? [];
  subscriptions[index >= 0 ? index : subscriptions.length] = uniqueId;
  try {
    await userRef.update({ subscriptions: subscriptions });
    return userDocument.data();
  } catch (error) {
    console.error("Error updating subscriptions", error);
    return null;
  }
};

export const getSubscriptions = async (userId) => {
  let subscriptions = [];
  // TODO: Instead of the query below, we should get the subscription IDs from the user reference and just query those IDs.
  let subs = await firestore
    .collection("subscriptions")
    .where("userId", "==", userId)
    .get();
  subs.forEach((s) => subscriptions.push(s.data()));
  // Check if membership should renew
  if (
    subscriptions.some(
      (sub) =>
        moment().isAfter(sub.renewalDate) || moment().isSame(sub.renewalDate)
    )
  ) {
    subscriptions = await renewMembership(subscriptions);
  }
  return subscriptions.length === 0 ? null : subscriptions;
};

export const pickupItem = async (user, subscriptionId) => {
  if (!user) return null;
  const subscriptionRef = await firestore
    .doc(`subscriptions/${subscriptionId}`)
    .get();
  const subscription = subscriptionRef.data();
  // Remove 1 from remaining items of the subscription
  if (subscription.remainingItems > 0) subscription.remainingItems--;
  // Update firebase
  try {
    await firestore.doc(`subscriptions/${subscriptionId}`).set(subscription);
  } catch (error) {
    console.error("Error picking up an item", error);
    return null;
  }
  return subscription;
};

const renewMembership = async (subscriptions) => {
  subscriptions.forEach(async (sub) => {
    // If renewalData is in the past, renew that subscription
    if (moment().isAfter(sub.renewalDate) || moment().isSame(sub.renewalDate)) {
      // Single product subscription renewal
      if (sub.totalItems) sub.remainingItems = sub.totalItems;
      // General Shopscribe subscription renewal
      if (sub.totalCredit) {
        let remainingCredits = parseFloat(sub.remainingCredit);
        let totalMonthlyCredit = parseFloat(sub.totalCredit);
        let creditToRollover =
          remainingCredits > totalMonthlyCredit
            ? totalMonthlyCredit
            : remainingCredits;
        sub.remainingCredit = totalMonthlyCredit + creditToRollover;
      }
      sub.renewalDate = moment()
        .add(sub.frequency ?? 30, "days")
        .format();
      try {
        await firestore.doc(`subscriptions/${sub.uniqueId}`).set(sub);
      } catch (error) {
        console.error("Error renewing a subscription", error);
        return null;
      }
    }
  });
  return subscriptions;
};

export const getShopSubscriptions = async (shopId) => {
  const subs = await firestore
    .collection("subscriptions")
    .where("shopId", "==", shopId)
    .get();
  let shopData = [];
  subs.forEach((s) => {
    let data = s.data();
    // Do not consider demo subscriptions
    if (!data.demo) shopData.push(data);
  });
  return shopData;
};

export const getShopTransactions = async (shopId) => {
  const transactions = await firestore
    .collection("transactions")
    .where("shopId", "==", shopId)
    .get();
  let shopData = [];
  transactions.forEach((t) => {
    let data = t.data();
    // Do not consider demo transactions
    if (!data.demo) shopData.push(data);
  });
  return shopData;
};

export const sendPasswordRecoveryEmail = async (email) => {
  let response;
  if (!email || email === "" || !email.includes("@"))
    return { success: false, message: "The email you inserted is not valid" };
  await auth
    .sendPasswordResetEmail(email)
    .then(() => {
      response = {
        success: true,
        message: "Great! Sent you email to reset your password.",
      };
    })
    .catch((error) => {
      response = {
        success: false,
        message:
          error.code === "auth/user-not-found"
            ? "This email is not associated with any user."
            : error.message,
      };
    });
  return response;
};

export const redeemMembership = async (user, amount, shopId) => {
  try {
    if (!user) return null;
    //Remove the used amount from the membership
    const membershipRef = await firestore
      .doc(`subscriptions/${user.membershipId}`)
      .get();
    await firestore.doc(`subscriptions/${user.membershipId}`).set(
      {
        remainingCredit:
          parseFloat(membershipRef.data().remainingCredit).toFixed(2) - amount,
      },
      { merge: true }
    );
    //Record the transaction, if it's not a demo
    if (user.subscriptions.find((s) => s.uniqueId === user.membershipId).demo) {
      return "Demo transaction successful";
    }
    const transactionId = uuid();
    const transaction = {
      transactionId: transactionId,
      userId: user.uid,
      shopId: shopId,
      amount: parseFloat(amount).toFixed(2),
      timestamp: moment().toISOString(),
    };
    await firestore.doc(`transactions/${transactionId}`).set(transaction);
    return transaction;
  } catch (error) {
    console.log("Firebase: Error updating the remaining credit");
    console.log(error);
    return null;
  }
};

export const isEmailUnique = async (email) => {
  const users = await firestore
    .collection("users")
    .where("email", "==", email)
    .get();
  return users.docs.length === 0;
};
