import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
  NavBar,
  Slider,
  Card,
  Button,
  TangibleExample,
  priceToCredit,
  priceToExtraPercent,
  getMinPrice,
  getMaxPrice,
  ShopsList,
} from "../../Components";
import {
  generateUserDocument,
  getFirebaseUserDocument,
  subscribeToShopscribe,
} from "../../firebase";
import { request } from "../../backend";

const DEFAULT_AMOUNT = 50;

const MembershipPicker = ({ user, refreshUserInfo }) => {
  const urlAmount = new URLSearchParams(window.location.search).get("amount");
  const updateSub =
    new URLSearchParams(window.location.search).get("update-sub") === "true"
      ? true
      : false;

  const history = useHistory();

  const [amount, setAmount] = useState(
    urlAmount && !isNaN(Number(urlAmount)) && Number(urlAmount) < 200
      ? Number(urlAmount)
      : DEFAULT_AMOUNT
  );
  const [shopscribeMembership, setShopscribeMembership] = useState();
  const [shopsShown, setShopsShown] = useState(!isMobile);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setShopscribeMembership(
        user.subscriptions && user.subscriptions.find((s) => !s.shopId)
      );
    }
  }, [user]);

  const subscribe = async () => {
    if (!updateSub) {
      const userId = user.id || user.uid;
      const userDocument = await getFirebaseUserDocument(userId);
      if (userDocument && userDocument.uid) {
        history.push(
          user ? `/checkout/${amount}` : `/signup?callback=/checkout/${amount}`
        );
      } else {
        const userOnPostgres = await request(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`,
          "GET"
        );
        if (userOnPostgres && userOnPostgres.subId) {
          alert(
            "Sorry! we're phasing out memberships - we don't support memberships for box subscribers."
          );
        } else {
          await generateUserDocument(user);
          history.push(
            user
              ? `/checkout/${amount}`
              : `/signup?callback=/checkout/${amount}`
          );
        }
      }
    } else {
      setLoading(true);
      if (user) {
        //Get sub id
        let updatedStripeSub = {};
        if (!shopscribeMembership.demo) {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/update-subscription`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                subId: user.membershipId,
                price: amount,
              }),
            }
          );
          updatedStripeSub = await response.json();
        }
        if (
          shopscribeMembership.demo ||
          (updatedStripeSub &&
            updatedStripeSub?.id === user.membershipId &&
            updatedStripeSub?.plan?.amount === amount * 100)
        ) {
          // Ensure conversion to floats
          const floatPrice = parseFloat(amount);
          const floatCredit = parseFloat(priceToCredit(floatPrice));
          const floatOldPrice = parseFloat(shopscribeMembership.price);
          let floatRemainingCredit = parseFloat(
            shopscribeMembership.remainingCredit
          );
          // If higher price, rollover previous credits. If lower price keep same credits, change price which will update credits next cycle
          const isFreeTrial =
            shopscribeMembership &&
            shopscribeMembership.freeTrialEnd &&
            moment().isBefore(shopscribeMembership.freeTrialEnd)
              ? true
              : false;
          if (floatPrice > floatOldPrice && !isFreeTrial)
            floatRemainingCredit += floatCredit;
          subscribeToShopscribe({
            user: user,
            price: floatPrice,
            totalCredit: floatCredit,
            id: shopscribeMembership.uniqueId,
            remainingCredit: floatRemainingCredit,
            demo: shopscribeMembership.demo,
            sendConfirmationEmail: false,
          }).then(() => {
            refreshUserInfo();
            history.push("/profile");
          });
        } else {
          throw new Error("Stripe: Error updating the subscription");
        }
      } else {
        throw new Error("Error updating the subscription - no user");
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <NavBar forShops={false} />
      <div className="row">
        <div
          className="column"
          style={{
            flex: "50%",
            maxHeight: isMobile ? "auto" : "100vh",
            overflow: isMobile ? "visible" : "auto",
          }}
        >
          <div
            className="section-hero"
            style={
              isMobile
                ? { marginTop: "0", paddingTop: "125px", paddingBottom: "25px" }
                : { paddingTop: 0, marginTop: "150px", paddingBottom: 0 }
            }
          >
            <div className="block-hero" style={{ width: "100%" }}>
              <div className="content" style={{ maxWidth: "500px" }}>
                <h4
                  style={
                    isMobile
                      ? { fontSize: "25px", marginBottom: 0 }
                      : { marginBottom: 0 }
                  }
                >
                  {updateSub
                    ? `Change your monthly price`
                    : `Pick a monthly price`}
                </h4>
                <p style={{ marginBottom: "25px", fontSize: "16px" }}>
                  {updateSub
                    ? "Unused credits will roll over. Upgrades start immediately."
                    : `You can cancel anytime!`}
                </p>
                <Slider
                  step={5}
                  min={getMinPrice()}
                  max={getMaxPrice()}
                  value={amount}
                  onChange={setAmount}
                  price={true}
                  style={{ margin: "15px 0 45px" }}
                />
                <h4 style={isMobile ? { fontSize: "25px" } : {}}>
                  What you get
                </h4>
                <Card width="100%">
                  <h3 style={{ marginBottom: "-10px" }}>
                    £{priceToCredit(amount)}
                  </h3>
                  <p style={{ marginBottom: "0", fontSize: "16px" }}>
                    to spend across shops
                  </p>
                  <p
                    style={{
                      lineHeight: "140%",
                      fontSize: "15px",
                      margin: "5px 0 30px",
                    }}
                  >
                    <span style={{ opacity: 0.5 }}>
                      an extra {priceToExtraPercent(amount).toFixed(0)}%, £
                      {Math.floor((priceToCredit(amount) - amount) * 12)} free
                      annually{" "}
                    </span>
                    🎉
                  </p>
                  <p className="shop-description">
                    <b>For example</b>, you can get all of these:
                  </p>
                  <TangibleExample price={amount} />
                </Card>
                <div
                  className="button-container"
                  style={{
                    marginTop: "10px",
                    width: isMobile || updateSub ? "100%" : "50%",
                  }}
                >
                  <Button onClick={() => subscribe()}>
                    {updateSub ? `Change plan to £${amount}/mo` : `Let's go!`}
                  </Button>
                  {updateSub && (
                    <div
                      style={{
                        fontSize: "16px",
                        margin: "-5px auto 25px",
                      }}
                    >
                      <a
                        href="/profile"
                        className="shop-description"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        {"<"} Go back
                      </a>
                    </div>
                  )}
                  {loading && (
                    <div style={{ marginTop: "25px" }}>
                      <Loader
                        type="TailSpin"
                        color="#ff7e67"
                        height={50}
                        width={50}
                      />
                    </div>
                  )}
                  {isMobile && (
                    <div style={{ marginTop: "15px" }}>
                      <a
                        style={{
                          fontSize: "16px",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                        onClick={() => {
                          setShopsShown(!shopsShown);
                        }}
                        href={shopsShown ? "#shops-list" : "#"}
                        id="shops-list"
                      >
                        <span>
                          {shopsShown ? "See less " : "See our shops "}
                          <svg
                            width="24"
                            height="24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            className="dropdown-heading-dropdown-arrow gray"
                            style={{
                              marginBottom: "-7px",
                              transform: shopsShown ? "rotate(180deg)" : "none",
                            }}
                          >
                            <path d="M6 9L12 15 18 9"></path>
                          </svg>
                        </span>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="column"
          style={{
            flex: "50%",
            overflowY: isMobile ? "unset" : "scroll",
            overflowX: "hidden",
            maxHeight: isMobile ? "auto" : "100vh",
            backgroundColor: isMobile ? "inherit" : "rgb(247, 240, 232)",
            padding: isMobile ? 0 : "25px",
            boxShadow: isMobile
              ? "none"
              : "inset 0px 15px 25px rgba(0,0,0,0.05)",
          }}
        >
          {shopsShown && (
            <div className="section-hero" style={{ paddingTop: "75px" }}>
              <ShopsList
                width={isMobile ? window.innerWidth : window.innerWidth * 0.5}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MembershipPicker;
