import { PropTypes } from "prop-types";
import { Button } from "../../Components";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

const NavBar = ({
  forShops = true,
  padded = true,
  showSignUp = false,
  user = null,
}) => {
  const history = useHistory();

  // Typeform code
  (function () {
    var js,
      q,
      d = document,
      gi = d.getElementById,
      ce = d.createElement,
      gt = d.getElementsByTagName,
      id = "typef_orm_share",
      b = "https://embed.typeform.com/";
    if (!gi.call(d, id)) {
      js = ce.call(d, "script");
      js.id = id;
      js.src = b + "embed.js";
      q = gt.call(d, "script")[0];
      q.parentNode.insertBefore(js, q);
    }
  })();

  return (
    <div className="section-navbar">
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        data-w-id="083b3595-12c0-3eb5-8a08-5d830155c0c6"
        role="banner"
        className={padded ? "navbar w-nav nav-padded" : "navbar w-nav"}
      >
        <div className="w-layout-grid grid-navbar">
          <div data-w-id="083b3595-12c0-3eb5-8a08-5d830155c0c8" className="nav">
            <a
              href={forShops ? "/forshops.html" : "/"}
              aria-current="page"
              className="brand w-nav-brand w--current"
            >
              <img
                src="/images/logo-orange.svg"
                loading="lazy"
                sizes="100vw"
                alt=""
                className="logo"
              />
            </a>
          </div>
          <div data-w-id="083b3595-12c0-3eb5-8a08-5d830155c0cb" className="nav">
            {forShops ? (
              <nav role="navigation" className="nav-menu w-nav-menu">
                <a href="/pricing" className="nav-link w-nav-link">
                  Pricing
                  <br />
                </a>
                <a href="/about" className="nav-link w-nav-link">
                  About us
                  <br />
                </a>
                <a href="/" className="nav-link w-nav-link">
                  For Customers
                  <br />
                </a>
                <div className="mobile-menu-fields">
                  <br />
                  <a href="/login" className="nav-link w-nav-link">
                    <img
                      alt=""
                      src="/images/user.svg"
                      style={{ maxWidth: "30px" }}
                    />
                    <br />
                  </a>
                  <a
                    className="typeform-share nav-button w-button"
                    href="https://form.typeform.com/to/fmtVL9bN?utm_source=app_navbar&typeform-medium=embed-snippet"
                    data-mode="popup"
                    data-size="100"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Create a Membership
                  </a>
                </div>
              </nav>
            ) : (
              <nav role="navigation" className="nav-menu w-nav-menu">
                <a href="/#howitworks" className="nav-link w-nav-link">
                  How it works
                  <br />
                </a>
                <a href="/#pastboxes" className="nav-link w-nav-link">
                  Past boxes
                  <br />
                </a>
                <a
                  href="https://shopscribe.notion.site/Frequently-Asked-Questions-2779c187321f4729818d36ce2561415c"
                  className="nav-link w-nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  FAQs
                  <br />
                </a>
                <a href="/about" className="nav-link w-nav-link">
                  About us
                  <br />
                </a>
                <div className="mobile-menu-fields">
                  <br />
                  {forShops && (
                    <a
                      className="typeform-share nav-button w-button"
                      href="https://form.typeform.com/to/fmtVL9bN?utm_source=app_navbar&typeform-medium=embed-snippet"
                      data-mode="popup"
                      data-size="100"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Create a Membership
                    </a>
                  )}
                </div>
              </nav>
            )}
          </div>
          <div
            data-w-id="083b3595-12c0-3eb5-8a08-5d830155c0dc"
            className="nav right"
          >
            <div className="extra-nav-menu">
              {forShops && (
                <a
                  className="typeform-share nav-button w-button"
                  href="https://form.typeform.com/to/fmtVL9bN?utm_source=app_navbar&typeform-medium=embed-snippet"
                  data-mode="popup"
                  data-size="100"
                  target="_blank"
                  rel="noreferrer"
                >
                  Create a Membership
                </a>
              )}
            </div>
            {!user && showSignUp ? (
              <Button
                width="fit-content"
                small
                onClick={() => history.push("/jump-on-board")}
              >
                {isMobile ? "Try now" : "Try for free"}
              </Button>
            ) : isMobile ? (
              <a href="/login">
                <img
                  alt=""
                  src="/images/user.svg"
                  style={{
                    width: "25px",
                    margin: "0 0 0 15px",
                  }}
                />
              </a>
            ) : (
              <a
                href="/login"
                className="nav-link w-nav-link"
                style={{ marginRight: 0, paddingRight: 0 }}
              >
                <span
                  style={{
                    borderBottom: "1.5px black solid",
                  }}
                >
                  Login
                </span>
              </a>
            )}
            {(user || !showSignUp) && (
              <div
                className="menu-button w-nav-button"
                aria-label="menu"
                role="button"
                tabIndex="0"
                aria-controls="w-nav-overlay-0"
                aria-haspopup="menu"
                aria-expanded="false"
              >
                <div className="menu-icon w-icon-nav-menu"></div>
              </div>
            )}
          </div>
        </div>
        <div className="navbar-bg" style={{ boxShadow: "none" }}></div>
      </div>
    </div>
  );
};

export default NavBar;

NavBar.propTypes = {
  forShops: PropTypes.bool,
  padded: PropTypes.bool,
  showSignUp: PropTypes.bool,
  user: PropTypes.object,
};
