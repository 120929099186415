import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import "react-app-protect/dist/index.css";
import { getFirebaseUserDocument, getSubBoxUsers } from "../../firebase";
import { migrateUserToPostgres, request } from "../../backend";
import { Button } from "../../Components";

const MigrateUser = () => {
  const { userId } = useParams();

  const [loading, setLoading] = useState(true);
  const [userText, setUserText] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      const userOnFirebase = await getFirebaseUserDocument(userId);
      const userOnPostgres = await request(
        `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`,
        "GET"
      );
      let text = "";
      if (userOnFirebase) {
        text += "Firebase User \n\n";
        text += JSON.stringify(userOnFirebase, undefined, 2);
        text += "\n\n";
      }
      if (userOnPostgres) {
        text += "Postgres User \n\n";
        text += JSON.stringify(userOnPostgres, undefined, 2);
      }
      setUserText(text);
      setLoading(false);
    };
    fetchUserData();
    //eslint-disable-next-line
  }, []);

  const migrateUser = async () => {
    setLoading(true);
    const userOnFirebase = await getFirebaseUserDocument(userId);
    if (userOnFirebase) {
      await migrateUserToPostgres(userId);
    } else {
      setLoading(false);
    }
  };

  const migrateAllUsers = async () => {
    setLoading(true);
    const subBoxUsers = await getSubBoxUsers();
    for (const doc of subBoxUsers.docs) {
      console.log(doc.id, " => ", doc.data());
      const userOnFirebase = await getFirebaseUserDocument(doc.id);
      if (userOnFirebase) {
        await migrateUserToPostgres(doc.id);
      }
      const userOnPostgres = await request(
        `${process.env.REACT_APP_BACKEND_URL}/users/${doc.id}`,
        "GET"
      );
      console.log(userOnPostgres);
    }
    setLoading(false);
  };

  return (
    <div className="account" style={{ paddingTop: "70px" }}>
      {!loading ? (
        <div className="dashboard">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p style={{ marginBottom: "20px", marginLeft: "-10px" }}>
              <a href="/home.html">
                <img
                  src="/images/logo-orange.svg"
                  loading="lazy"
                  alt=""
                  className="logo"
                />
              </a>
            </p>
            <Button onClick={migrateAllUsers}>
              Migrate All Users To Postgres
            </Button>
            <pre style={{ whiteSpace: "pre-line" }}>{userText}</pre>
            <Button onClick={migrateUser}>Migrate User To Postgres</Button>
          </div>
        </div>
      ) : (
        <div className="block-account">
          <Loader type="TailSpin" color="#ff7e67" height={80} width={80} />
          <h6 style={{ color: "#ff7e67" }}>
            Loading from databases! Please wait!
          </h6>
        </div>
      )}
    </div>
  );
};

export default MigrateUser;
