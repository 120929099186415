import { NavBar, Footer } from "../../Components";

const About = () => {
  return (
    <div>
      <NavBar forShops={false} />
      <div className="section-hero" style={{ paddingBottom: 0 }}>
        <div className="content">
          <div className="block-hero">
            <h1 data-w-id="Heading 2" className="heading-hero">
              Hey hey 👋
            </h1>
            <p style={{ maxWidth: "800px" }}>
              Launched in 2021, Shopscribe is on a mission to{" "}
              <b>support independent shop owners</b> who have been hit the
              hardest by the Covid pandemic.
            </p>
            <p></p>
            <p></p>
            <p></p>
            <div className="plan">
              <p>
                Shopscribe allows customers to{" "}
                <b>
                  discover the best small businesses, and get extra spend for
                  supporting local on a regular basis
                </b>
                . Pledging to spend money at local shops instead of big chains
                empowers small business owners to continue doing what they love
                to support their families.
              </p>
              <p>
                We know, because we started Shopscribe by{" "}
                <b>working in every single shop we onboarded</b> and - in
                between sweeping floors, serving customers, and being glorified
                bag boys - we spoke to customers directly to create something
                they enjoy!
              </p>
              <p>
                In a nutshell, Shopscribe makes it easy for you to{" "}
                <b>
                  support the local businesses in your community, save money and
                  enjoy special perks
                </b>{" "}
                while doing it! So, if you are like us and like a little variety
                to the high street – you’ll love Shopscribe ❤️
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="section" style={{ marginTop: "30px" }}>
        <div className="content">
          <h3>
            The team at <span className="text-accent">Shopscribe</span>
          </h3>
          <br />
          <div className="row" style={{ width: "100%" }}>
            <div
              className="column"
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  maxWidth: "250px",
                  position: "relative",
                  marginBottom: "25px",
                }}
              >
                <img
                  src="https://bookface-images.s3.amazonaws.com/avatars/fb4a587589a5e2e42a8a209ffd5f8d83823b908d.jpg?1622562226"
                  alt="Alex Zhou - CEO"
                  style={{
                    borderRadius: "100%",
                    maxWidth: "250px",
                  }}
                />
              </div>
              <h4>Alexander Zhou 🙈</h4>
              <p style={{ marginBottom: 0 }}>Co-Founder & CEO</p>
              <p style={{ opacity: 0.33 }}>alex@shopscribe.com</p>
              <p
                style={{
                  maxWidth: "450px",
                  fontSize: "15px",
                  lineHeight: "140%",
                }}
              >
                Born in California, Alex studied in hospitality at Cornell
                University, before moving to the UK where he attended University
                of Cambridge and Imperial College London. He worked both front
                and back of house in restaurants, and previously started a
                company backed by Y Combinator.
              </p>
              <br />
            </div>
            <div
              className="column"
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  maxWidth: "250px",
                  position: "relative",
                  marginBottom: "25px",
                }}
              >
                <img
                  src="https://pbs.twimg.com/profile_images/1187339177341411328/4fhmI5a7_400x400.jpg"
                  alt="Lorenzo Sani - CTO"
                  style={{
                    borderRadius: "100%",
                    maxWidth: "250px",
                  }}
                />
              </div>
              <h4>Lorenzo Sani 🤓</h4>
              <p style={{ marginBottom: 0 }}>Co-Founder & CTO</p>
              <p style={{ opacity: 0.33 }}>lorenzo@shopscribe.com</p>

              <p
                style={{
                  maxWidth: "450px",
                  fontSize: "15px",
                  lineHeight: "140%",
                }}
              >
                Lorenzo grew up in a family that ran a local bookshop in
                Tuscany, Italy. He moved to the UK to study Computer Science at
                university and worked as a Software Engineer in pre-seed to
                Series B startups. After joining a course in Neurotechnology at
                Imperial College London, he dropped out to start Shopscribe.
              </p>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div
              className="column"
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  maxWidth: "250px",
                  position: "relative",
                  marginBottom: "25px",
                }}
              >
                <img
                  src="https://res.cloudinary.com/shopscribe/image/upload/c_crop,h_1000,w_1017/v1641812803/Team/thush_hd7fny.jpg"
                  alt="Lorenzo Sani - CTO"
                  style={{
                    borderRadius: "100%",
                    maxWidth: "250px",
                  }}
                />
              </div>
              <h4>Thushaan Rajaratnam 🚀</h4>
              <p style={{ marginBottom: 0 }}>Full Stack Engineer</p>
              <p style={{ opacity: 0.33 }}>thush@shopscribe.com</p>
              <p
                style={{
                  maxWidth: "450px",
                  fontSize: "15px",
                  lineHeight: "140%",
                }}
              >
                Thush was born in Kandy, Sri Lanka, and has been in London since
                his teenage years. Thush studied Mechanical Engineering at
                Imperial College London, and went onto found his own tech
                startups. After later meeting the Shopscribe founders, he united
                on the idea to bring tech to local brick and mortar shops.
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default About;
