import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { Link, useHistory } from "react-router-dom";
import { auth, getFirebaseUserDocument } from "../../firebase.js";
import { AddressAutoComplete, Button } from "../../Components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import moment from "moment";
import {
  generateUserDocument,
  migrateUserToPostgres,
  request,
} from "../../backend.js";
import { isMobile } from "react-device-detect";

const errorCodes = {
  "auth/invalid-email": "That doesn't look like an email address :)",
  "auth/weak-password": "The password must be 6 characters long or more.",
  "auth/email-already-in-use":
    "You already have an account! Please sign in before ordering",
};

const DEFAULT_BOX_TYPE_ID = 1;

const DISCOUNT_CODE = "BOX40";
const DISCOUNT_PERC = 40;
const NEXT_SHOP_TYPE = "an authentic asian shop";

const LONDON_POSTCODES = [
  "EC1",
  "EC2",
  "EC3",
  "EC4",
  "WC1",
  "WC2",
  "E1",
  "E2",
  "E3",
  "E4",
  "E5",
  "E6",
  "E7",
  "E8",
  "E9",
  "E10",
  "E11",
  "E12",
  "E13",
  "E14",
  "E15",
  "E16",
  "E17",
  "E18",
  "E20",
  "N1",
  "N2",
  "N3",
  "N4",
  "N5",
  "N6",
  "N7",
  "N8",
  "N9",
  "N10",
  "N11",
  "N12",
  "N13",
  "N14",
  "N15",
  "N16",
  "N17",
  "N18",
  "N19",
  "N20",
  "N21",
  "N22",
  "NW1",
  "NW2",
  "NW3",
  "NW4",
  "NW5",
  "NW6",
  "NW7",
  "NW8",
  "NW9",
  "NW10",
  "NW11",
  "SE1",
  "SE2",
  "SE3",
  "SE4",
  "SE5",
  "SE6",
  "SE7",
  "SE8",
  "SE9",
  "SE10",
  "SE11",
  "SE12",
  "SE13",
  "SE14",
  "SE15",
  "SE16",
  "SE17",
  "SE18",
  "SE19",
  "SE20",
  "SE21",
  "SE22",
  "SE23",
  "SE24",
  "SE25",
  "SE26",
  "SE27",
  "SE28",
  "SW1",
  "SW2",
  "SW3",
  "SW4",
  "SW5",
  "SW6",
  "SW7",
  "SW8",
  "SW9",
  "SW10",
  "SW11",
  "SW12",
  "SW13",
  "SW14",
  "SW15",
  "SW16",
  "SW17",
  "SW18",
  "SW19",
  "SW20",
  "W1",
  "W2",
  "W3",
  "W4",
  "W5",
  "W6",
  "W7",
  "W8",
  "W9",
  "W10",
  "W11",
  "W12",
  "W13",
  "W14",
  "BR",
  "CR",
  "DA",
  "EN",
  "HA",
  "IG",
  "KT",
  "RM",
  "SM",
  "TW",
  "UB",
  "WD",
];

const SubBoxesSignUp = ({ user, authLoading }) => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState(user ? user.displayName : "");
  const [address0, setAddress0] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [phone, setPhone] = useState("");
  const [deliveryInfo, setDeliveryInfo] = useState("");
  const [dietaryInfo, setDietaryInfo] = useState("");
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [signupShown, setSignupShown] = useState(false);

  const [userData, setUserData] = useState({});
  const [userDataValid, setUserDataValid] = useState(false);
  const [batchId, setBatchId] = useState(null);
  const [cutOffDate, setCutOffDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [boxTypeId, setBoxTypeId] = useState(DEFAULT_BOX_TYPE_ID);

  const successCallback = new URLSearchParams(window.location.search).get(
    "callback"
  );

  const scrollToSecondStep = () => {
    const element = document.getElementById("second-step");
    window.scrollTo({
      behavior: "smooth",
      top: element ? element.offsetTop : 0,
    });
  };

  const postcodeIsValid = () => {
    let postcode = address3;
    let isValid = false;
    LONDON_POSTCODES.forEach((item) => {
      if (postcode.toUpperCase().startsWith(item)) {
        isValid = true;
      }
    });
    return isValid;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    scrollToSecondStep();
  }, [signupShown]);

  useEffect(() => {
    getCurrentBatch();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const checkLoading = () => {
      if (!authLoading) {
        setLoading(false);
        clearInterval(interval);
      }
    };
    const interval = setInterval(checkLoading, 500);
  }, [authLoading]);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user && userDataValid) {
        await redirectAfterAuth(user);
      }
    });
    //eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    if (user) {
      setDisplayName(user.displayName);
    }
    if (user && user.boxes && user.boxes.length > 0) {
      alert("You already have a box, you'll be redirect to your profile!");
      history.push("/profile");
    }
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (userDataValid) {
      const subBoxUser = true;
      const subBoxPaid = false;
      const nextDeliveryDate = deliveryDate;
      const address = address0;
      const aptOrSuite = address1;
      const town = address2;
      const postCode = address3;
      setDisplayName(displayName?.length === 0 ? user.displayName ?? "" : "");
      setUserData({
        subBoxUser,
        subBoxPaid,
        displayName,
        address,
        phone,
        nextDeliveryDate,
        deliveryInfo,
        dietaryInfo,
        aptOrSuite,
        town,
        postCode,
      });
      // TODO: local storage has to be used due to stage / page changes (user + sub must be created before box), not ideal
      localStorage.setItem("createSub", true);
      localStorage.setItem("recipientName", displayName);
      localStorage.setItem("address", address);
      localStorage.setItem("aptOrSuite", aptOrSuite);
      localStorage.setItem("town", town);
      localStorage.setItem("postCode", postCode);
      localStorage.setItem("deliveryInfo", deliveryInfo);
      localStorage.setItem("dietaryInfo", dietaryInfo);
      localStorage.setItem("deliveryDate", nextDeliveryDate);
      localStorage.setItem("boxTypeId", boxTypeId);
      localStorage.setItem("batchId", batchId);
      localStorage.setItem("cutOffDate", cutOffDate);
      createUserWithEmailAndPasswordHandler(email, password);
    } else {
      setSubmitted(false);
    }
    //eslint-disable-next-line
  }, [userDataValid]);

  const getCurrentBatch = async () => {
    const now = moment();
    const currentBatch = await request(
      `${process.env.REACT_APP_BACKEND_URL}/current-batch`,
      "POST",
      {
        date: now.toDate(),
        boxTypeId: DEFAULT_BOX_TYPE_ID,
      }
    );
    if (
      currentBatch &&
      currentBatch.id &&
      currentBatch.cutOffDate &&
      currentBatch.deliveryDate &&
      currentBatch.defaultBoxTypeId
    ) {
      setBatchId(currentBatch.id);
      setCutOffDate(currentBatch.cutOffDate);
      setDeliveryDate(currentBatch.deliveryDate);
      setBoxTypeId(currentBatch.defaultBoxTypeId);
    } else {
      console.error(
        "Error obtaining batch id, cut off date, delivery date, default box type Id"
      );
    }
  };

  const redirectAfterAuth = async (authUser) => {
    await generateUserDocument(authUser, userData);
    const userId = authUser.id || authUser.uid;
    const userOnFirebase = await getFirebaseUserDocument(userId);
    const userOnPostgres = await request(
      `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`,
      "GET"
    );
    if (userOnPostgres) {
      if (
        userOnPostgres &&
        userOnPostgres.boxes &&
        userOnPostgres.boxes.length > 0
      ) {
        alert("You already have a box, you'll be redirect to your profile!");
        history.push("/profile");
      } else {
        redirectToCheckout();
      }
    } else if (userOnFirebase && !userOnFirebase.deprecated) {
      // eslint-disable-next-line no-restricted-globals
      const check = confirm(
        "You're a past shopscribe user - we don't currently support both membership and box subscription.\n By continuing you will cancel your membership if you have one and switch to a box subscription."
      );
      if (check) {
        await migrateUserToPostgres(userId);
        redirectToCheckout();
      } else {
        history.push("/");
      }
    } else {
      throw new Error("Redirect error");
    }
  };

  const redirectToCheckout = () => {
    if (successCallback) {
      history.push(successCallback);
    } else {
      history.push(`/checkout/25?type=box`);
    }
  };

  const checkUserData = () => {
    let valid = true;
    const errors = [];
    if (!user && email === "") {
      valid = false;
      errors.push("email address");
    }
    if (!user && password === "") {
      valid = false;
      errors.push("password");
    }
    if (!user && displayName === "") {
      valid = false;
      errors.push("name");
    }
    if (address0 === "") {
      valid = false;
      errors.push("address");
    }
    if (address3 === "") {
      valid = false;
      errors.push("postcode");
    }
    setUserDataValid(valid);
    if (errors.length > 0) {
      let error = "Please enter a valid ";
      errors.forEach((string, index) => {
        if (index === 0) {
          error += string;
        } else if (index !== errors.length - 1) {
          error += ", " + string;
        } else {
          error += " & " + string;
        }
      });
      setError(error);
      setSubmitted(false);
    }
  };

  const createUserWithEmailAndPasswordHandler = async (email, password) => {
    try {
      if (user) {
        await redirectAfterAuth(user);
      } else {
        user = await auth.createUserWithEmailAndPassword(email, password);
      }
    } catch (error) {
      setError(errorCodes[error.code] ?? "Error on signing up, try again!");
      setSubmitted(false);
    }
    setEmail("");
    setPassword("");
    setDisplayName("");
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    } else if (name === "displayName") {
      setDisplayName(value);
    } else if (name === "deliveryInfo") {
      setDeliveryInfo(value);
    } else if (name === "dietaryInfo") {
      setDietaryInfo(value);
    } else if (name === "address0") {
      setAddress0(value);
    } else if (name === "address1") {
      setAddress1(value);
    } else if (name === "address2") {
      setAddress2(value);
    } else if (name === "address3") {
      setAddress3(value);
    } else if (name === "phone") {
      setPhone(value);
    }
    if (error) {
      setError(null);
    }
  };

  return (
    <div>
      <div>
        <div className="account">
          <a
            href="/"
            data-w-id="f6d39ff8-752c-4106-c067-25966622553d"
            className="brand-account w-nav-brand"
            style={{ position: "fixed", top: "30px", zIndex: 99999 }}
          >
            <img
              src="images/logo-orange.svg"
              loading="lazy"
              alt=""
              className="logo"
            />
          </a>
          {!submitted && !loading && (
            <div
              className="row"
              style={{ width: "100%", height: "100%", maxWidth: "1200px" }}
            >
              <div className="column" style={{ flex: "45%" }}>
                <div
                  data-w-id="bfdef6b2-2e26-0bbb-6944-284fe24ab051"
                  className="block-account"
                >
                  <div className="account-form" data-w-id="Heading 2">
                    <div
                      className="form-block w-form"
                      style={{
                        fontFamily: "DM Sans, sans-serif !important",
                        margin: 0,
                      }}
                    >
                      <h3 style={{ margin: "0 0 30px 0" }}>
                        Where shall we deliver?
                      </h3>
                      <div data-name="Sign up" className="form">
                        <AddressAutoComplete
                          address0={address0}
                          address1={address1}
                          address2={address2}
                          address3={address3}
                          onChange={(event) => onChangeHandler(event)}
                          onAddress0Change={(address) => setAddress0(address)}
                          onAddress1Change={(address) => setAddress1(address)}
                          onAddress2Change={(address) => setAddress2(address)}
                          onAddress3Change={(address) => setAddress3(address)}
                        />
                        <Accordion
                          allowMultipleExpanded={true}
                          allowZeroExpanded={true}
                        >
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton
                                style={{
                                  textAlign: "left",
                                  fontSize: "15px",
                                  opacity: ".5",
                                  cursor: "pointer",
                                }}
                              >
                                ▶ Add delivery instructions
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <textarea
                                style={{
                                  margin: "10px 0",
                                }}
                                type="text"
                                className="text-field w-input text-field-textarea"
                                name="deliveryInfo"
                                value={deliveryInfo}
                                placeholder="Delivery instructions"
                                id="name"
                                onChange={(event) => onChangeHandler(event)}
                              />
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton
                                style={{
                                  textAlign: "left",
                                  fontSize: "15px",
                                  opacity: ".5",
                                  cursor: "pointer",
                                }}
                              >
                                ▶ Add dietary preferences
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <textarea
                                style={{
                                  margin: "10px 0",
                                }}
                                type="text"
                                className="text-field w-input text-field-textarea"
                                name="dietaryInfo"
                                value={dietaryInfo}
                                placeholder="Dietary preferences"
                                id="name"
                                onChange={(event) => onChangeHandler(event)}
                              />
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>
                      <div
                        className="form-block w-form"
                        style={{
                          fontFamily: "DM Sans, sans-serif !important",
                        }}
                      >
                        {error !== null && (
                          <div className="error-message w-form-fail">
                            <div className="error-text">{error}</div>
                          </div>
                        )}
                        <Button
                          onClick={() => {
                            if (postcodeIsValid()) {
                              if (user) {
                                setSubmitted(true);
                                checkUserData();
                              } else {
                                signupShown
                                  ? scrollToSecondStep()
                                  : setSignupShown(true);
                              }
                            } else {
                              if (!address3) {
                                setError(
                                  "Please enter a valid postcode/address"
                                );
                              } else {
                                setError(
                                  `Sorry, we don't deliver to ${address3} yet!`
                                );
                              }
                            }
                          }}
                          style={{
                            maxWidth: "100% !important",
                            margin: "20px 0 0",
                          }}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      maxWidth: "450px",
                      margin: isMobile ? "40px auto 0" : "25px auto 0",
                      paddingLeft: isMobile ? "5vw" : "45px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="check-plan"
                        style={{ width: "30px", height: "30px" }}
                      >
                        <img
                          src="/images/check-orange.svg"
                          loading="lazy"
                          alt=""
                          className="icon-check"
                        />
                      </div>

                      <p style={{ marginBottom: "10px", fontSize: "15px" }}>
                        Free delivery, <b>cancel anytime</b>
                      </p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="check-plan"
                        style={{ width: "30px", height: "30px" }}
                      >
                        <img
                          src="/images/check-orange.svg"
                          loading="lazy"
                          alt=""
                          className="icon-check"
                        />
                      </div>

                      <p style={{ marginBottom: "10px", fontSize: "15px" }}>
                        First box comes from <b>{NEXT_SHOP_TYPE}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column" style={{ flex: "55%" }}>
                <div
                  style={
                    isMobile
                      ? {
                          marginTop: "75px",
                          width: window.innerWidth + 10,
                          marginLeft: "-30px",
                        }
                      : window.innerWidth <= 1500
                      ? {
                          position: "fixed",
                          top:
                            (window.innerHeight -
                              Math.min(window.innerWidth * 0.45, 750)) /
                            2,
                          right: "-5px",
                          width: "45%",
                          maxWidth: "750px",
                        }
                      : {
                          position: "fixed",
                          top:
                            (window.innerHeight -
                              Math.min(window.innerWidth * 0.45, 550)) /
                            2,
                          right: (window.innerWidth - 1200) / 2,
                          width: "30%",
                          maxWidth: "550px",
                        }
                  }
                >
                  <Carousel
                    autoPlay
                    infiniteLoop
                    stopOnHover
                    swipeable={false}
                    showArrows={false}
                    showStatus={false}
                    showIndicators={false}
                    showThumbs={false}
                    animationHandler="fade"
                  >
                    <div>
                      <img
                        style={{
                          borderBottomRightRadius:
                            window.innerWidth > 1500 ? "30px" : 0,
                          borderTopRightRadius:
                            window.innerWidth > 1500 ? "30px" : 0,
                          borderTopLeftRadius: isMobile ? 0 : "30px",
                          borderBottomLeftRadius: isMobile ? 0 : "30px",
                        }}
                        src="https://res.cloudinary.com/shopscribe/image/upload/v1648457550/IMG_0093-Edit_n6xiah.jpg"
                        alt="Shopscribe Box Example"
                      />
                    </div>
                    <div>
                      <img
                        style={{
                          borderBottomRightRadius:
                            window.innerWidth > 1500 ? "30px" : 0,
                          borderTopRightRadius:
                            window.innerWidth > 1500 ? "30px" : 0,
                          borderTopLeftRadius: isMobile ? 0 : "30px",
                          borderBottomLeftRadius: isMobile ? 0 : "30px",
                        }}
                        src="https://res.cloudinary.com/shopscribe/image/upload/v1648457546/IMG_0038-2-Edited_xr9vzg.jpg"
                        alt="Shopscribe Box Example"
                      />
                    </div>
                    <div>
                      <img
                        style={{
                          borderBottomRightRadius:
                            window.innerWidth > 1500 ? "30px" : 0,
                          borderTopRightRadius:
                            window.innerWidth > 1500 ? "30px" : 0,
                          borderTopLeftRadius: isMobile ? 0 : "30px",
                          borderBottomLeftRadius: isMobile ? 0 : "30px",
                        }}
                        src="https://res.cloudinary.com/shopscribe/image/upload/v1648457545/IMG_0063-Edited_gghgcs.jpg"
                        alt="Shopscribe Box Example"
                      />
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          )}
          {(submitted || loading) && (
            <div className="block-account">
              <Loader type="TailSpin" color="#ff7e67" height={80} width={80} />
            </div>
          )}
        </div>
        {signupShown && (
          <div className="account">
            <div
              className="row"
              style={{ height: "100%", width: "100%", maxWidth: "1200px" }}
            >
              <div className="column" style={{ flex: "45%" }}>
                <div className="account-form" style={{ marginBottom: "30px" }}>
                  <div
                    className="form-block w-form"
                    style={{
                      fontFamily: "DM Sans, sans-serif !important",
                    }}
                  >
                    <div data-name="Sign up" className="form">
                      <div>
                        <h3 style={{ margin: "0 0 5px 0" }}>
                          Yay, we deliver to you!
                        </h3>
                        <p>Now let’s get your account set up</p>
                        <input
                          type="text"
                          className="text-field w-input"
                          maxLength="256"
                          name="displayName"
                          value={displayName}
                          placeholder="Full name *"
                          id="name"
                          onChange={(event) => onChangeHandler(event)}
                          style={{ marginBottom: "10px" }}
                        />
                        <input
                          type="email"
                          className="text-field w-input"
                          maxLength="256"
                          name="userEmail"
                          value={email}
                          placeholder="Email address *"
                          id="Email"
                          required=""
                          onChange={(event) => onChangeHandler(event)}
                          style={{ marginBottom: "10px" }}
                        />
                        <input
                          type="password"
                          className="text-field w-input"
                          maxLength="256"
                          name="userPassword"
                          value={password}
                          placeholder="Password *"
                          id="Password"
                          required=""
                          onChange={(event) => onChangeHandler(event)}
                        />
                      </div>
                    </div>
                    <div
                      className="form-block w-form"
                      style={{
                        fontFamily: "DM Sans, sans-serif !important",
                      }}
                    >
                      {error !== null && (
                        <div className="error-message w-form-fail">
                          <div className="error-text">{error}</div>
                        </div>
                      )}
                      <Button
                        onClick={() => {
                          setSubmitted(true);
                          checkUserData();
                        }}
                        style={{
                          maxWidth: "100% !important",
                          margin: "20px 0 10px",
                        }}
                      >
                        Go to Checkout
                      </Button>
                    </div>
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        minWidth: "100%",
                        marginTop: "10px",
                        marginBottom: "0",
                        fontSize: "15px",
                        lineHeight: "140%",
                        opacity: 0.5,
                      }}
                    >
                      Use <b>{DISCOUNT_CODE}</b> at checkout for{" "}
                      <b>{DISCOUNT_PERC}% off</b> your first month!
                    </p>
                  </div>
                </div>
                <div
                  data-w-id="c79bff7a-25bf-c657-cba2-061e2a3f4e5e"
                  className="text-account"
                >
                  Already have an account?{" "}
                  <Link
                    to={`/login${
                      successCallback ? "?callback=" + successCallback : ""
                    }`}
                  >
                    Login
                  </Link>
                </div>
              </div>
              <div className="column" style={{ flex: "55%" }}></div>
            </div>
          </div>
        )}
      </div>
      <div id="second-step"></div>
    </div>
  );
};
export default SubBoxesSignUp;
